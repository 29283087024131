import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Header } from "./Header";
import { SidePanel } from "./SidePanel";
import { USER_TYPE, ROUTES } from "../constants";
import { PersistentDrawerLeft } from "./../components";
import {
  MobileView,
  isBrowser,
} from "react-device-detect";

import { closeAll, setVisibility } from "../actions";

import "./styles/RvipLayout.css";

const mapStateToProps = (state) => {
  return {
    sidePanelVisible: state.SidePanelReducer.visible,
    orgUser: state.UserReducer.orgUser,
    currentUser: state.UserReducer.currentUser,
  };
};

class RvipLayout extends Component {
  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
    this.checkIfOrganizationIsSelected = this.checkIfOrganizationIsSelected.bind(this);
  }

  goTo(routeName) {
    if (this.props.location.pathname === routeName) {
      return;
    }
    this.props.history.push(routeName);
    this.props.dispatch(closeAll(true));
    this.props.dispatch(setVisibility(false));
  }

  componentDidMount() {
    this.checkIfOrganizationIsSelected();
  }

  componentDidUpdate() {
    this.checkIfOrganizationIsSelected();
  }

  checkIfOrganizationIsSelected() {
    if (this.props.currentUser && !this.props.orgUser) {
      this.goTo(ROUTES.ORGANIZATIONS);
    }
  }

  render() {
    if (isBrowser) {
      return (
        <div className="fill-screen">
            <Header />
            { this.props.orgUser ?
              this.props.orgUser.accountType !== USER_TYPE.GUEST ? 
                <div
                  className={`redico-content ${
                    !this.props.sidePanelVisible ? "" : "hidden"
                  }`}
                >
                  <SidePanel selected={this.props.selected ?? ""} />
                
                  <div className={`current-content`}>
                    {this.props.children ?? this.props.pageName ?? "Unfinished"}
                  </div>
                </div>
                :
                <div className={`redico-content hidden`}>
                  <div className={`current-content`}>
                    {this.props.children ?? this.props.pageName ?? "Unfinished"}
                  </div>
                </div>
              :
              <></>
            }
          
        </div>
      )
    }
    return (
      <div className="fill-screen">
        <MobileView>
          <PersistentDrawerLeft />

          <div className="redico-content hidden">
            <div className={`current-content`}>
              {this.props.children ?? this.props.pageName ?? "Unfinished"}
            </div>
          </div>
        </MobileView>
      </div>
    );
  }
}

const ConnectedRvipLayout = connect(mapStateToProps)(RvipLayout);

const RvipLayoutWithRouter = withRouter(ConnectedRvipLayout);

export { RvipLayoutWithRouter as RvipLayout };
