export * from "./routes";
export { API_ROUTES } from "./ApiRoutes";
export { EMAIL_REGEX } from "./emailRegex";
export * from "./ActionTypes";

const ACCESS_TOKEN = "accessToken";
export { ACCESS_TOKEN };

const ACCESS_TOKEN_HEADER = "x-access-token";
export { ACCESS_TOKEN_HEADER };

export const USER_TYPE = {
  1: "Administrador",
  // 2: "Maintainer",
  2: "Admin. Projecto",
  3: "Invitado",
  ADMIN: 1,
  MAINTAINER: 2,
  GUEST: 3,
};

export const IS_INT = /^-?\d+$/;

export const ACCESS_TYPE = {
  NONE: 0,
  AR: 1,
  VR: 2,
  MR: 3,
  AR_VR: 4,
  AR_MR: 5,
  VR_MR: 6,
  AR_VR_MR: 7,
};

export const PROJECT_SECTION_TABS = {
  VIEW_3D: "3d-views",
  MEETINGS: "meetings",
  MARKERS: "markers",
  MAINTAINERS: "maintainers",
  DOCUMENTS: "documents",
}