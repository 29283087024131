import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, MiniButton } from "../../..";
import { setAddMeetingCategoryModal, setConfigCategoryMeetingModal, setImportTypesOfMeetingModal } from "../../../../actions";

export function MeetingCategoriesActions() {
  const dispatch = useDispatch();

  const [isActive, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!isActive);
  };

  const handleNewCategory = () => {
    setActive(false)
    dispatch(setAddMeetingCategoryModal(true, 'Config'));
  };
  
  const handleImportCategory = () => {
    setActive(false)
    dispatch(setConfigCategoryMeetingModal(true));
    dispatch(setImportTypesOfMeetingModal(true));
  };

  return (
    <div className="header-categories-options-button">
      <MiniButton type="create" onClick={toggleActive} />
      <div className={`header-categories-options header-mouse-up ${isActive ? 'active' : 'hidden'}`}>
        <Button className="button-vertical header-mouse-up" onClick={handleNewCategory}>
          Crear Tipo de Reunión
        </Button>
        <Button className="button-vertical header-mouse-up" onClick={handleImportCategory}>
          Importar Tipo de Reunión
        </Button>
      </div>
    </div>
  );
};
