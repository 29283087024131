import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "../Button";
import {NewMantainer} from "../NewMantainer";
import {NewGuest} from "../NewGuest";
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import { setUserModal } from "../../actions"
import { USER_TYPE } from "../../constants";

import "./styles/Modal.css"

const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalUser,
        placeholder: state.UserReducer.selectedUser,
        orgUser: state.UserReducer.orgUser
    };
}

const initialState =  {
    username: '',
    email: '',
    company: '',
    userType: '',
    disabled: false,
    mantainer: true,
    guest: false,
};

class NewUserModal extends Component{

    constructor(props){
        super(props);
        this.state = initialState;

        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);

        this.setGuestTrue = this.setGuestTrue.bind(this);
        this.setMantainerTrue = this.setMantainerTrue.bind(this);

        this.youNeedToBeAdmin = this.youNeedToBeAdmin.bind(this);
    }

    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
    }

    renderContent() {
        if (this.props.orgUser.accountType === USER_TYPE.ADMIN){
            if (this.state.mantainer){
                return <NewMantainer username={this.state.username} email={this.state.email} switch={this.setGuestTrue} close={this.closeModal}/>
            }
            else {
                return <NewGuest username={this.state.username} email={this.state.email} switch={this.setMantainerTrue} close={this.closeModal}/>
            }
        }
        else if (this.props.orgUser.accountType === USER_TYPE.MAINTAINER){
            return <NewGuest switch={this.youNeedToBeAdmin} close={this.closeModal}/>
        }
    }

    youNeedToBeAdmin(){
        alert("Necesitas ser administrador de sistema para acceder a esta opción")
    }

    setGuestTrue(username, email) {
        this.setState({...this.state, username: username, email: email, guest: true, mantainer: false});
    }

    setMantainerTrue(username, email) {
        this.setState({...this.state, username: username, email: email, guest: false, mantainer: true});
    }

    resetState(){
        this.setState(initialState);
    }

    closeModal(){
        // this.resetState();
        this.props.dispatch(setUserModal(false)).then(this.resetState);
    }

    render(){
        var modalState = 'new-user-purple-background ';
        modalState += this.props.isVisible? 'new-user-modal-open':'modal-closed';

        return(
            <div className={modalState}>
                <div className="new-user-modal">
                    <Button className='new-user-modal-button' onClick={this.closeModal}>
                        <Close/>
                    </Button>
                    <div className='new-user-modal-title'>
                        <span> Crear miembro </span>
                    </div>
                    <div className="user-modal-content" >
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }
}

const connectedNewUserModal = connect(mapStateToProps)(NewUserModal);

export { connectedNewUserModal as NewUserModal };