import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConfigCategoryMeetingModal, getProjectMeetingCategories } from "../../actions";
import "./styles/Modal.css"
import "./styles/NewMeetingCategoryModal.css"
import { ListWidget } from "..";
import { LIST_WIDGET_CONTENT_TYPE } from "../../constants/ListWidgetContentType";
import { MeetingCategoriesContentType } from "../ListWidget/contentType/MeetingCategoriesContentType";

export const ConfigCategoryMeetingModal = () => {

  const dispatch = useDispatch();
  
  // store references
  const selectedProject = useSelector((state) => state.ProjectReducer.selectedProject);
  const isVisible = useSelector((state) => state.ModalReducer.modalConfigCategoryMeetings);
  const projectMeetingCategories = useSelector((state) => state.ProjectReducer.projectMeetingCategories);

  useEffect(function SetMeetingGategoryList() {
    if (!isVisible || !selectedProject) return;
    dispatch(getProjectMeetingCategories(selectedProject.id));
  }, [dispatch, isVisible, selectedProject])

  useEffect(function SetMeetingGategoryList() {
    if (!selectedProject) return;
    dispatch(getProjectMeetingCategories(selectedProject.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    dispatch(setConfigCategoryMeetingModal(false));
  }

  var modalState = 'modal-overlay-categories ';
  modalState += isVisible ? 'modal-open' : 'modal-closed';
  const MEETING_CATEGORIES = {
    type: LIST_WIDGET_CONTENT_TYPE.MEETING_CATEGORIES,
    content:  projectMeetingCategories ?? [],
    contentType: MeetingCategoriesContentType,
  };
    
  return (
    <div className={modalState}>
      <div className="modal-category-element">
        <div style={{width: "50em"}}>
          <div className="new-category-meeting-modal-box">
            <button className="closing-x" onClick={closeModal}> X </button>
            <div className="project-header-2">
              <div className="project-logo" />
              <div className="project-data">
                  <div className="project-name">
                    Tipos de Reunion / {selectedProject? (selectedProject.name):("No name")}
                  </div>
              </div>
            </div>
            <div className="list-content-category">
                <ListWidget className="list-container-category" content={MEETING_CATEGORIES} closeModal={isVisible}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
