import { ACCESS_TOKEN, ACCESS_TOKEN_HEADER, API_ROUTES } from "../constants";

class ProjectService {
  static async getProjects() {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(API_ROUTES.PROJECTS, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          const projects = resJson.projects.map((project) => {
            const organizationsLength = resJson.organizations.length;
            for (let index = 0; index < organizationsLength; index++) {
              const organization = resJson.organizations[index];
              if (organization.id === project.OrganizationId) {
                project.organization = organization;
                break;
              }
            } 
            return project; 
          });
          return projects;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async addProject(projectData) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(API_ROUTES.PROJECTS, {
      method: "POST",
      credentials: "include",
      headers,
      body: JSON.stringify(projectData),
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson.project;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProjectsAssigned(mantainerId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS_ASSIGNED}/${mantainerId}/projects`, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson.projects;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProject(projectId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}`, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson.project;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProjectMaintainers(projectId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/maintainers`, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProjectMeetings(projectId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meetings`, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200) {
          return resJson.message;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProjectMeetingGuests(projectId, meetingId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(
      `${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}/guests`,
      {
        method: "GET",
        credentials: "include",
        headers,
      }
    )
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProjectMeetingViews(projectId, meetingId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(
      `${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}/visual-references`,
      {
        method: "GET",
        credentials: "include",
        headers,
      }
    )
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProjectAnnotations(projectId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/annotation-types`, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200) {
          return resJson.message;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async updateProject(projectId, projectData) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };

    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}`, {
      method: "PUT",
      credentials: "include",
      headers,
      body: JSON.stringify(projectData),
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson;
        }
        // handle failure
        throw resJson.message;
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async deleteProject(projectId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}`, {
      method: "DELETE",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200) {
          return resJson.project;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async addProjectMaintainer(projectId, maintainerId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/maintainers`, {
      method: "POST",
      credentials: "include",
      headers,
      body: JSON.stringify(maintainerId),
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson;
        }
        console.error("resJson", resJson);
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async deleteProjectMaintainer(projectId, maintainerId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      ACCESS_TOKEN_HEADER: localStorage.getItem(ACCESS_TOKEN),
    };
    return fetch(
      `${API_ROUTES.PROJECTS}/${projectId}/maintainers/${maintainerId}`,
      {
        method: "DELETE",
        credentials: "include",
        headers,
      }
    )
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProjectMeetingCategories(projectId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meeting-categories`, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        if (res.status === 200 || res.status === 201) {
          localStorage.setItem(ACCESS_TOKEN, resJson.accessToken);
          return resJson["message"];
        }
        // handle failure
        throw resJson.message;
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProjectMeeting(projectId, meetingId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}`, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        if (res.status === 200 || res.status === 201) {
          localStorage.setItem(ACCESS_TOKEN, resJson.accessToken);
          return resJson.meeting;
        }
        // handle failure
        throw resJson.message;
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getProjectMeetingAnnotationInstances(projectId, meetingId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(
      `${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}/annotations`,
      {
        method: "GET",
        credentials: "include",
        headers,
      }
    )
      .then(async (res) => {
        const resJson = await res.json();
        if (res.status === 200 || res.status === 201) {
          localStorage.setItem(ACCESS_TOKEN, resJson.accessToken);
          return resJson["message"];
        }
        // handle failure
        throw resJson.message;
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async addProjectMeetingCategory(projectId, categoryData) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meeting-categories`, {
      method: "POST",
      credentials: "include",
      headers,
      body: JSON.stringify(categoryData),
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson["meetingCategory"];
        }
        console.error(resJson);
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }
  
  static async getProjectViews(projectId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/views`, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200) {
          return resJson;
        }
        // handle failure
        throw new Error(resJson);
      })
      .catch((err) => {
        console.error("Caught error xd: ", err);
        throw err;
      });
  }

  static async addProjectMeetingViews(projectId, meetingId, visualReferenceId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(
      `${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}/visual-references`,
      {
        method: "POST",
        credentials: "include",
        headers,
        body: JSON.stringify(visualReferenceId),
      }
    )
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200) {
          return resJson;
        }
        // handle failure
        throw new Error(resJson);
      })
      .catch((err) => {
        console.error("Caught error xd: ", err);
        throw err;
      });
  }

  static async addProjectMeetingNotes(projectId, meetingId, annotationId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(
      `${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}/annotation-types`,
      {
        method: "POST",
        credentials: "include",
        headers,
        body: JSON.stringify(annotationId),
      }
    )
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200) {
          return resJson;
        }
        // handle failure
        throw new Error(resJson);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async addProjectMeetingGuests(projectId, meetingId, guestId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(
      `${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}/guests`,
      {
        method: "POST",
        credentials: "include",
        headers,
        body: JSON.stringify(guestId),
      }
    )
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200) {
          return resJson;
        }
        // handle failure
        throw new Error(resJson);
      })
      .catch((err) => {
        console.error("Caught error xd: ", err);
        throw err;
      });
  }

  static async addProjectImport(projectId, data) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(
      `${API_ROUTES.PROJECTS}/${projectId}/meeting-categories/import`,
      {
        method: "POST",
        credentials: "include",
        headers,
        body: JSON.stringify(data),
      }
    )
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson;
        }
        console.error(resJson);
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }


  static async deleteProjectView(projectId, viewId, viewVersionId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    return fetch(
      `${API_ROUTES.PROJECTS}/${projectId}/views/${viewId}/${viewVersionId}`,
      {
        method: "DELETE",
        credentials: "include",
        headers,
      }
    ).then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson.viewVersion;
        }
        console.error(resJson);
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

}

export { ProjectService };
