import React, { Component } from "react";
import { connect } from "react-redux";
import { EditListWidget } from "../EditListWidget.js";
import {
  setMeetingModal,
  resetMeeting,
} from "../../actions";
import { USER_TYPE } from "../../constants";
import "./styles/NewMeetingModal.css";
import { ReactComponent as MeetingDetails } from "../../routes/svg/icono_reunión_virtual.svg";
import { ReactComponent as View3D } from "../../routes/svg/icono_vistas_3D.svg";
import { ReactComponent as Marker } from "../../routes/svg/icono_anotaciones.svg";
import { ReactComponent as Guest } from "../../routes/svg/icono_invitado.svg";
import { LIST_WIDGET_CONTENT_TYPE } from "../../constants/ListWidgetContentType.js";
import { MarkersContentType } from "../ListWidget/contentType/MarkersContentType.js";
import { ViewsContentType } from "../ListWidget/contentType/ViewsContentType.js";

const mapStateToProps = (state) => {
  return {
    isVisible: state.ModalReducer.modalGetMeeting,
    selectedMeeting: state.ProjectReducer.selectedProjectMeeting,
    annotationTypes: state.ProjectReducer.selectedProjectMeetingNotes,
    orgUser: state.UserReducer.orgUser,
  };
};

class MeetingModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  goTo(routeName) {
    if (this.props.location.pathname === routeName) {
      return;
    }
    this.props.history.push(routeName);
  }

  closeModal() {
    this.props
      .dispatch(setMeetingModal(false))
      .then(this.props.dispatch(resetMeeting()));
  }

  render() {
    if(!this.props.isVisible || !this.props.selectedMeeting ) return (<div />);

    var modalState = "modal-overlay-categories ";
    modalState += this.props.isVisible ? "modal-open" : "modal-closed";

    if ( this.props.orgUser.accountType === USER_TYPE.GUEST) {
      modalState += " less-right"
    }

    const {
      annotationTypes,
    } = this.props;

    const project = this.props.selectedMeeting.Project;

    const GUESTS = {
      type: "GUESTS",
      content: [],
      getMeeting: true,
    };

    const VIEW_3D = {
      type: LIST_WIDGET_CONTENT_TYPE.VIEW_3D,
      content: [],
      getMeeting: true,
      contentType: ViewsContentType,
    };

    const MARKERS = {
      type: LIST_WIDGET_CONTENT_TYPE.MARKERS,
      content: [],
      getMeeting: true,
      contentType: MarkersContentType,
    };

    if (this.props.annotationTypes) {
      MARKERS.content = this.props.annotationTypes;
    }

    const ANNOTATIONS = {
      type: "ANNOTATIONS",
      content: [],
      getMeeting: true,
    };

    if (this.props.selectedMeeting) {
      ANNOTATIONS.content = this.props.selectedMeeting.AnnotationInstances;
      VIEW_3D.content = this.props.selectedMeeting.VisualReferences;
      GUESTS.content = this.props.selectedMeeting.Guests.map( guest =>{
        const dateObj = new Date(guest["createdAt"]);
        let myDate =
          dateObj.getFullYear() +
          "-" +
          (dateObj.getMonth() + 1) +
          "-" +
          dateObj.getDate();
        return {
          itemName: guest["company"],
          id: guest["UserId"],
          subText: "",
          description: guest["alias"],
          creationDate: myDate,
        };
      });
    }

    const { selectedMeeting } = this.props;

    var svgClass = "";
    var annotationColor = "purple-color-annotation";
    var littleSvgClass = "meeting-details-smaller-logo ";
    var lineClass = "meeting-details-line ";
    if (selectedMeeting && selectedMeeting.isFinished) {
      svgClass = "green-svg-color";
      annotationColor = "green-color-annotation";
      littleSvgClass += "green-svg-color";
      lineClass += "green-line";
    }
    return (
      <div className={modalState}>
        <div className="">
          {" "}
          <div className="new-meeting-category-modal">
            <div className="new-category-meeting-modal-box">
              <button id="closing-x" onClick={this.closeModal}>
                {" "}
                X{" "}
              </button>

              <div className="project-header-2">
                <div className="project-logo"></div>
                <div className="project-data">
                  <div className="project-name">
                    {project ? project.name : "No name"}
                  </div>
                  <div className="project-context">
                    <span>
                      {" "}
                      Creada el:
                      <b>
                        {" "}
                        {project && project.creationDate
                          ? project.creationDate.substring(0, 10)
                          : "No Date"}{" "}
                      </b>
                    </span>
                    &nbsp;
                    <span>
                      {" "}
                      Nombre del Proyecto:{" "}
                      <b>
                        {project ? project.name : "No Admin"}
                      </b>{" "}
                    </span>
                  </div>
                  <div className="project-description">
                    {project
                      ? project.description
                      : "No description"}
                  </div>
                </div>
              </div>
              <div className="meeting-form">
                <div className="horizontal-flex"> 
                  <div className="meeting-details">
                    <div className="meeting-details-child-logos">
                      <MeetingDetails className={svgClass} />
                    </div>
                    <div className="meeting-details-child-3">
                      <div className={annotationColor}>
                        {" "}
                        <b>
                          {selectedMeeting &&
                          selectedMeeting.isFinished
                            ? "REALIZADA"
                            : "AGENDADA"}
                        </b>{" "}
                      </div>
                      <div>
                        {" "}
                        {selectedMeeting
                          ? selectedMeeting.topic
                          : ""}{" "}
                      </div>
                    </div>
                    <div className="meeting-details-child-1">
                      <div>
                        {" "}
                        <b className={annotationColor}>Vistas Usadas 0/</b>
                        { VIEW_3D.content.length }
                      </div>
                    </div>
                    <div className="meeting-details-child-logos">
                      <View3D className={littleSvgClass} />
                    </div>
                    <div className={lineClass}></div>
                    <div className="meeting-details-child-2">
                      <div>
                        {" "}
                        <b className={annotationColor}>Anotaciones Usadas 0/</b>
                        {annotationTypes ? annotationTypes.length : "0"}
                      </div>
                      <div>
                        {" "}
                        <b className={annotationColor}>
                          Instancias Totales {ANNOTATIONS.content.length}
                        </b>{" "}
                      </div>
                    </div>
                    <div className="meeting-details-child-logos">
                      <Marker className={littleSvgClass} />
                    </div>
                    <div className={lineClass}></div>
                    <div className="meeting-details-child-1">
                      <div>
                        {" "}
                        <b className={annotationColor}> Asistentes 0/</b>
                        { GUESTS.content.length }
                      </div>
                    </div>
                    <div className="meeting-details-child-logos">
                      <Guest className={littleSvgClass} />
                    </div>
                    <div className={lineClass}></div>
                    <div className="meeting-details-child-2">
                      <div>Fecha </div>
                      <div className={annotationColor}>
                        {" "}
                        <b>
                          {selectedMeeting && selectedMeeting.date
                            ? selectedMeeting.date.substring(0, 10)
                            : "dd/mm/aaaa"}{" "}
                        </b>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="widget-container-2"
                  id="job"
                  selected="Projects"
                >
                  <EditListWidget
                    content={VIEW_3D}
                    finished={ selectedMeeting.isFinished }
                    get={true}
                    isMeeting={true}
                  />

                  <EditListWidget
                    content={MARKERS}
                    finished={ selectedMeeting.isFinished }
                    get={true}
                    annInstances={ANNOTATIONS}
                    creatingMeeting={false}
                  />

                  <EditListWidget
                    content={GUESTS}
                    finished={ selectedMeeting.isFinished }
                    get={true}
                    isMeeting={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const connectedMeetingModal = connect(mapStateToProps)(MeetingModal);

export { connectedMeetingModal as MeetingModal };
