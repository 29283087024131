import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Links } from "../components/Links";
import { MeetingList } from "../components/MeetingList";
import { getMeetings } from "../actions";
import { ROUTES } from "../constants";
import { Button } from "../components/Button";
import {
  isBrowser,
} from "react-device-detect";
import { ReactComponent as IconMeetings } from './svg/icono_reuniones_virtuales.svg';
import { ReactComponent as Arrow } from "../routes/svg/icono_flecha.svg";
import "./styles/Redico.css";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";
import { MeetingContentType } from "../components/ListWidget/contentType/MeetingContentType";
import { ListWidget, MeetingModal } from "../components";
import { NewMeetingModal } from "../components/modals/NewMeetingModal";
import { AddMeetingGuestModal } from "../components/modals/AddMeetingGuestModal";
import { AddMeetingNoteModal } from "../components/modals/AddMeetingNoteModal";
import { AddMeetingViewModal } from "../components/modals/AddMeetingViewModal";

export const Meetings = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const meetings = useSelector((state) => state.MeetingReducer.meetings);

  useEffect(function SetMeetingsList(){
    dispatch(getMeetings());
  }, [dispatch]);

  const goTo = (routeName) => {
    if (location.pathname === routeName) {
      return;
    }
    history.push(routeName);
  }

  const links = [
    {
      link: "#",
      name: "Reuniones",
    },
  ];

  const content = {
    type: LIST_WIDGET_CONTENT_TYPE.MEETINGS,
    content: meetings,
    contentType: {
      ...MeetingContentType,
      RenderSettings: () => (<div />),
      RenderActions: () => (<div />),
    },
  }

  if (isBrowser) {
    return (
      <div className="main-content" selected="Meetings">
        <Links links={links} />
        <ListWidget content={content} fromMeeting={true}/>
        <MeetingModal />
        <NewMeetingModal />
        <AddMeetingGuestModal />
        <AddMeetingNoteModal />
        <AddMeetingViewModal />
      </div>
    );
  }

  return (
    <div className="main-content" selected="Meetings">
      <div className="mobile-title">
        <IconMeetings />
        REUNIONES
      </div>
      <div style={{marginLeft: "-0.3em", width: "99.75%"}}>
        <MeetingList content={meetings} />
      </div>
      <Button className="move-to-bottom-mobile button-purple" onClick={() => goTo(ROUTES.LOGGED_HOME)}>
        <Arrow />
      </Button>
    </div>
  );
};

