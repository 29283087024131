import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ROUTES, USER_TYPE } from "../constants";
import "./styles/Home.css";
import { ReactComponent as IconProyects } from './svg/icono_proyectos.svg';
import { ReactComponent as IconAdmin } from './svg/iconos_adm_proyectos.svg';
import { ReactComponent as IconNotes } from './svg/icono_anotaciones.svg';
import { ReactComponent as IconConfig } from './svg/icono_ajustes.svg';
import { ReactComponent as IconPayments } from './svg/icono_pagos.svg';
import { ReactComponent as Guests } from "../routes/svg/icono_invitado.svg";
import { ReactComponent as Meetings } from "../routes/svg/icono_reuniones_virtuales.svg";
import { ReactComponent as Downloads } from '../components/Icons/download-svgrepo-com.svg';
import { getProjects, getOrgUsers, getMeetings, getOrgMarkers } from "../actions";
//import { getLessons } from "../actions";

const mapStateToProps = (state) => {
  return {
    orgUser: state.UserReducer.orgUser,
    projects: state.ProjectReducer.projects,
    orgUsers: state.OrganizationReducer.orgUsers,
    meetings: state.MeetingReducer.meetings,
    orgMarkers: state.OrganizationReducer.orgMarkers,
    lessons: state.OrganizationReducer.lessons,
  };
};
  
class LoggedHome extends Component{

  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
  }

  componentDidMount() {
    this.SetProps();
  }

  SetProps() {
    if (!this.props.orgUser) return;
    this.props.dispatch(getMeetings()).catch((error) => { console.log(error) });

    if (this.props.orgUser.accountType === USER_TYPE.GUEST) return;
    this.props.dispatch(getProjects()).catch((error) => { console.log(error) });
    this.props.dispatch(getOrgUsers()).catch((error) => { console.log(error) });
    
    if (this.props.orgUser.accountType === USER_TYPE.MAINTAINER) return;
    this.props.dispatch(getOrgMarkers()).catch((error) => { console.log(error) });
  }

  goTo(routeName) {
    if (this.props.location.pathname === routeName) return;
    this.props.history.push(routeName);
  }


  render() {

    const projects = this.props.projects ?? [];

    const isAdmin = this.props.orgUser.accountType === USER_TYPE.ADMIN;
    const isMaintainer = this.props.orgUser.accountType === USER_TYPE.MAINTAINER;
    const isGuest = this.props.orgUser.accountType === USER_TYPE.Guests;
    const isAdminOrMaintainer = isAdmin || isMaintainer;

    return(
      <div className='main-content'>
        { 
          this.props.orgUser !== null ? 
            <div className="frame">
              <div className="modules">
                {  
                  isAdminOrMaintainer ?
                    <div className="proyects-module"> 
                      <span className="module-name"> Proyectos </span>
                      <span className="module-number"> {projects.length} </span>
                      <IconProyects fill='current'/>
                      <button className="module-button" onClick={()=> this.goTo(ROUTES.PROJECTS)}> VER DETALLES </button>
                    </div>
                  :
                    ''
                }
                { 
                  isAdminOrMaintainer ? 
                    <div className="admin-module">
                      <span className="module-name"> Usuarios </span>
                      <span className="module-number"> { this.props.orgUsers.length } </span>
                      {  this.props.orgUser.accountType === USER_TYPE.ADMIN ? <IconAdmin fill='current'/> : <Guests fill='current'/> }
                      <button className="module-button" onClick={()=> this.goTo(ROUTES.MY_ORG)}> VER DETALLES </button>
                    </div>
                  : 
                    ''
                }
                {  
                  isAdmin ?
                    <div className="notes-module"> 
                      <span className="module-name"> Anotaciones </span>
                      <span className="module-number"> { this.props.orgMarkers ? this.props.orgMarkers.length : 0 }</span>
                      <IconNotes fill='current'/>
                      <button className="module-button" onClick={()=> this.goTo(ROUTES.MARKERS)}> VER DETALLES </button>
                    </div>
                  :
                    ''
                }
                <div className="lessons-module">
                  <span className="module-name"> Descargas </span>
                  <span className="module-number"> { '-' } </span>
                  <Downloads fill='current' height={64}/>
                  <button className="module-button" onClick={()=> this.goTo(ROUTES.DOWNLOADS)}> VER DETALLES </button>
                </div>
                {  
                  isAdmin ?
                    <div className="config-module"> 
                      <span className="module-name"> Ajustes </span>
                      <span className="module-number">-</span>
                      <IconConfig fill='current'/>
                      <button className="module-button" onClick={()=> this.goTo(ROUTES.SETTINGS)}> VER DETALLES </button>
                    </div>
                  :
                    ''
                }
                {  
                  isAdmin ?
                    <div className="payments-module"> 
                      <span className="module-name"> Suscripción RVIP </span>
                      <span className="payment-state"> Pendiente </span>
                      <IconPayments fill='current'/>
                      <button className="module-button" onClick={()=> this.goTo(ROUTES.PAYMENTS)}> VER DETALLES </button>
                    </div>
                  :
                    ''
                }
                <div className="meeting-module">
                  <span className="module-name"> Reuniones </span>
                  <span className="module-number"> { this.props.meetings.length } </span>
                  <Meetings fill='current'/>
                  <button className="module-button" onClick={()=> this.goTo(ROUTES.MEETINGS)}> VER DETALLES </button>
                </div>
                {  
                  isMaintainer ?
                    <>
                      <div className="blank-module1"> </div>
                      <div className="blank-module2"> </div>
                      <div className="blank-module1"> </div>
                    </>
                  :
                    ''
                }
                {  
                  isGuest ?
                    <>
                      <div className="blank-module1"> </div>
                      <div className="blank-module2"> </div>
                      <div className="blank-module1"> </div>
                      <div className="blank-module1"> </div>
                      <div className="blank-module1"> </div>
                      <div className="blank-module1"> </div>
                    </>
                  :
                    ''
                }
                <div className="blank-module1"> </div>

              </div>
            </div> 
          :
            <></>
          }
        </div>     
      
      
      )
  }
}

const connectedLoggedHome =  connect(mapStateToProps)(LoggedHome);

const LoggedHomeWithRouter = withRouter(connectedLoggedHome);

export {LoggedHomeWithRouter as LoggedHome};