import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "../Button";
import { desregister, setDesregisterModal, closeAll, setVisibility, logout } from "../../actions"
import "./styles/Modal.css"
import { ROUTES } from "../../constants";
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";

const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalDesregister
    };
}

const initialState = {
    disabled: false,
};

class DesregisterConfirmModalModal extends Component{

    constructor(props){
        super(props);
        this.state = initialState;
        this.setUserOptions = this.setUserOptions.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
    }

    resetState(){
        this.setState(initialState);
    }

    setUserOptions(visibility) {
        this.setState({ ...this.state, userOptionsIsActive: visibility });
      }

    goTo(routeName) {
        this.setUserOptions(false);
        this.props.history.push(routeName);
        this.props.dispatch(closeAll(true));
        this.props.dispatch(setVisibility(false));
      }
      logout() {
        this.props.dispatch(logout()).then(() => {
          this.goTo(ROUTES.HOME);
        });
      }
    

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled:true});
        this.props.dispatch(desregister()).then(()=>{
            this.logout();
        }).catch(()=>{
            this.setState({...this.state, disabled:false});
        })
    }

    closeModal(){
        // this.resetState();
        // this.props.dispatch(selectUser(-1));
        this.props.dispatch(setDesregisterModal(false)).then(this.resetState);
    }

    render(){
        var modalState = 'modal ';
        modalState += this.props.isVisible? 'modal-open':'modal-closed';

        return(
            <div className={modalState}>
                    <div className="delete-modal">
                        <div className="delete-modal-box">
                                <Button className="modal-button"  onClick={this.closeModal}>
                                    <Close />
                                </Button>
                                <div id="MyModal" className="modal-content">
                                <div className="modal-warning">
                                Está seguro que desea <span>eliminar</span> la totalidad de su <span>usuario</span>. Si acepta esta acción no podrá entrar nuevamente a la aplicación
                                y deberá <span>registrarse nuevamente</span> para poder ingresar.
                                </div>
                                <div className='bottom-buttons'>
                                <Button className="red-button" onClick={this.handleSubmit}>
                                    ELIMINAR
                                </Button>
                                <Button className="purple-button" onClick={this.closeModal}>
                                    NO ELIMINAR
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

const connectedDesregisterConfirmModalModal = connect(mapStateToProps)(DesregisterConfirmModalModal);

export { connectedDesregisterConfirmModalModal as DesregisterConfirmModalModal };