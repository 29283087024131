import { ATTACHED_FILE_ACTIONS } from "../constants";

const initialState = {
  attachedFileList: [],
};

export const AttachedFileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ATTACHED_FILE_ACTIONS.GET_PROJECT_ATTACHED_FILE_LIST:
      return { ...state, attachedFileList: payload };
    case ATTACHED_FILE_ACTIONS.ADD_ATTACHED_FILE:
      const newState = {
        ...state,
        attachedFileList: [
          ...state.attachedFileList,
          payload,
        ],
      }; 
      return newState; 
    case ATTACHED_FILE_ACTIONS.DELETE_ATTACHED_FILE:
      return {
        ...state,
        attachedFileList: state.attachedFileList.filter((item) => item.id !== payload.deletedItem.id),
      };
    case ATTACHED_FILE_ACTIONS.UPDATE_ATTACHED_FILE:
      return {
        ...state,
        attachedFileList: state.attachedFileList.map((item) => {
          if (item.id === payload.id) {
            return payload;
          }
          return item;
        }),
      };
    default:
      return state;
  }
};
