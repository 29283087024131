import { ReactComponent as Projects } from '../../../routes/svg/icono_proyectos.svg';
import { ProjectActions } from "./actions/ProjectActions";
import { ProjectList } from "./lists/ProjectList";

const { LIST_WIDGET_CONTENT_TYPE } = require("../../../constants/ListWidgetContentType");

const RenderActions = ({ accessType, type }) => (<ProjectActions accessType={accessType} importing={type === LIST_WIDGET_CONTENT_TYPE.IMPORT_CATEGORIES}/>);

export const ProjectContentType = {
  type: LIST_WIDGET_CONTENT_TYPE.PROJECTS,
  RenderActions,
  RenderList: ProjectList,
  RenderIcon: () => (<Projects />),
};
