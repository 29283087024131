import { React, useEffect, useState } from 'react';
import './styles/ListWidget.css';
import './styles/List.css';
import { MiniButton, ListWidgetItem } from '../components';
import { ListWidgetItemMobile } from '../components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Markers } from '../routes/svg/icono_anotaciones.svg';
import { ReactComponent as Guests } from '../routes/svg/icono_invitado.svg';
import { ReactComponent as Meetings } from '../routes/svg/icono_reuniones_virtuales.svg';
import { ReactComponent as ThreeDView } from '../routes/svg/icono_vistas_3D.svg';
import { ReactComponent as Projects } from '../routes/svg/icono_proyectos.svg';
import { ReactComponent as GroupedMarkers } from '../routes/svg/icono_grupo_anotaciones.svg';
import {
  getProjectMeetings,
  getProjectMeetingCategories,
  getProjectViews,
  getProjectAnnotations,
} from '../actions';
import { useDispatch } from 'react-redux';
import { LIST_WIDGET_CONTENT_TYPE } from '../constants/ListWidgetContentType';

const formatDate = (date) => {
  if (date) {
    return date.split('T')[0].split('-').reverse().join(' / ');
  } else {
    return '';
  }
};

const CONTENT = {
  PROJECTS: {
    TITLE: 'PROYECTOS',
    ICON: <Projects />,
  },
  PROJECTS_MAN: {
    TITLE: 'PROYECTOS DEL USUARIO',
    ICON: <Projects />,
  },
  MEETINGS: {
    TITLE: 'REUNIONES VIRTUALES',
    ICON: <Meetings />,
  },
  VIEW_3D: {
    TITLE: 'VISTAS 3D',
    ICON: <ThreeDView />,
  },
  MARKERS: {
    TITLE: 'ANOTACIONES',
    ICON: <Markers />,
  },
  GROUPED_MARKERS: {
    TITLE: 'GRUPO ANOTACIONES',
    ICON: <GroupedMarkers />,
  },
  GUESTS: {
    TITLE: 'INVITADOS',
    ICON: <Guests />,
  },
  ADMINISTRADORES: {
    TITLE: 'ADMINISTRADORES DE PROYECTO',
    ICON: <Guests />,
  },
  MAINTAINERS: {
    TITLE: 'ADMINISTRADORES DE PROYECTO',
    ICON: <Guests />,
  },
  MEETING_CATEGORIES: {
    TITLE: 'TIPOS DE REUNION',
    ICON: <Meetings />,
  },
  IMPORT_CATEGORIES: {
    TITLE: 'PROYECTOS',
    ICON: <Projects />,
  },
};

export const ListWidgetMobile = ({ content, parentCallback, closeModal, parentCallbackImportMeeting, color }) => {
  const dispatch = useDispatch();
  const creando_proyecto = content.isSelectionList;
  const [, setCategoriesOptionsIsActive] = useState(false);
  const [list, setList] = useState(content.content);
  const [searchFilter, setSearchFilter] = useState('');
  if (!content.content) {
    content.content = [];
  }
  const [count, setCount] = useState(content.content.length);

  const [selectionArray, setSelectionArray] = useState([]);
  const [projectsSelected, setProjectsSelected] = useState([]);

  useEffect(() => {
    setList(content.content);
  }, [content.content]);

  useEffect(() => {
    setCount(list.length);
  }, [list.length]);

  useEffect(() => {
    if (!closeModal) {
      setCategoriesOptionsIsActive(false);
    }
  }, [closeModal]);

  let history = useHistory();

  const goToProject = (projectId) => {
    dispatch(getProjectMeetingCategories(projectId));
    dispatch(getProjectMeetings(projectId));
    dispatch(getProjectViews(projectId));
    dispatch(getProjectAnnotations(projectId));
    history.push(`/projects/${projectId}`);
  };

  const AddToSelectionArray = (selected) => {
    setSelectionArray([...selectionArray, selected]);
  };

  const RemoveItemFromSelectionArray = (item) => {
    setSelectionArray(selectionArray.filter(selectedItem => selectedItem.id !== item.id));
  };

  useEffect(() => {
    parentCallback(selectionArray)
  }, [parentCallback, selectionArray]);


  const AddToSelectedProjects = (selected) => {
    setProjectsSelected([...projectsSelected, selected]);
  }

  const RemoveItemFromSelectedProjects = (item) => {
    setProjectsSelected(projectsSelected.filter(selectedProject => selectedProject.id !== item.id));
  }

  useEffect(()=>{
    parentCallbackImportMeeting(projectsSelected);
  }, [parentCallbackImportMeeting, projectsSelected])


  const headerColor = color? color : "";

  return (
    <div className="list-container">
      <div className="list-header" id={headerColor}>
        <div className="list-info-mobile">
          {CONTENT[content.type].ICON}
          <span> {count} </span>
        </div>
        <div className="list-actions">
          <div className="search-box">
            <input
              className="search-input"
              type="text"
              placeholder="Buscar..."
              onChange={(event) => {
                setSearchFilter(event.target.value);
              }}
            />
          </div>
          <MiniButton type="filter" />
        </div>
      </div>

      {/* La siguiente condicion se aplica solo para que al elegir un administrador no se caiga la pagina, 
      ya que todavia no tenemos el endpoint para encontrar proyectos por medio del administrador*/}
      <div className="list-content">
        {(content.type === LIST_WIDGET_CONTENT_TYPE.PROJECTS ||
          content.type === LIST_WIDGET_CONTENT_TYPE.PROJECTS_MAN ||
          content.type === LIST_WIDGET_CONTENT_TYPE.IMPORT_CATEGORIES) ? (
          <div>
            {list.map((item, index) => 
            item.name?.includes(searchFilter) && 
            (
              <ListWidgetItem
                type={content.type}
                key={index}
                itemId={item.id}
                itemName={item.name}
                subText={item.OrganizationId}
                creationDate={item.createdAt ? item.createdAt.substring(0, 10) : 'Missing Date'}
                description={item.description}
                importing={content.type === LIST_WIDGET_CONTENT_TYPE.IMPORT_CATEGORIES}
                funcionProjectElegido={AddToSelectedProjects}
                funcionProjectDescartado={RemoveItemFromSelectedProjects}
                onClick={goToProject}
              />
            ))}
          </div>
        ) : content.type === LIST_WIDGET_CONTENT_TYPE.MEETINGS ? (
          list.length !== 0 ? (
            list.map(
              (item, index) =>
                item.topic?.includes(searchFilter) && (
                  <ListWidgetItemMobile
                    type={content.type}
                    key={index}
                    itemId={item.id}
                    itemName=""
                    subText={
                      item.date
                        ? 'AGENDADA ' +
                          item.date.substring(0, 10) +
                          ' a las  ' +
                          item.date.substring(11, 19)
                        : ''
                    }
                    description={item.topic}
                    projectId={item.projectId}

                    //funcionReu={funcionReu}
                  />
                )
            )
          ) : (
            <div></div>
          )
        ) : content.type === LIST_WIDGET_CONTENT_TYPE.VIEW_3D ? (
          list.length !== 0 ? (
            list.map((item, index) => (
              <ListWidgetItemMobile
                type={content.type}
                key={index}
                itemId={item.id}
                subText={`Versión 1`} // queda hardcodeado por ahora para resolver error más tarde
                description={item.fileName}
                // creationDate={item.createdAt.slice(0, 10)}
                funcionElegido={AddToSelectionArray}
                funcionDescartado={RemoveItemFromSelectionArray}
                creando={creando_proyecto}
                creationDate={formatDate(item.createdAt)}
                onClick={goToProject}
              />
            ))
          ) : (
            <div /> // En caso de que no existan vistas 3D
          )
        ) : content.type === LIST_WIDGET_CONTENT_TYPE.MARKERS ? (
          <div>
            {list.length !== 0 ? (
              list.map((item, index) => (
                <ListWidgetItemMobile
                  type={content.type}
                  key={index}
                  itemId={item.id}
                  itemName={item.name}
                  subText={item.color}
                  description={item.isStandard? "Anotación Estandar" : "Anotación Personalizada"}
                  creationDate={item.creationDate??formatDate(item.createdAt)}
                  creando={creando_proyecto}
                  funcionElegido={AddToSelectionArray}
                  funcionDescartado={RemoveItemFromSelectionArray}
                />
              ))
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div>
            {list.length !== 0 ? (
              list.map((item, index) => (
                <ListWidgetItemMobile
                  type={content.type}
                  key={index}
                  itemId={item.id}
                  itemName={item.itemName}
                  creationDate={item.creationDate}
                  subText={item.subText}
                  description={item.description}
                  onClick={goToProject}
                  creando={creando_proyecto}
                  funcionElegido={AddToSelectionArray}
                  funcionDescartado={RemoveItemFromSelectionArray}
                />
              ))
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
