
import './styles/ListItemOptions.css';
import './styles/AttachedFileListItemOptions.css';
import { Button } from '@mui/material';
import { SetAttachedFileDeleteModal, SetAttachedFileUpdateModal } from '../../../../../actions/ModalActions';
import { useDispatch } from 'react-redux';

export const AttachedFileListItemOptions = ({
  item,
  posX,
  posY,
  isVisible,
  setVisibility,
}) => {

  const dispatch = useDispatch();

  const hide = () => setVisibility(false);

  const handleEditClick = () => {
    dispatch(SetAttachedFileUpdateModal(true, item)).then(hide);
  };
  
  const handleDeleteClick = () => {
    dispatch(SetAttachedFileDeleteModal(true, item)).then(hide);
  };

  return (
    <div className={`list-item-options ${ isVisible ? 'visible' : '' }`} 
         style={{top: `${ posY - 75}px`, left: `${ posX + 40}px`}}
         onMouseLeave={hide}
         >
      <Button onClick={handleEditClick}>
        replace
      </Button>
      <Button onClick={handleDeleteClick}>
        delete
      </Button>
    </div>
  );
};
