import { Button } from '../../../..';
import { API_ROUTES } from '../../../../../constants';
import { RequestProduct } from '../../../../../services/ProductsService';
import './styles/ProductListItem.css';


const TYPE_TEXT = {
  file: 'Descargar',
  link: 'Ir al sitio de descarga',
  request: 'solicitar acceso',
}

export const ProductListItem = ({ name, description, type, fileKey }) => {

  const onClick = () => {
    if (type === 'file') {
      // download file
      window.open(`${API_ROUTES.PRODUCTS}/${name}`);
      return;
    }
    if (type === 'request') {
      // send request
      RequestProduct(name).then(message=>{
        alert(message);
      }).catch(error=>{
        alert(error.message);
      });
      // open modal
      return;
    }
    // go to link
    window.open(fileKey).focus();
  }

  return(
    <div className='list-item project product'>
      <div>
        <h3>
          { name.replaceAll('_', ' ') }
        </h3>
        <p>
          { description?? '' }
        </p>
      </div>
      <Button onClick={onClick}>
        { TYPE_TEXT[type] ?? 'Descargar' }
      </Button>
    </div>
  );
};
