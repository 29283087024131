import { ACCESS_TOKEN, ACCESS_TOKEN_HEADER, API_ROUTES } from "../constants";

class UsersService {

    static async signup(signupData) {
        return fetch(API_ROUTES.SIGNUP, {
            method: 'POST',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(signupData)
        }).then(async res => {
            const resJson = await res.json();
            if (res.status === 201) {
                return resJson.message;
            }
            throw resJson.message;
        }).catch(err => {
            throw(err)
        })
    }

    static async signin(signinData) {
        return fetch(API_ROUTES.SIGNIN, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(signinData)
        }).then(async res => {
            if (res.status === 401) {
                throw new Error("Mail y/o contraseña inválidos");
            }
            const resJson = await res.json();
            if (res.status === 200) {
                localStorage.setItem(ACCESS_TOKEN, resJson.accessToken);
                return resJson;
            }
            throw resJson.message;
        }).catch(err => {
            throw(err)
        })
    }

    static async getUsers() {
        const headers = {};
        headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);

        return fetch(API_ROUTES.USERS, {
            method: 'GET',
            headers
        }).then(res => {
            // handle success
            if (res.status === 200) {
                return res.json().then(data=>{
                    return data.members;
                })
            }
            // handle failure
            return [];
        }).catch(err => {
            console.error('Caught error: ', err);
        })
    }

    
    static async getUserData() {
        const headers = {};
        headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);

        return fetch(API_ROUTES.USER_DATA, {
            method: 'GET',
            headers
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.user;
            }
            // handle failure
            throw resJson.message;
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async verifyUser(token, body ) {
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }; 
        headers[ACCESS_TOKEN_HEADER] = token;
        return fetch(API_ROUTES.VERIFY, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.message;
            }
            // handle failure
            throw resJson.message;
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async updateSelf(userData) {
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
        return fetch(API_ROUTES.UPDATE_SELF, {
            method: 'PUT',
            headers,
            credentials: 'include',
            body: JSON.stringify(userData)
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.user;
            }
            // handle failure
            throw resJson.message;
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async updateUser(userId, userData) {
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
        return fetch( `${API_ROUTES.USERS}/${userId}`, {
            method: 'PUT',
            credentials: 'include',
            headers,
            body: JSON.stringify(userData)
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.user;
            }
            // handle failure
            throw resJson.message;
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async getUser(userId) {
        return fetch( `${API_ROUTES.USERS}/${userId}`, {
            method: 'GET',
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.user;
            }
            // handle failure
            throw resJson.message;
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async deleteUser(userId) {
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
        return fetch( `${API_ROUTES.USERS}/${userId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                localStorage.setItem(ACCESS_TOKEN, resJson.accessToken);
                return resJson.deletedUser;
            }
            // handle failure
            throw resJson.message;
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async desregisterUser() {
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
        return fetch( API_ROUTES.DESREGISTER, {
            method: 'DELETE',
            credentials: 'include',
            headers
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.deletedUser;
            }
            // handle failure
            throw resJson.message;
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async addUser(userData, isMantainer){
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);

        return fetch(isMantainer ? API_ROUTES.NEW_MANTAINER : API_ROUTES.NEW_GUEST, {
            method: 'POST',
            headers,
            body: JSON.stringify( userData)
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.newUser;
            }
            // handle failure
            throw resJson.message;
        }).catch(err => {
            console.error('Caught error: ', err);
            throw new Error(err);
        })
    }

    static async getUsersOrganizations() {
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };

        return fetch(API_ROUTES.USERS_ORGANIZATIONS, {
            method: 'GET',
            credentials: 'include',
            headers,
        }).then(res => {
            // handle success
            if (res.status === 200) {
                return res.json().then(data=>{
                    return data.organizations
                })
            }
            // handle failure
            return [];
        }).catch(err => {
            console.error('Caught error: ', err);
        })
    }

    static async selectOrganization(orgId) {
        return fetch(API_ROUTES.USERS_ORGANIZATIONS, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(orgId)
        }).then(async res => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            }
            throw resJson.message;
        }).catch(err => {
            throw(err)
        })
    }

    static async logOut() {
        return fetch(API_ROUTES.LOGOUT, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
        }).catch(err => {
            throw(err)
        })
    }



}

export { UsersService };