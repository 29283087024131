import React, { useState } from "react";
import { ROUTES } from "../constants";

import { Button } from "./Button";

import { ReactComponent as Guests } from "../routes/svg/icono_invitado.svg";
import { ReactComponent as Meetings } from "../routes/svg/icono_reuniones_virtuales.svg";
import { ReactComponent as Projects} from "../routes/svg/icono_proyectos.svg"
import { ReactComponent as ThreeDView } from "../routes/svg/icono_vistas_3D.svg";
import { ReactComponent as Arrow } from "../routes/svg/icono_flecha.svg";

import "./styles/ProjectSidePanel.css";
import { ANNOTATION_SECTION_VIEW_TYPE } from ".";
import { useHistory, useLocation } from "react-router-dom";

export const NotesSidePanel = ({ handleChangeView, color}) => {

  const location = useLocation();
  const history = useHistory();

  const [viewMode, setViewMode] = useState(ANNOTATION_SECTION_VIEW_TYPE.PROJECT_LIST)

  const goTo = (routeName) => {
    if (location.pathname === routeName) return;
    history.push(routeName);
  };

  const changeView = (newViewMode) => {
    handleChangeView(newViewMode);
    setViewMode(newViewMode);
  };

  const selected = "selected " + color;
  const backButtonClass = "move-to-bottom color " + color;

  return (
    <div className="mini-side-panel" >
      <Button className={`svg-container ${ viewMode === ANNOTATION_SECTION_VIEW_TYPE.PROJECT_LIST ? selected : "" }`}
              tooltip="Proyectos"
              onClick={() => changeView(ANNOTATION_SECTION_VIEW_TYPE.PROJECT_LIST)} >
        <Projects x="1.25rem" y="1.75rem" />
      </Button>
      <Button className={`svg-container ${ viewMode === ANNOTATION_SECTION_VIEW_TYPE.MEETING_LIST ? selected : "" }`}
              tooltip="Reuniones"
              onClick={() => changeView(ANNOTATION_SECTION_VIEW_TYPE.MEETING_LIST)} >
        <Meetings x=" 8.25rem" y="1.75rem" />
      </Button>
      <Button className={`svg-container ${ viewMode === ANNOTATION_SECTION_VIEW_TYPE.VIEW_LIST ? selected : "" }`}
              tooltip="Vistas 3D"
              onClick={() => changeView(ANNOTATION_SECTION_VIEW_TYPE.VIEW_LIST)} >
        <ThreeDView x="1.25rem" y="1.75rem" />
      </Button>
      <Button className={`svg-container ${ viewMode === ANNOTATION_SECTION_VIEW_TYPE.AUTHOR_LIST ? selected : "" }`}
              tooltip="Autores"
              onClick={() => changeView(ANNOTATION_SECTION_VIEW_TYPE.AUTHOR_LIST)} >
        <Guests x="8.25rem" y="1.75rem" />
      </Button>
      <Button className={backButtonClass} tooltip="volver" 
              tooltipPosition="top" 
              onClick={() => goTo(ROUTES.MARKERS)} >
        <Arrow x="8.25rem" y="1.75rem" />
      </Button>
    </div>
  );
};
