import { useHistory } from "react-router-dom";
import { React, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../styles/ListWidget.css";
import "../../../../styles/List.css";
import "../../../../styles/ListMarker.css";
import "../../../../styles/ProjectSection.css";
import "../../../../styles/Meetings.css";
import { ReactComponent as Meeting } from "../../../../../routes/svg/icono_reunión_virtual.svg";
import { DeleteConfirmModalMeeting } from "../../../../modals/DeleteConfirmModalMeeting";
import { getProject, getProjectAnnotations, getProjectMeeting, getProjectViews, selectMeeting, setDeleteMeeting, setGetMeetingModal, setMeetingModal } from "../../../../../actions";
import { USER_TYPE } from "../../../../../constants";
import { Button } from "../../../../Button";


export const MeetingListItem = ({
  type,
  meeting,
  itemId,
  itemName,
  subText,
  projectId,
  description,
  finished,
  fromMeeting,
}) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const history = useHistory();

  // store references
  const orgUser = useSelector((state) => state.UserReducer.orgUser);
  const project = useSelector((state) => state.ProjectReducer.selectedProject);
  const meetings = useSelector((state) => state.MeetingReducer.meetings);

  // state
  const [active, setActive] = useState(false);
  
  const class_item = "list-item project";

  useEffect(() => {
    const listener = (event) => {
      if (!active) return;
      // Do nothing if clicking ref's element or descendent elements
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    };
    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, [active]);

  const toggleMeetingOptions = () => {
    setActive(!active);
  };

  const handleDeleteMeeting = () => {
    if (fromMeeting) {
      const result = meetings.find((x) => x.id === itemId);
      if (result) {
        dispatch(getProject(result.Project.id));
      }
    }
    dispatch(setDeleteMeeting(true, itemId));
    setActive(false);
  };

  const handleUpdateMeeting = () => {
    if (orgUser.accountType === USER_TYPE.GUEST) return;
    dispatch(getProjectViews(meeting.ProjectId));
    dispatch(getProjectAnnotations(meeting.ProjectId));
    dispatch(selectMeeting(projectId, itemId)).then(()=>{
      dispatch(setMeetingModal(true))
    });
    setActive(false);
  };

  const handleShowMeeting = () => {
    if (orgUser.accountType === USER_TYPE.GUEST) return;
    dispatch(getProjectMeeting(projectId, itemId)).then(
      dispatch(setGetMeetingModal(true))
    );
    setActive(false);
  };

  var idFinished = finished ? "green" : "";

  return (
    <div className={`${ class_item } ${ idFinished }`} id={ idFinished }>
      <div className="project-left-content">
        <div className={ `list-item-icon ${ idFinished }` }>
          <Meeting />
        </div>
        <div className="project-data">
          <div className="project-name">
            { itemName }
          </div>
          <div className={ `project-context ${ idFinished }` }>
            <span> { subText } </span>
          </div>
          <div className="project-description"> { description } </div>
        </div>
      </div>
      <div className="project-right-content">
        <div>
          <div className="meeting-options-and-button">
            <Button className={"move-right general-button"} onClick={toggleMeetingOptions}>
              ...
            </Button>
            <div ref={ref} className={`meeting-options meeting-mouse-up ${active ? "active" : "hidden"}`}>
              <div>
                <Button className="button-vertical project-mouse-up" onClick={handleDeleteMeeting}>
                  Eliminar reunión
                </Button>
              </div>
              {
                !finished ? (
                  <Button className="button-vertical project-mouse-up" onClick={handleUpdateMeeting}>
                    Editar reunión
                  </Button>
                  ) : (<div />)
              }
              <Button className="button-vertical project-mouse-up" onClick={handleShowMeeting}>
                Ver detalles
              </Button>
            </div>
          </div>
          <DeleteConfirmModalMeeting
            history={ history }
            meetingId={ itemId }
            meetingDescription={ description }
            fromMeeting={ fromMeeting }
            projectId={ project && project.id }
          />
        </div>
      </div>
    </div>
  );
};
