exports.LIST_WIDGET_CONTENT_TYPE = {
  MEETINGS: 'MEETINGS',
  ANN_MEETINGS: 'ANN_MEETINGS',
  PROJECTS: 'PROJECTS',
  PROJECTS_MAN: 'PROJECTS_MAN',
  IMPORT_CATEGORIES: 'IMPORT_CATEGORIES',
  MAINTAINERS: 'MAINTAINERS',
  ORG_USERS: 'ORG_USERS',
  MEETING_CATEGORIES: 'MEETING_CATEGORIES',
  MARKERS: 'MARKERS',
  VIEW_3D: 'VIEW_3D',
  DOWNLOADS: 'DOWNLOADS',
  DATA: 'DATA',
};
