import { MODAL_ACTION } from "../constants"

export const setUserModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_USER_MODAL,
        payload: modalVisibility
    });
}

export const setDeleteUserModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_DELETE_USER_MODAL,
        payload: modalVisibility
    });
}

export const setDesregisterModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_DESREGISTER_MODAL,
        payload: modalVisibility
    });
}

export const setDeleteOrgModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_DELETE_ORG_MODAL,
        payload: modalVisibility
    });
}

export const setOrganizationModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_ORGANIZATION_MODAL,
        payload: modalVisibility
    });
}

export const setProjectModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_PROJECT_MODAL,
        payload: modalVisibility
    });
}

export const setMarkerModal= (modalVisibility) => async (dispatch) => {
    dispatch({
        type: MODAL_ACTION.SET_MARKER_MODAL,
        payload: modalVisibility
    });
}

export const setProjectMaintainerModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_PROJECT_MAINTAINER_MODAL,
        payload: modalVisibility
    });
}

export const setDataModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_DATA_MODAL,
        payload: modalVisibility
    });
}


export const setDeleteProjectMaintainer = (modalVisibility, maintainerId) => async (dispatch) => {
    dispatch({
        type: MODAL_ACTION.SET_DELETE_PROJECT_MAINTAINER,
        payload: {modalVisibility, maintainerId}
    });
}

export const setAddMeetingCategoryModal = (modalVisibility, parent) => async (dispatch) => {
    const respuesta = {
        "modalVisibility":modalVisibility,
        "parent":parent,
    }
    dispatch({
        type: MODAL_ACTION.SET_ADD_MEETING_CATEGORY_MODAL,
        payload: respuesta,
    });
}

export const setConfigCategoryMeetingModal = (modalVisibility) => async (dispatch) => {
    dispatch({
        type: MODAL_ACTION.SET_CONFIG_CATEGORY_MEETING_MODAL,
        payload: modalVisibility,
    });
}

export const setImportTypesOfMeetingModal = (modalVisibility) => async (dispatch) => {
    dispatch({
        type: MODAL_ACTION.SET_IMPORT_TYPES_OF_MEETING_MODAL,
        payload: modalVisibility,
    });
}


export const closeAll = (closeAll) => {
    return {
        type: MODAL_ACTION.CLOSE_ALL,
        payload: closeAll
    }
}

export const setMeetingModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_MEETING_MODAL,
        payload: modalVisibility
    });
}

export const setDeleteMeeting= (modalVisibility, meetingId) => async (dispatch) => {
    dispatch({
        type: MODAL_ACTION.SET_DELETE_MEETING_MODAL,
        payload: {modalVisibility, meetingId}
    });
}

export const setDeleteViewModal= (modalVisibility, viewId) => async (dispatch) => {
    dispatch({
        type: MODAL_ACTION.SET_DELETE_VIEW_MODAL,
        payload: {modalVisibility, viewId}
    });
}


export const setAddMeetingGuestModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_MEETING_GUEST_MODAL,
        payload: {modalVisibility}
    });
}

export const setAddMeetingViewModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_MEETING_VIEW_MODAL,
        payload: {modalVisibility}
    });
}

export const setAddMeetingNoteModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_MEETING_NOTE_MODAL,
        payload: {modalVisibility}
    });
}

export const setGetMeetingModal = (modalVisibility) => async (dispatch)  => {
    dispatch({
        type: MODAL_ACTION.SET_GET_MEETING_MODAL,
        payload: modalVisibility,
    });
}

export const setAnnotationModal = (modalVisibility) => async (dispatch) => {
    dispatch({
        type: MODAL_ACTION.SET_ANNOTATION_MODAL,
        payload: {modalVisibility}
    });
}

export const SetAttachedFileDeleteModal = (modalVisibility, selectedItem) => async (dispatch) => {
    dispatch({
        type: MODAL_ACTION.SET_ATTACHED_FILE_DELETE_MODAL,
        payload: {
            modalVisibility,
            selectedItem,
        }
    })
}

export const SetAttachedFileUpdateModal = (modalVisibility, selectedItem) => async (dispatch) => {
    dispatch({
        type: MODAL_ACTION.SET_ATTACHED_FILE_UPDATE_MODAL,
        payload: {
            modalVisibility,
            selectedItem,
        },
    });
};
