import { useDispatch } from "react-redux";
import { setUserModal } from "../../../../actions";
import { MiniButton } from "../../../MiniButton";
import { NewUserModal } from "../../../modals";

export const MembersActions = () => {

  const dispatch = useDispatch();

  const openNewUserModal = () => {
    dispatch(setUserModal(true));
  };

  return (
    <div>
      <MiniButton type="create" onClick={openNewUserModal} />
      <NewUserModal />
    </div>
  );
};
