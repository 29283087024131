import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateOrganization } from '../actions';

export const UpdateOrgInfoForm = ( props ) => {
    const organization = useSelector((state) => state.OrganizationReducer.organization);
    var date;

    let history = useHistory();
    const dispatch = useDispatch();

    if (organization!= null ){
        const year = organization.expirationDate.substring(0, 4);
        const month = organization.expirationDate.substring(5, 7);
        const day = organization.expirationDate.substring(8, 10);
        date = new Date(year, month, day);
    }

    const [name, setName] = useState(organization.name);
    const [accountType, setType] = useState(organization.type);
    const [expirationDate, setDate] = useState(date);
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const newDate = new Date(expirationDate)
        const body = {
            name: name,
            accountType: accountType,
            expirationDate: newDate.toISOString().slice(0, 10)
        }
        dispatch(updateOrganization(body));
        props.onSuccess();
        history.push("/my-account")
    }



    return (
        <div className="display-info-user">
            <br/>
                <img alt="" src="https://cdn-icons-png.flaticon.com/512/484/484522.png"></img>
            <br/>
            <form onSubmit={handleSubmit}> 
                <span className="profile-items">Nombre de la Organización</span>
                <br/>
                <input 
                    className="input-box" 
                    fieldname="name" 
                    type="text"
                    onChange={e => setName(e.target.value)}
                    defaultValue={organization.name}>
                </input>
                <br/>
                <br/>
                <span className="profile-items">Tipo</span>
                <br/>
                <select className="input-box" fieldname="accountType" defaultValue={organization.accountType} onChange={e => setType(e.target.value)}>
                    <option value="VR">Realidad Virtual</option>
                    <option value="AR">Realidad Aumentada</option>
                </select>
                <br/>
                <br/>
                <span className="profile-items"> Fecha de pago </span>
                <br/>
                <input 
                    className="input-box" 
                    fieldname="name" 
                    type="date" 
                    onChange={e => setDate(e.target.value)}
                    defaultValue={date.toISOString().slice(0, 10)}>
                </input>
                <div>
                    <div className="profile-notice">
                        {/* {this.notice} */}
                    </div>
                    <input className="submit-info-button" type="submit" value="Submit"/>
                </div>
            </form>
        </div>
    );
  };