import React, { Component } from "react";
import { connect } from "react-redux";
import { updateProject, setProjectModal } from "../../actions"
import { TextField } from "../TextField";
import { IS_INT } from "../../constants";
import { BiRocket } from "react-icons/bi";
import { FaTimes } from 'react-icons/fa';
import "./styles/Modal.css"
import "./styles/UpdateProjectModal.css"


const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalProject,
        users: state.UserReducer.users,
        currentUser: state.UserReducer.currentUser,
        selectedProject: state.ProjectReducer.selectedProject,
        projects: state.ProjectReducer.projects
        
    };
}

let initialState = {
    disabled: false
};


class UpdateModalProject extends Component{

    constructor(props){
        super(props);
        
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
    }

    commponentDidUpdate(){
        if(!this.props.selectedProject.name && !this.state.name){
            this.setState({...this.state, name: this.props.selectedProject.name, description: this.props.selectedProject.description});
        }
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        var newState = this.state;
        newState[event.target.name] = event.target.value
        this.setState(newState);
    }

    goToProject() {
        this.resetState();
        this.props.history.push(`/projects/${this.props.selectedProject.id}`);
    }

    resetState(){
        this.setState(initialState);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled:true});
        const projectData = {}
        const fields = ['name', 'description']
        fields.forEach(field=>{
            if (this.state[field] !== undefined || this.state[field] !== null || this.state[field] !== '') {
                projectData[field] = IS_INT.test(this.state[field]) ? parseInt(this.state[field]) : this.state[field];
            }
        });
        const onSuccess = ()=>{this.closeModal();};
        const onFailure = (error)=>{
            console.error(error.fieldErrors);
            this.setState({...this.state, disabled:false});
        };
        this.props.dispatch(updateProject(this.props.selectedProject.id, projectData)).then(onSuccess).catch(onFailure);
        this.resetState();
        this.goToProject();
        return
    }

    closeModal() {
        this.resetState();
        // this.props.dispatch(selectUser(-1));
        this.props.dispatch(setProjectModal(false)).then(this.resetState);
    }

    render(){
        var modalState = 'add-modal ';
        modalState += this.props.isVisible? 'modal-open':'modal-closed';

        return(
            <div className={modalState}>
                <div className="project-modal-box">
                    <div id="MyModal" className="modal-content">
                        <button className="closing-x" onClick={this.closeModal}>
                            <FaTimes/>
                        </button>
                        <h2>EDITAR PROYECTO</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="update-project-top">
                                <div className="update-project-left-content">
                                    <div className="update-project-logo">
                                        <BiRocket size={50} />
                                    </div>
                                </div>
                                <div className="update-project-right-content">
                                    <TextField 
                                        className="name-project-field" 
                                        fieldName="name" 
                                        type="text" 
                                        value={this.state.name} 
                                        placeholder={this.props.selectedProject? this.props.selectedProject.name : "Nombre Proyecto"} 
                                        //defaultValue={this.props.selectedProject ? this.props.selectedProject.name : "Descripción Proyecto"}
                                        onChange={this.handleChange}>
                                    </TextField>
                                    <TextField
                                        className="description-project-field"
                                        fieldName="description" 
                                        maxlength={200}
                                        type="text" 
                                        value={this.state.description} 
                                        placeholder={this.props.selectedProject ? this.props.selectedProject.description : "Descripción Proyecto"} 
                                        //defaultValue={this.props.selectedProject ? this.props.selectedProject.description : "Descripción Proyecto"}
                                        onChange={this.handleChange}>
                                    </TextField>
                                </div>
                            </div>
                            <div className="update-project-bottom">
                                <input className="update-project-button" type="submit" value="EDITAR PROYECTO" disabled={this.state.disabled}/>
                            </div>
                        </form>
                    </div> 
                </div>
            </div>
        )
    }
}

const connectedUpdateModalProject = connect(mapStateToProps)(UpdateModalProject);

export { connectedUpdateModalProject as UpdateModalProject };