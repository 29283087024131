const baseURL = process.env.REACT_APP_API_URL;

const authRoutes = baseURL + "/api/auth";
const usersRoutes = baseURL + "/api/users";
const organizationsRoutes = baseURL + "/api/organizations";
const projectsRoutes = baseURL + "/api/projects";
const orgUserRoutes = baseURL + "/api/orgusers";
const orgMarkersRoute = baseURL + "/api/organization/annotation-types";
const meetingsRoutes = baseURL + "/api/meetings";
const productsRoutes = baseURL + "/api/products";

const API_ROUTES = {
  CATCH_ALL: baseURL,
  SIGNUP: authRoutes + "/signup",
  SIGNIN: authRoutes + "/signin",
  VERIFY: authRoutes + "/verify",
  LOGOUT: authRoutes + "/logout",

  USER_DATA: usersRoutes + "/user-data",
  USERS_ORGANIZATIONS: usersRoutes + "/organizations",
  UPDATE_SELF: usersRoutes,
  DESREGISTER: usersRoutes,
  USERS: usersRoutes + "",
  NEW_MANTAINER: orgUserRoutes + "/new-maintainer",
  NEW_GUEST: orgUserRoutes + "/new-guest",
  ORG_USERS: orgUserRoutes,
  ORG_MARKERS: orgMarkersRoute,

  ORG_INFO: organizationsRoutes,
  
  PRODUCTS: productsRoutes,

  PROJECTS: projectsRoutes + "",
  PROJECTS_ASSIGNED: orgUserRoutes,

  MEETINGS_DATA: meetingsRoutes,
};

export { API_ROUTES };
