import React, { Component } from "react";
import { TextField } from "./TextField";
import { connect } from "react-redux";
import { verifyUser } from "../actions";
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const initialState = {
    password: '',
    passwordVerification: '',
    disabled: false,
    showSecret: false,
    showSecret2: false,
};

class VerifyAccountForm extends Component{
    
    constructor(props) {
        super(props);
        this.state = initialState;
    
        this.notice = '';
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState(initialState);
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        var newState = this.state;
        newState[event.target.name] = event.target.value

        this.setState(newState);
    }
    
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled: true});
        // handle validation
        const token = {
            token: this.props.token,
        }
        const body = {
            password: this.state.password,
            passwordVerification: this.state.passwordVerification
        }
        // send request
        this.props.dispatch(verifyUser(token, body)).then(()=>{
            this.setState(initialState);
            this.props.onSuccess();
        }).catch(error=>{
            console.log(error);
            this.notice = error;
            this.setState({...this.state, disabled: false});
        })
    }

    handleShowSecret = (event) => {
        this.setState({
            showSecret: event.type === "mousedown" || event.type === "touchstart",
        });
    }
    
    handleShowSecret2 = (event) => {
        this.setState({
            showSecret2: event.type==="mousedown" || event.type==="touchstart",
        });
    }


    render() {
        return(
            <div className="signin-content signin-box center-div">
                <h4 className="verify-message">
                    Por favor ingrese su nueva contraseña
                </h4>

                <form onSubmit={this.handleSubmit}>
                    Contraseña:
                    <TextField required="true" className="login-field" placeholder="" fieldName="password" type={this.state.showSecret?"text":"password"} value={this.state.password} onChange={this.handleChange}></TextField>
                    <span toggle="#password-field" className="fa fa-fw field-icon" onMouseDown={this.handleShowSecret} onMouseUp={this.handleShowSecret} onMouseMove={this.handleShowSecret} onTouchStart={this.handleShowSecret} onTouchEnd={this.handleShowSecret} onTouchMove={this.handleShowSecret}>{this.state.showSecret?< FaEyeSlash />:< FaEye />}</span>
                    Repetir contraseña:
                    <TextField required="true" className="login-field" placeholder="" fieldName="passwordVerification" type={this.state.showSecret2?"text":"password"} value={this.state.passwordVerification} onChange={this.handleChange}></TextField>
                    <span toggle="#password-field" className="fa fa-fw field-icon" onMouseDown={this.handleShowSecret2} onMouseUp={this.handleShowSecret2} onMouseMove={this.handleShowSecret2} onTouchStart={this.handleShowSecret2} onTouchEnd={this.handleShowSecret2} onTouchMove={this.handleShowSecret2}>{this.state.showSecret2?< FaEyeSlash />:< FaEye />}</span>
                    <div className="notice-div">
                        {this.notice}
                    </div>
                    <div className="button-div"> 
                        <input className="login-button" type="submit" value="VERIFICAR" disabled={this.state.disabled}/>
                    </div>
                </form> 
                <br/>
                <br/>
            </div>
        );
    }
}

const connectedVerifyAccountForm = connect(null)(VerifyAccountForm);

export {connectedVerifyAccountForm as VerifyAccountForm};