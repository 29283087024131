import * as XLSX from 'xlsx/xlsx.mjs';
import { CHAR2INT } from './CHAR2INT';
import { FormatDate } from './FormatHelper';

export const ReadActivities = ({spreadSheetFile}) => {
  return ReadXLSX({spreadSheetFile, readAs: GetActivitiesFromSpreadSheet});
};

export const ReadRestrictions = ({spreadSheetFile}) => {
  return ReadXLSX({spreadSheetFile, readAs: GetRestrictionsFromSpreadSheet});
};

export const ReadIntegration = ({spreadSheetFile}) => {
  return ReadXLSX({spreadSheetFile, readAs: GetIntegrationFromSpreadSheet});
};

const ReadXLSX = ({spreadSheetFile, readAs}) => {

  var result = {}
  if (!readAs) return result;
  if (typeof (FileReader) === "undefined") return result;

  var reader = new FileReader();

  //For IE Browser.
  if (!reader.readAsBinaryString) {
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };reader.onload = function (e) {
        var data = "";
        var bytes = new Uint8Array(e.target.result);
        for (var i = 0; i < bytes.byteLength; i++) {
          data += String.fromCharCode(bytes[i]);
        }
        result = readAs({data});
        resolve(result);
      };
      reader.readAsArrayBuffer(spreadSheetFile);
    });
  }

  //For Browsers other than IE.
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };
    reader.onload = function (e) {
      result = readAs({data: e.target.result});
      resolve(result);
    };
    reader.readAsBinaryString(spreadSheetFile);
  });
}

const GetSheet = ({data, sheetIndex}) => {
    //Read the Excel File data in binary
    var workbook = XLSX.read(data, {
      type: 'binary', cellDates: true,
  });

  //get the name of First Sheet.
  var Sheet = workbook.SheetNames[sheetIndex];

  return XLSX.utils.sheet_to_json(workbook.Sheets[Sheet], { header: 1});
}

const GetActivitiesFromSpreadSheet = ({data}) => {

  var excelRows = GetSheet({data, sheetIndex: 0});

  const result = {};

  if (excelRows.length < 13) return result;

  result.PAC_diario = excelRows[13][3];
  result.activities = [];

  var currentCategory = "";
  for (let rowIndex = 21; rowIndex < excelRows.length; rowIndex++) {
    if (!excelRows[rowIndex][2]) continue; // empty row
    if (!excelRows[rowIndex][0]) { // category
      currentCategory = excelRows[rowIndex][2];
      // if (!result.activities[currentCategory]) {
      //   result.activities[currentCategory] = [];
      // }
      continue;
    }
    if (currentCategory === "") continue;
    result.activities.push({
      category: currentCategory,
      name: excelRows[rowIndex][2],
      zone: excelRows[rowIndex][3],
      elementIdList: `${excelRows[rowIndex][4]}`.split(","),
      assignedTo: excelRows[rowIndex][6],
      ammount: excelRows[rowIndex][9],
      ammount_unit: excelRows[rowIndex][8],
      percent_promissed: excelRows[rowIndex][10],
      percent_reached: excelRows[rowIndex][11],
      end_date: FormatDate(excelRows[rowIndex][22]),
    });
  }

  return result;
};

const GetRestrictionsFromSpreadSheet = ({data}) => {

  var excelRows = GetSheet({data, sheetIndex: 0});

  const result = {};

  result.restrictions = [];

  var currentActivity = "";
  var currentZone = "";
  for (let rowIndex = 8; rowIndex < excelRows.length; rowIndex++) {
    if (!excelRows[rowIndex][1]) continue; // empty row
    currentActivity = excelRows[rowIndex][4];
    // if(!result.restrictions[currentActivity]) {
    //   result.restrictions[currentActivity] = {};
    // }
    currentZone = excelRows[rowIndex][3];
    // if(!result.restrictions[currentActivity][currentZone]) {
    //   result.restrictions[currentActivity][currentZone] = [];
    // }
    result.restrictions.push({
      state: excelRows[rowIndex][1],
      activity: currentActivity,
      zone: currentZone,
      name: excelRows[rowIndex][6],
      assignedTo: excelRows[rowIndex][7],
      releaseDate: FormatDate(excelRows[rowIndex][8]),
      newDate: FormatDate(excelRows[rowIndex][9]),
      comment: excelRows[rowIndex][11],
    });
  }

  return result;
};

const GetIntegrationFromSpreadSheet = ({data}) => {

  var excelRows = GetSheet({data, sheetIndex: 0});

  const result = {};

  result.integration = [];

  var currentActivity = ""
  var currentZone = "";
  for (let rowIndex = 8; rowIndex < excelRows.length; rowIndex++) {
    if (!excelRows[rowIndex][1]) continue; // empty row
    currentZone = excelRows[rowIndex][2];
    // if(!result.integration[currentZone]) {
    //   result.integration[currentZone] = {};
    // }
    currentActivity = excelRows[rowIndex][3];
    // if(!result.integration[currentZone][currentActivity]) {
    //   result.integration[currentZone][currentActivity] = []
    // }
    // DIRECTRICES DE LA TAREA	
    result.integration.push({
      name: "DIRECTRICES DE LA TAREA",
      activity: currentActivity,
      zone: currentZone,
      comment: excelRows[rowIndex][CHAR2INT.F],
      fileList: excelRows[rowIndex][CHAR2INT.G].split(","),
    })

    // DETALLES CONSTRUCTIVOS
    result.integration.push({
      name: "DETALLES CONSTRUCTIVOS",
      activity: currentActivity,
      zone: currentZone,
      comment: "",
      fileList: excelRows[rowIndex][CHAR2INT.H].split(","),
    })
    
    // INFORMACIÓN COMPLEMENTARIA
    result.integration.push({
      name: "INFORMACIÓN COMPLEMENTARIA",
      activity: currentActivity,
      zone: currentZone,
      comment: "",
      fileList: excelRows[rowIndex][CHAR2INT.I].split(","),
    })

    // REQUERIMIENTOS DE SEGURIDAD	
    result.integration.push({
      name: "REQUERIMIENTOS DE SEGURIDAD",
      activity: currentActivity,
      zone: currentZone,
      comment: excelRows[rowIndex][CHAR2INT.J],
      fileList: excelRows[rowIndex][CHAR2INT.K].split(","),
    })

    // REQUERIMIENTOS DE CALIDAD	
    result.integration.push({
      name: "REQUERIMIENTOS DE CALIDAD",
      activity: currentActivity,
      zone: currentZone,
      comment: excelRows[rowIndex][CHAR2INT.L],
      fileList: excelRows[rowIndex][CHAR2INT.M].split(","),
    })

    // ANTECEDENTES DEL PROYECTO
    result.integration.push({
      name: "ANTECEDENTES DEL PROYECTO",
      activity: currentActivity,
      zone: currentZone,
      comment: "",
      fileList: excelRows[rowIndex][CHAR2INT.N].split(","),
    })

  }

  return result;
};