import React, { Component } from "react";
import { connect } from "react-redux";
import { getProjectAnnotations, setMarkerModal } from "../../actions";
import { addAnnotation, addAnnotationStandar, getOrgMarkers } from "../../actions";
import "./styles/Modal.css"
import "./styles/NewMeetingCategoryModal.css"
import "./styles/NewMarkerModal.css"
import { ReactComponent as DownArrow } from "../svg/icono_flecha_abajo.svg";
// import { ReactComponent as GroupedMar    kers } from "../svg/grupo_anotaciones_blue.svg";
import { ReactComponent as Marker } from "../svg/icono_anotaciones_2.svg";

const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalMarker,
        users: state.UserReducer.users,
        currentUser: state.UserReducer.currentUser,
        selectedProject: state.ProjectReducer.selectedProject,
        orgMarkers: state.OrganizationReducer.orgMarkers,
        };
}

const initialState =  {
    name: '',
    color: '',
    tipo: '',
    disabled: false,
};

class NewMarkerModal extends Component{
    
    constructor(props){
        super(props);
        this.state = initialState;
        this.notice = "";
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleShowColors = this.handleShowColors.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentDidMount(){
        this.setState(initialState);
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        var newState = this.state;
        newState[event.target.name] = event.target.value
        this.setState(newState);
    }

    resetState(){
        this.setState(initialState);
    }

    goToProject() {
        this.setState(initialState);
        if (this.props.selectedProject) {
            this.props.dispatch(getProjectAnnotations(this.props.selectedProject.id));
        }

        this.props.dispatch(getProjectAnnotations(this.props.selectedProject.id));
        //if (this.props.selectedProject && this.props.history) {
        //    this.props.history.push(`/projects/${this.props.selectedProject.id}`); 
        //}
        this.props.dispatch(this.closeModal);
    }

    goToList() {
        this.setState(initialState);
        if (this.props.orgMarkers) {
            this.props.dispatch(getOrgMarkers()).catch(error=>{
                console.log(error);
            });
        }

        this.props.dispatch(getOrgMarkers()).catch(error=>{
            console.log(error);
        });
        //if (this.props.selectedProject && this.props.history) {
        //    this.props.history.push(`/projects/${this.props.selectedProject.id}`); 
        //}
        this.props.dispatch(this.closeModal);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({ disabled: true });
        const annotationData = {}
        const fields = ['name', 'color']
        fields.forEach(field=>{
            if (this.state[field] !== undefined || this.state[field] !== null || this.state[field] !== '') {
                annotationData[field] =  this.state[field];
            }
        });

        const onSuccess = ()=>{
            this.closeModal();
        };
        if (this.props.boolFromOrg) {
            this.props
                .dispatch(addAnnotationStandar(annotationData))
                .then(() => {
                    this.setState(initialState);
                    this.goToList();
                    onSuccess();
                })
                .catch((error) => {
                    if (error.message.includes("A standard AnnotationType with name") && error.message.includes("already exists in this organization")) {
                    this.notice = "Ya existe una anotación con dicho nombre";
                    } else if (error.message.includes("A valid non-empty color value is required")) {
                    this.notice = "Debe elegir un color";
                    } else if (error.message.includes("A valid non-empty name value is required")) {
                    this.notice = "Debe elegir un nombre";
                    }
                    this.setState({ disabled: false });
                });
        } else {
            this.props
                .dispatch(addAnnotation(this.props.selectedProject.id, annotationData))
                .then(() => {
                    this.setState(initialState);
                    this.goToProject();
                    onSuccess();
                })
                .catch((error) => {
                    if (error.message.includes("An AnnotationType with name") && error.message.includes("is already associated with this project")) {
                    this.notice = "Ya existe una anotación con dicho nombre";
                    } else if (error.message.includes("A valid non-empty color value is required")) {
                    this.notice = "Debe elegir un color";
                    } else if (error.message.includes("A valid non-empty name value is required")) {
                    this.notice = "Debe elegir un nombre";
                    }
                    this.setState({ disabled: false });
                });
        }
    }

    closeModal(){
        const buttonColor = document.querySelector(".body-new-marker-modal");
        buttonColor.classList.remove("active");
        const colorInitial = document.querySelector(".color-initial");
        colorInitial.setAttribute("class", "color-initial");
        this.notice = "";
        this.setState(initialState);
        this.props.dispatch(setMarkerModal(false)).then(this.resetState);
    }

    handleShowColors = (event) => {
        const buttonColor = document.querySelector(".body-new-marker-modal");
        buttonColor.classList.toggle("active");
    }

    handleChildsColors = (e) => {
        if (this.state.disabled) {
            return;
        }
        this.setState({
            color: e.target.id,
        });

        const colorInitial = document.querySelector(".color-initial");
        colorInitial.setAttribute("class", "color-initial");
        if (e.target.id) {
            colorInitial.classList.toggle(e.target.id);   
        }
    }



    render(){
    var modalState = 'modal-overlay-markers ';
    modalState += this.props.isVisible? 'modal-open':'modal-closed';
    
    return (
        <div className={modalState}>
                 <div className="marker-modal">
                    <button id="closing-x-category" onClick={this.closeModal}> X </button>
                            <div className="modal-selectedProject">
                            <form onSubmit={this.handleSubmit}>
                                <div className='modal-marker-title'>
                                    <div className="marker-left-content">
                                    <div className="iconMarkerModal">
                                        <Marker/>
                                    </div>
                                    <input 
                                    className="name-marker-field" 
                                    id="name" 
                                    name="name" 
                                    type="text" 
                                    value={this.state.name} 
                                    placeholder="Nombre de la Anotación" 
                                    onChange={this.handleChange}/>
                                    </div>
                                    <div className="notice-div-marker">{this.notice}</div>
                                    <div className="marker-right-content">
                                        <div className="colors-wrapper" onClick={this.handleShowColors}>
                                            <div className="header-new-marker-modal">
                                                <div className="color-initial"></div>
                                                <div className="flecha-abajo">
                                                    < DownArrow />
                                                </div>
                                            </div>
                                            <div className="body-new-marker-modal" onClick={this.handleChildsColors}>
                                                <div className="color-option-new-marker-modal yellow" id="yellow">
                                                </div>
                                                <div className="color-option-new-marker-modal purple" id="purple">
                                                </div>
                                                <div className="color-option-new-marker-modal lightblue" id="lightblue">
                                                </div>
                                                <div className="color-option-new-marker-modal red" id="red">
                                                </div>
                                                <div className="color-option-new-marker-modal lightgreen" id="lightgreen">
                                                </div>
                                                <div className="color-option-new-marker-modal orange" id="orange">
                                                </div>
                                                <div className="color-option-new-marker-modal pink" id="pink">
                                                </div>
                                                <div className="color-option-new-marker-modal darkgreen" id="darkgreen">
                                                </div>
                                                <div className="color-option-new-marker-modal darksalmon" id="darksalmon">
                                                </div>
                                                <div className="color-option-new-marker-modal lightslategrey" id="lightslategrey">
                                                </div>
                                        
                                            </div>
                                        </div>
                                    {/*<select onChange={this.handleColors} name="color">
                                        <option value="" selected disabled hidden>Color</option>
                                        <option className="pink" value="pink">pink</option>
                                        <option className="red" value="red">red</option>
                                        <option className="yellow" value="yellow">yellow</option>
                                        <option className="green" value="green">green</option>
                                    </select>*/}
                                    </div>
                                </div>
                                <div>
                                </div>  
                                <div className="modal-marker-avisar-2">
                                    {/* De forma opcional tu anotación puede pertenecer a un grupo o a varios, selecciona a continuación a que grupo quieres asociar su anotación y luego dar "click"
                                    al botón con el ícono de la carpeta. */}
                                    {/* <div className="selectGroup">
                                        <div className="select_box">
                                            <select className="dropdown-marker"  name='marker-type'  >
                                                className="dropdown-marker"  onChange={this.handleOptions}
                                                <option>Seleccionar Grupo</option>
                                                <option value="meeting-type1">Especialidades</option>
                                                <option value="meeting-type2">Requerimientos</option>
                                                <option value="meeting-type3">Equipos</option>
                                            </select>
                                        </div>
                                        <button className="buttonMarker">
                                            <GroupedMarkers/>
                                            <img src="../svg/grupo_anotaciones_blue.svg"/>
                                        </button>
                                    </div> */}
                                 </div>
                                
                                <div className="button-div-4"> 
                                    <input className="crear-marker-button" type="submit" value="CREAR ANOTACIÓN" disabled={this.state.disabled}/>
                                </div>
                            </form> 

                    </div>
                </div>
            </div>
        );
    }
}
/*
<select className="login-field" id="MantainerId" name="MantainerId" placeholder={<div>Type to search</div>} value={this.state.MantainerId} onChange={this.handleChange}>
    <option value={mantainerVal} defaultValue disabled hidden>
        { mantainerLabel }
    </option>
    {this.props.users.map(user=>(
        <option value={user.id} key={user.id}>
            {user.username}
        </option>
    ))}
</select>
*/

const connectedNewMarkerModal  = connect(mapStateToProps)(NewMarkerModal );

export { connectedNewMarkerModal  as NewMarkerModal  };