import React, { Component } from "react";
import { connect } from "react-redux";
import { setDesregisterModal, setDeleteOrgModal } from "../actions";
import { getOrganization } from "../actions";
import { Links } from "../components/Links";
import { Button, MyInfo, UpdateInfoForm, MyOrg, UpdateOrgInfoForm } from "../components";
import { DesregisterConfirmModalModal, DeleteOrgModal } from "../components";
import {
    isBrowser,
  } from "react-device-detect";
import "./styles/MyPage.css";

const initialState = {
    update_user: false,
    org_profile: false,
    profile: true,
    update_org: false,
}

let onClick = () => {
    alert("Seleccione una organización para acceder a este contenido!");
};

const mapStateToProps = (state) => {
    return {
      orgUser: state.UserReducer.orgUser,
      currentUser: state.UserReducer.currentUser,
    };
  };

class MyPage extends Component{
    constructor(props){
        super(props);
        this.state = initialState;

        this.renderContent = this.renderContent.bind(this);

        this.setProfileTrue = this.setProfileTrue.bind(this);
        this.toggleUpdate = this.toggleUpdate.bind(this);
        this.setUpdateFalse = this.setUpdateFalse.bind(this);

        this.setOrgProfileTrue = this.setOrgProfileTrue.bind(this);
        this.toggleUpdateOrg = this.toggleUpdateOrg.bind(this);
        this.setUpdateOrgFalse = this.setUpdateOrgFalse.bind(this);
        
        this.setDesregisterModal = this.setDesregisterModal.bind(this);
        this.setDeleteOrgModal = this.setDeleteOrgModal.bind(this);
    }

    renderContent() {
        if ( this.state.update_org ) {
            return (<UpdateOrgInfoForm onSuccess={this.setUpdateOrgFalse}/>);
        }
        else if (this.state.update_user) {
            return(<UpdateInfoForm onSuccess={this.setUpdateFalse} currentUser={this.props.currentUser}/>);
        }
        else if ( this.state.org_profile )
        {
            return(<MyOrg/>);
        }
        else if (this.state.profile) {
            return(<MyInfo/>);
        }
    }

    setProfileTrue() {
        this.setState({
            org_profile: false, 
            profile: true, 
            update_user: false, 
            update_org: false,
        });
    }

    setOrgProfileTrue() {
        this.props.dispatch(getOrganization()).catch(error=>{
            console.log(error);
        });
        this.setState({
            org_profile: true, 
            profile: false, 
            update_user: false, 
            update_org: false
        });
    }

    setUpdateFalse(){
        this.setState({ update_user: false });
    }

    setUpdateOrgFalse(){
        this.setState({ update_org: false });
    }

    toggleUpdate(){
        this.setState({ update_user: !this.state.update_user });
    }

    toggleUpdateOrg(){
        this.setState({ update_org: !this.state.update_org });
    }

    setDesregisterModal(){
        this.props.dispatch(setDesregisterModal(true));
    }

    setDeleteOrgModal(){
        this.props.dispatch(setDeleteOrgModal(true));
    }

    render(){
        let links;
        if (this.props.orgUser) {
            links = [     
                {
                  link: "/home",
                  name: "Inicio",
                },       
                {
                  link: "#",
                  name: "Perfil",
                },
              ];
        }
        else {
            links = [            
                {
                  link: "#",
                  name: "Perfil",
                },
              ];
        }
        
        return(
            isBrowser ?
                <div className='main-content' selected='Settings'>
                    <Links links={links} />
                    <div className="page-content">
                        { this.state.org_profile? 
                        <div className="left-nav">
                            {/*Perfil de organización por confirmar*/}
                            <div className="left-nav-item">
                                <Button
                                    className="remove-background"
                                    onClick={this.setProfileTrue}
                                >Perfil de Usuario</Button>
                            </div>
                            {this.props.orgUser ? 
                                <div className="selected-left-nav-item">
                                    <Button
                                        className="remove-background"
                                        onClick={this.setOrgProfileTrue}
                                    >Sobre la organización</Button>
                                </div>
                                :
                                <div className="selected-left-nav-item">
                                    <Button
                                        className="remove-background"
                                        onClick={onClick}
                                    >Sobre la organización</Button>
                                </div>
                            }
                        </div> : 
                        <div className="left-nav">
                            <div className="selected-left-nav-item">
                                <Button
                                    className="remove-background"
                                    onClick={this.setProfileTrue}
                                >Perfil de Usuario</Button>
                            </div>
                            {this.props.orgUser ? 
                                <div className="left-nav-item">
                                    <Button
                                        className="remove-background"
                                        onClick={this.setOrgProfileTrue}
                                    >Sobre la organización</Button>
                                </div>
                                :
                                <div className="left-nav-item">
                                    <Button
                                        className="remove-background"
                                        onClick={onClick}
                                    >Sobre la organización</Button>
                                </div>
                            }
                        </div>
                        }
                    <div className="right-content">

                        <div className="edit-form-field">
                            {this.renderContent()}
                        </div>
                        <div className='bottom-buttons'>
                            { this.state.profile ? 
                            <>
                                <Button className="purple-button" onClick={this.setDesregisterModal}>
                                    Desregistrarme
                                </Button>
                                <Button className="purple-button" onClick={this.toggleUpdate}>
                                        {this.state.update_user ? 'Volver' : 'Editar'}
                                </Button>
                            </> 
                            :
                            <>
                                {this.props.orgUser.accountType === 1 ? 
                                    <>
                                        <Button className="purple-button" onClick={this.setDeleteOrgModal}>
                                            Eliminar
                                        </Button><Button className="purple-button" onClick={this.toggleUpdateOrg}>
                                                {this.state.update_org ? 'Volver' : 'Editar'}
                                        </Button>
                                    </>    
                                    :
                                    <></>
                            } 
                            </> 
                            }
                        </div>
                        <DesregisterConfirmModalModal history={this.props.history}/>
                        <DeleteOrgModal history={this.props.history}/>
                    </div>
                </div>                
            </div>
            :
            <div className='main-content' selected='Settings'>
                <div class="page-content">
                    { this.state.org_profile? 
                        <div class="left-nav">
                            <div class="left-nav-item">
                                <Button
                                    className="remove-background"
                                    onClick={this.setProfileTrue}
                                >Perfil de Usuario</Button>
                            </div>
                            {this.props.orgUser ? 
                                <div class="selected-left-nav-item">
                                    <Button
                                        className="remove-background"
                                        onClick={this.setOrgProfileTrue}
                                    >Sobre la organización</Button>
                                </div>
                                :
                                <div class="selected-left-nav-item">
                                    <Button
                                        className="remove-background"
                                        onClick={onClick}
                                    >Sobre la organización</Button>
                                </div>
                            }
                        </div> : 
                        <div class="left-nav">
                            <div class="selected-left-nav-item">
                                <Button
                                    className="remove-background"
                                    onClick={this.setProfileTrue}
                                >Perfil de Usuario</Button>
                            </div>
                            {this.props.orgUser ? 
                                <div class="left-nav-item">
                                    <Button
                                        className="remove-background"
                                        onClick={this.setOrgProfileTrue}
                                    >Sobre la organización</Button>
                                </div>
                                :
                                <div class="left-nav-item">
                                    <Button
                                        className="remove-background"
                                        onClick={onClick}
                                    >Sobre la organización</Button>
                                </div>
                            }
                        </div>
                        }
                        
                    <div class="right-content">
                        <div class="edit-form-field">
                            {this.renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ConnectedMyPage = connect(mapStateToProps)(MyPage);

export {ConnectedMyPage as MyPage};
