import React, { Component } from "react";
import { withRouter } from "react-router";

import { SignupForm } from "../components";

import { ROUTES } from "../constants";
import {ReactComponent as RedicoLogo } from '../components/Icons/logo_redico.svg';
import {ReactComponent as LoginLogo } from '../components/Icons/logo_login.svg';


import "./styles/SignUp.css";

class SignUp extends Component{

    goTo(routeName){
        this.props.history.push(routeName);
    }

    render(){
        return(
            <div className='grid'>
                <div className='first-mid-row'>
                    <LoginLogo className='login-logo'/>
                </div>
                <div className='mid-grid'>
                    <SignupForm onSuccess={()=>this.goTo({
                            pathname: ROUTES.SIGN_IN, 
                            state: { notice: "Se le ha enviado un mail para que verifique su cuenta" }
                        })} /> 
                </div>
                <div className='third-third-row'>
                    <RedicoLogo className='redico-logo'/>
                </div>
            </div>
        )
    }
}

const SginUpWithRouter = withRouter(SignUp);

export {SginUpWithRouter as SignUp};