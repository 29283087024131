import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteProject, setProjectModal } from "../../actions"
import { Button } from "../Button";
import { ListWidgetItem } from "..";
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import "./styles/Modal.css"
import { LIST_WIDGET_CONTENT_TYPE } from "../../constants/ListWidgetContentType";

const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalDeleteUser,
        users: state.UserReducer.users,
        currentUser: state.UserReducer.currentUser,
        project: state.ProjectReducer.selectedProject,
        projects: state.ProjectReducer.projects
    };
}

const initialState = {
    disabled: false
};

class DeleteConfirmModalProject extends Component{

    constructor(props){
        super(props);
        this.state = initialState;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
    }

    goToProjects () {
        this.setState(initialState);
        this.props.history.push(`/projects`);
        this.props.dispatch(this.closeModal);
    }

    resetState(){
        this.setState(initialState);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled:true});
        if (this.props.project) {
            this.props.dispatch(deleteProject(this.props.project.id)).then(()=>{
                this.closeModal();
            }).catch(()=>{
                this.setState({...this.state, disabled:false});
            })
            this.setState({...this.state, disabled:false});
            this.goToProjects();
            return;
        }
    }

    closeModal(){
        // this.resetState();
        // this.props.dispatch(selectUser(-1));
        this.props.dispatch(setProjectModal(false)).then(this.resetState);
    }

    render(){
        var modalState = 'modal ';
        modalState += this.props.isVisible? 'modal-open':'modal-closed';
        return(
            <div className={modalState}>
                 <div className="delete-modal">
                        <Button className="modal-button" onClick={this.closeModal}>
                                    <Close/>
                                </Button>
                            <div className="modal-selectedProject">
                            <form onSubmit={this.handleSubmit}>
                                <div className='modal-2-title'>
                                    <div className="modal-avisar">
                                            Está seguro que quiere eliminar:
                                    </div>
                                </div>
                                <div>
                                {this.props.project ? (
                                    <ListWidgetItem 
                                        type={LIST_WIDGET_CONTENT_TYPE.PROJECTS}
                                        key={1}
                                        itemId={this.props.project.id}
                                        itemName={this.props.project.name}
                                        subText={this.props.projects[0]? this.props.projects[0].OrganizacionId : ("No pertenece a una organización")}
                                        creationDate={this.props.project.creationDate?(this.props.project.creationDate.substring(0,10)):("No Date")}
                                        description={this.props.project.description}
                                        eliminando={true}
                                    /> 
                                ) : (
                                    ""
                                )}
                                </div>  
                                <div className="modal-avisar-2">
                                    Este procesos actualizará tu lista de proyectos y no tiene vuelta atras.
                                </div>
                                
                                <div className="button-div-4"> 
                                    <input className="delete-proyecto-button" type="submit" value="ELIMINAR PROYECTO" disabled={this.state.disabled}/>
                                </div>
                            </form> 

                    </div>
                </div>
            </div>
        )
    }
}

const connectedDeleteConfirmModalProject = connect(mapStateToProps)(DeleteConfirmModalProject);

export { connectedDeleteConfirmModalProject as DeleteConfirmModalProject };