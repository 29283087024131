/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { EditListWidget } from '../../components/EditListWidget';
import { addMeeting, deselectMeeting, NewMeetingGuest, NewMeetingNote, NewMeetingView, ResetMeetingFormAction, setAddMeetingCategoryModal, setMeetingCategory, setMeetingModal, updateMeeting } from "../../actions"
import { IS_INT } from '../../constants';
import { TextField } from "../TextField";
import "./styles/NewMeetingModal.css"
import { ReactComponent as MeetingDetails } from "../../routes/svg/icono_reunión_virtual.svg";
import { DropdownMenu } from '../DropdownMenu';
import { ValueIsNullOrEmptySpace } from '../../helpers/FormatHelper';
import { LIST_WIDGET_CONTENT_TYPE } from '../../constants/ListWidgetContentType';
import { MarkersContentType } from '../ListWidget/contentType/MarkersContentType';
import { ViewsContentType } from '../ListWidget/contentType/ViewsContentType';
import { useDispatch, useSelector } from 'react-redux';

const initialState =  {
    topic: '',
    date: '',
    isPublic: false,
    disabled: false,
};

export const NewMeetingModal = () => {
  const dispatch = useDispatch();

  // store references
  const isVisible = useSelector((state) => state.ModalReducer.modalMeeting);
  const selectedProject = useSelector((state) => state.ProjectReducer.selectedProject);
  const selectedMeetingGuests = useSelector((state) => state.MeetingReducer.newGuests);
  const selectedMeetingViews = useSelector((state) => state.MeetingReducer.newViews);
  const selectedMeetingNotes = useSelector((state) => state.MeetingReducer.newNotes);
  const selectedCategoryId = useSelector((state) => state.MeetingReducer.selectedCategoryId);

  const selectedMeeting =  useSelector((state) => state.MeetingReducer.selectedMeeting);
  
  // state
  const [
    { 
      topic, 
      date, 
      isPublic, 
      disabled 
    },
    setState
  ] = useState(initialState);

  useEffect(function onPopUp(){
    if (!selectedMeeting) {
      setState({ ...initialState });
      dispatch(ResetMeetingFormAction());
      return;
    }

    setState((prevState) => ({
      ...prevState,
      topic: selectedMeeting.topic,
      date: selectedMeeting.date.substring(0, "2022-04-28T22:45".length),
      isPublic: selectedMeeting.public,
    }));
    dispatch(setMeetingCategory(selectedMeeting.MeetingCategoryId))

    const guests = selectedMeeting.Guests;
    const guestIds = guests.map(item => item.id );
    dispatch(NewMeetingGuest(guestIds, guests));
    const VisualReferences = selectedMeeting.VisualReferences;
    const VisualReferencesIds = guests.map(item => item.id );
    dispatch(NewMeetingView(VisualReferencesIds, VisualReferences));
    const AnnotationTypes = selectedMeeting.AnnotationTypes;
    const AnnotationTypesIds = guests.map(item => item.id );
    dispatch(NewMeetingNote(AnnotationTypesIds, AnnotationTypes));
  }, [selectedMeeting]);

  const handleChange = (event) => {
    if (disabled) return;
    setState((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }

  const closeModal = () => {
    dispatch(setMeetingModal(false)).then(setState({...initialState}));
    dispatch(ResetMeetingFormAction());
    dispatch(deselectMeeting());
  }

  const addMeetingCategory = () => {
    dispatch(setAddMeetingCategoryModal(true, "newMeeting"));
  }

  const GUESTS = {
    type: "GUESTS",
    content: [],
  };
  if (selectedMeetingGuests) {
    selectedMeetingGuests.forEach((guest) => {
      const dateObj = new Date(guest.createdAt);
      let creationDate = (dateObj.getFullYear() + "-" + (dateObj.getMonth() + 1)+ "-" + (dateObj.getDate()));
      GUESTS.content.push({
        id: guest.id,
        itemName: guest.User.username,
        creationDate,
        description: guest.User.email,
      });
    });
  }

  const VIEW_3D = {
    type: LIST_WIDGET_CONTENT_TYPE.VIEW_3D,
    content: [],
    contentType: ViewsContentType,
  };
  if (selectedMeetingViews) {
    VIEW_3D.content = selectedMeetingViews;
  }

  const MARKERS = {
    type: LIST_WIDGET_CONTENT_TYPE.MARKERS,
    content: [],
    contentType: MarkersContentType,
  };
  if(selectedMeetingNotes){
    MARKERS.content = selectedMeetingNotes;
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (disabled) return;
    setState((prevState) => ({ ...prevState, disabled: true }));

    const meetingData = {
      topic: IS_INT.test(topic) ? parseInt(topic) : topic,
      date: IS_INT.test(date) ? parseInt(date) : date,
      public: IS_INT.test(isPublic) ? parseInt(isPublic) : isPublic,
    }
    const fields = {topic, date, isPublic}
    Object.keys(fields).forEach(fieldName=>{
        if (!ValueIsNullOrEmptySpace(fields[fieldName])) {
          meetingData[fieldName] = IS_INT.test(fields[fieldName]) ? parseInt(fields[fieldName]) : fields[fieldName];
        }
    })
    meetingData.meetingCategoryId = parseInt(selectedCategoryId);

    var annotationTypeIdArray = [];
    selectedMeetingNotes && selectedMeetingNotes.forEach(annotationType => {
      annotationTypeIdArray.push(parseInt(annotationType.id));
    });
    meetingData.annotationTypes = annotationTypeIdArray;

    var visualReferenceIdArray = [];
    selectedMeetingViews && selectedMeetingViews.forEach(visualReference => {
      visualReferenceIdArray.push(visualReference.id);
    });
    meetingData.visualReferences = visualReferenceIdArray;

    var guestIdArray = [];
    selectedMeetingGuests && selectedMeetingGuests.forEach(guest => {
      guestIdArray.push(guest.id);
    });
    meetingData.guests = guestIdArray;

    const onSuccess = () => {
      dispatch(ResetMeetingFormAction());
      closeModal();
    };

    const onFailure = () => {
      setState((prevState) => ({ ...prevState, disabled: false }));
    };

    if (selectedMeeting) {
      dispatch(updateMeeting(selectedMeeting.ProjectId, selectedMeeting.id, meetingData)).then(onSuccess).catch(onFailure);
      return;
    }

    dispatch(addMeeting(selectedProject.id, meetingData)).then(onSuccess).catch(onFailure);
  }


  return (
    <div className={`modal-overlay-categories ${ isVisible ? 'modal-open' : 'modal-closed' }`}>
      <div className="">
        <div className="">
          <div className="" style={{"display": "flex", "flexDirection": "column"}}>
            <button id="closing-x" onClick={closeModal}> X </button>
            <div className="project-header-2">
              <div className="project-logo"></div>
              <div className="project-data">
                <div className="project-name">
                  { selectedProject ? selectedProject.name : "No name" }
                </div>
                <div className="project-context">
                  <span> Creada el: <b> { selectedProject && selectedProject.createdAt ? selectedProject.createdAt.substring(0,10) : "No Date" } </b> </span>
                        &nbsp;
                        <span> Nombre del Proyecto: <b> { selectedProject ? selectedProject.name : "No Admin" } </b> {" "} </span>
                    </div>
                    <div className="project-description">
                        { selectedProject ? selectedProject.description : "No description" }
                    </div>
                </div>
            </div>
            <div className="">
                <form className="meeting-form" onSubmit={e => e.preventDefault()}>
                  <div className="new-meeting-details">
                    <MeetingDetails />
                    <TextField className="name-field" fieldName="topic" type="text" value={topic} placeholder="Nombre Reunión" onChange={handleChange} />
                    <TextField className="date-field" fieldName="date" type="datetime-local" value={date} step={60 * 15} placeholder="DD / MM / AA 00:00:00" onChange={handleChange} />
                    <DropdownMenu />
                    <button id="add-meeting-type" onClick={addMeetingCategory}>CREAR CATEGORÍA</button>
                  </div>
                  <div className="widget-container-2" id = 'job' selected="Projects">
                      <EditListWidget className="list-container" content={VIEW_3D} get={false} isMeeting={true}/>
                      <EditListWidget className="list-container" content={MARKERS} get={false} isMeeting={true} />
                      <EditListWidget className="list-container" content={GUESTS} get={false} isMeeting={true}/>
                  </div>
                <div className="button-div-3"> 
                    <input className="crear-proyecto-button" type="submit" value="AGENDAR REUNIÓN" disabled={disabled} onClick={handleSubmit}/>
                </div>
                </form> 
                </div>
              </div>
            </div>
          </div>
      </div>
  );
} 