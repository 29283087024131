import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProjectMaintainer, setDeleteProjectMaintainer } from "../../actions"
import { Button } from "../Button";
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import "./styles/Modal.css"

export const DeleteProjectMaintainerModal = ({
    itemId,
    description,
}) => {

    const dispatch = useDispatch();

    const isVisible = useSelector((state) => (state.ModalReducer.modalDeleteProjectMaintainer));
    const selectedProject = useSelector((state) => (state.ProjectReducer.selectedProject));
    const selectedMaintainer = useSelector((state) => (state.ModalReducer.modalProjectMaintainerid));

    const [disabled, setDisabled] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (disabled) {
            return;
        }
        setDisabled(true);
        if (selectedProject) {
            dispatch(deleteProjectMaintainer(selectedProject.id, selectedMaintainer))
            .then(closeModal)
            .catch(()=>{
                setDisabled(false);
            });
            return;
        }
        setDisabled(false);
    };

    const closeModal = () => {
        dispatch(setDeleteProjectMaintainer(false, 0)).then(setDisabled(false));
    };

    const modalState = `modal modal-${isVisible && selectedMaintainer === itemId ? 'open':'closed'}`;

    return (
        <div className={modalState}>
            <div className="delete-modal">
                <div className="delete-modal-box">
                    <Button className="modal-button"  onClick={closeModal}>
                        <Close />
                    </Button>
                    <div id="MyModal" className="modal-content">
                        <div className="modal-warning">
                            Está seguro que desea <span>eliminar</span> al administrador <span>{selectedMaintainer? (description):("seleccionado")}</span> del proyecto. 
                        </div>
                        <div className='bottom-buttons'>
                            <Button className="red-button" onClick={closeModal}>
                                CANCELAR
                            </Button>
                            <Button className="purple-button" onClick={handleSubmit}>
                                ELIMINAR
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
