import { ANNOTATION_ACTIONS } from "../constants";

const initialState = {
  instanceInfo: null,
  instanceAudio: null,
};

const MarkerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ANNOTATION_ACTIONS.GET_INSTANCE_INFO:
        return {...state, instanceInfo: payload}
    case ANNOTATION_ACTIONS.GET_INSTANCE_AUDIO:
      return {...state, instanceAudio: payload}
    case ANNOTATION_ACTIONS.RESET_INSTANCE:
      return initialState;
    default:
      return state;
  }
};

export default MarkerReducer;
