import React, { useState } from "react";
import { PROJECT_SECTION_TABS as VIEW_MODES, ROUTES } from "../constants";

import { Button } from "./Button";

import { ReactComponent as Markers } from "../routes/svg/icono_anotaciones.svg";
import { ReactComponent as Guests } from "../routes/svg/icono_invitado.svg";
import { ReactComponent as Meetings } from "../routes/svg/icono_reuniones_virtuales.svg";
import { ReactComponent as ThreeDView } from "../routes/svg/icono_vistas_3D.svg";
import { ReactComponent as Arrow } from "../routes/svg/icono_flecha.svg";
import { ReactComponent as DocIcon } from "../routes/svg/icono_lecciones_aprendidas.svg";

import "./styles/ProjectSidePanel.css";

import { useHistory, useLocation } from "react-router-dom";

export const ProjectSidePanel = ({
  changeViewCallback,
}) => {

  const history = useHistory();
  const location = useLocation();

  const [viewMode, SetViewMode] = useState(VIEW_MODES.MEETINGS);

  const goTo = (routeName) => { 
    if (location.pathname === routeName) {
      return;
    }
    history.push(routeName);
  };

  const onClick = (newViewMode) => {
    if (newViewMode === viewMode) return;
    changeViewCallback(newViewMode);
    SetViewMode(newViewMode);
  };

  // if (isBrowser) 
  return (
    <div className="mini-side-panel">
      <Button
            className={`svg-container ${
              viewMode === VIEW_MODES.MEETINGS ? "selected" : ""
            }`}
            tooltip="Reuniones"
            onClick={() => onClick(VIEW_MODES.MEETINGS)}>
        <Meetings x=" 8.25rem" y="1.75rem" />
      </Button>
      <Button
            className={`svg-container ${
              viewMode === VIEW_MODES.VIEW_3D ? "selected" : ""
            }`}
            tooltip="Vistas 3D"
            onClick={() => onClick(VIEW_MODES.VIEW_3D)}
          >
        <ThreeDView x="1.25rem" y="1.75rem" />
      </Button>
      <Button
            className={`svg-container ${
              viewMode === VIEW_MODES.DOCUMENTS ? "selected" : ""
            }`}
            tooltip="Documents"
            onClick={() => onClick(VIEW_MODES.DOCUMENTS)}
          >
        <DocIcon x="1.25rem" y="1.75rem" />
      </Button>
      <Button
            className={`svg-container ${
              viewMode === VIEW_MODES.MARKERS ? "selected" : ""
            }`}
            tooltip="Anotaciones"
            onClick={() => onClick(VIEW_MODES.MARKERS)}
          >
        <Markers x="1.25rem" y="1.75rem" />
      </Button>
      <Button
            className={`svg-container ${
              viewMode === VIEW_MODES.MAINTAINERS ? "selected" : ""
            }`}
            tooltip="Admins"
            onClick={() => onClick(VIEW_MODES.MAINTAINERS)}
          >
        <Guests x="8.25rem" y="1.75rem" />
      </Button>
      <Button
            className="move-to-bottom"
            onClick={() => goTo(ROUTES.PROJECTS)}
          >
        <Arrow x="8.25rem" y="1.75rem" />
      </Button>
    </div>
  )
};
