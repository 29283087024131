import { React, useEffect, useState } from "react";
import "./styles/ListWidget.css";
import "./styles/List.css";
import { ListWidgetItem } from "../components";
import { useHistory } from "react-router-dom";
import { selectOrganization } from "../actions";
import store from "../store";

import { useDispatch } from 'react-redux';
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";

const goTo = (orgId, history) => {
  const body = {
    orgId: orgId,
  };
  store.dispatch(selectOrganization(body)).then(() => {
    history.push("./home");
  });
};

export const OrgList = ({ content }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState(content);
  // const [searchFilter, setSearchFilter] = useState("");

  let history = useHistory();

  useEffect(() => {
    if (content.length === 1) {
      const body = {
        orgId: content[0].id,
      };
      dispatch(selectOrganization(body)).then(() => { 
        history.push("./home"); 
      });
    } 
    setList(content);
  }, [content, dispatch, history]);

  return (
    <div className="list-container">
      <div className="org-list-header"> Selecciona una organización </div>
      <div className="list-content">
        {list.map((item, index) => (
          <ListWidgetItem
            type={LIST_WIDGET_CONTENT_TYPE.PROJECTS}
            key={index}
            itemName={item.name}
            subText="Administrador: Matias Cadile"
            description="70 miembros"
            onClick={goTo.bind(null, item.id, history)}
          />
        ))}
      </div>
    </div>
  );
};
