import { ACCESS_TYPE, MEETING_ACTIONS, PROJECT_ACTIONS } from "../constants";

const initialState = {
  projects: [],
  views:[],
  projectViews: [],
  selectedProject: null,
  selectedProjectMaintainers: [],
  projectMeetingCategories: [],
  meetings: [],
  annotations: [],
  selectedProjectMeeting: null,
  selectedProjectMeetingGuests: [],
  selectedProjectMeetingViews: [],
  selectedProjects: [],
  selectedProjectMeetingNotes: [],
  selectedProjectMeetingAnnotationInstances: [],
  accessTypeDisplayed: ACCESS_TYPE.NONE,
};

const ProjectReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PROJECT_ACTIONS.ADD_PROJECT:
      return { ...state, projects: [...state.projects, payload] };
    case PROJECT_ACTIONS.UPDATE_PROJECT:
      return {
        ...state,
        projects: [...state.projects, payload],
        project: payload,
        selectedProject: payload,
      };
    case PROJECT_ACTIONS.GET_VIEWS:
      return {
        ...state,
        views: payload,
        accessTypeDisplayed: initialState.accessTypeDisplayed,
      }
    case PROJECT_ACTIONS.SET_ACCESS_TYPE_DISPLAYED:
      return {
        ...state,
        accessTypeDisplayed: payload,
      };
    case PROJECT_ACTIONS.GET_PROJECTS:
    case PROJECT_ACTIONS.GET_PROJECTS_ASSIGNED:
      return { ...state, projects: payload };
    case PROJECT_ACTIONS.SELECT_PROJECT:
      return { 
        ...state, 
        selectedProject: state.projects.find(({id}) => id === payload )
      };
    case PROJECT_ACTIONS.GET_PROJECT:
      return { ...state, selectedProject: payload };
    case PROJECT_ACTIONS.GET_PROJECT_MAINTAINERS:
      let lista_desplegar = [];
      payload.maintainers.forEach((element, index) => {
        const dateObj = new Date(element["createdAt"]);
        let myDate =
          dateObj.getFullYear() +
          "-" +
          (dateObj.getMonth() + 1) +
          "-" +
          dateObj.getDate();
        lista_desplegar[index] = {
          itemName: "",
          id: element["id"],
          subText: element["company"],
          description: element["alias"],
          creationDate: myDate,
        };
      });
      return { ...state, selectedProjectMaintainers: lista_desplegar };
    case PROJECT_ACTIONS.ADD_PROJECT_MAINTAINER:
      let nuevos_maintainers = [];
      payload.forEach((maintainer) => {
        const dateObj = new Date(maintainer["createdAt"]);
        let myDate =
          dateObj.getFullYear() +
          "-" +
          (dateObj.getMonth() + 1) +
          "-" +
          dateObj.getDate();
        const element = {
          itemName: "",
          id: maintainer["UserId"],
          subText: maintainer["company"],
          description: maintainer["alias"],
          creationDate: myDate,
        };
        nuevos_maintainers.push(element);
      });
      return {
        ...state,
        selectedProjectMaintainers: [
          ...state.selectedProjectMaintainers,
          ...nuevos_maintainers,
        ],
      };
    case PROJECT_ACTIONS.GET_PROJECT_MEETINGS:
      return { ...state, meetings: payload };
    case MEETING_ACTIONS.ADD_MEETING:
      return { ...state, meetings: [...state.meetings, payload] };
    case PROJECT_ACTIONS.PUT_PROJECT_MEETING:
      return {
        ...state,
        meetings: state.meetings.map(item=>item.id===payload.id?payload:item),
      };
    case PROJECT_ACTIONS.DELETE_PROJECT:
      return { ...state, selectedProject: state.projects[payload] };
    case PROJECT_ACTIONS.DELETE_PROJECT_MAINTAINER:
      let lista_desplegar_delete = [];
      let index = 0;
      state.selectedProjectMaintainers.forEach((maintainer) => {
        if (maintainer.id !== payload) {
          lista_desplegar_delete[index] = maintainer;
        }
        index++;
      });
      return { ...state, selectedProjectMaintainers: lista_desplegar_delete };
    case PROJECT_ACTIONS.GET_PROJECT_MEETING_CATEGORIES:
      let nuevas_categories = [];
      payload.forEach((category) => {
        const dateObj = new Date(category["createdAt"]);
        let myDate =
          dateObj.getFullYear() +
          "-" +
          (dateObj.getMonth() + 1) +
          "-" +
          dateObj.getDate();
        const element = {
          itemName: "",
          id: category["id"],
          subText: "",
          description: category["name"],
          creationDate: myDate,
        };
        nuevas_categories.push(element);
      });
      return { ...state, projectMeetingCategories: nuevas_categories };
    case PROJECT_ACTIONS.ADD_PROJECT_MEETING_CATEGORY:
      const dateObj = new Date(payload["createdAt"]);
      let myDate =
        dateObj.getFullYear() +
        "-" +
        (dateObj.getMonth() + 1) +
        "-" +
        dateObj.getDate();
      let elemento = {
        itemName: "",
        id: payload["id"],
        subText: "",
        description: payload["name"],
        creationDate: myDate,
      };
      return {
        ...state,
        projectMeetingCategories: [...state.projectMeetingCategories, elemento],
      };
    case PROJECT_ACTIONS.ADD_PROJECT_IMPORT:
      return { ...state, selectedProjects: payload };
    case PROJECT_ACTIONS.GET_PROJECT_VIEWS:
      const newViewList = [];
      if (payload.length) {
        for (let index = 0; index < payload.length; index++) {
          const viewItem = payload[index];
          if (viewItem.View3DVersions && viewItem.View3DVersions.length) {
            for (let j = 0; j < viewItem.View3DVersions.length; j++) {
              const viewVersionItem = viewItem.View3DVersions[j];
              viewVersionItem.versionIndex = `Versión ${ viewVersionItem.versionIndex + 1 }`;
              viewVersionItem.fileName = viewItem.fileName;
              viewVersionItem.View3D = viewItem;
              newViewList.push(viewVersionItem);
            }
            viewItem.View3DVersions = [];
          }
        }
      }
      return {
        ...state,
        projectViews: newViewList,
        accessTypeDisplayed: initialState.accessTypeDisplayed,
      };
    case PROJECT_ACTIONS.DELETE_PROJECT_VIEW:
      return { 
        ...state, 
        projectViews: state.projectViews.map((item) => {
          if (payload.id !== item.id) return item;
          item.isValid = payload.isValid;
          return item;
        }), 
      };
    case PROJECT_ACTIONS.GET_PROJECT_ANNOTATIONS:
      return { ...state, annotations: payload };
    case PROJECT_ACTIONS.GET_PROJECT_MEETING:
      return {
        ...state,
        selectedProjectMeeting: payload,
        selectedProjectMeetingNotes: payload["AnnotationTypes"],
      };
    case PROJECT_ACTIONS.RESET_PROJECT_INFO:
      return {
        ...state,
        meetings: [],
        selectedProjectMaintainers: [],
        projectViews: [],
      };
    case PROJECT_ACTIONS.RESET_PROJECTS:
      return initialState;
    case PROJECT_ACTIONS.GET_PROJECT_MEETING_GUESTS:
      let nuevos_guests = [];
      payload.forEach((guest) => {
        const dateObj = new Date(guest["createdAt"]);
        let myDate =
          dateObj.getFullYear() +
          "-" +
          (dateObj.getMonth() + 1) +
          "-" +
          dateObj.getDate();
        const element = {
          itemName: guest["company"],
          id: guest["UserId"],
          subText: "",
          description: guest["alias"],
          creationDate: myDate,
        };
        nuevos_guests.push(element);
      });
      return { ...state, selectedProjectMeetingGuests: nuevos_guests };
    case PROJECT_ACTIONS.GET_PROJECT_MEETING_VIEWS:
      let views = [];
      payload["visualreferences"].forEach((element) => {
        views.push(element.View3D);
      });
      return { ...state, selectedProjectMeetingViews: views };
    case PROJECT_ACTIONS.RESET_MEETING:
      return {
        ...state,
        selectedProjectMeeting: null,
        selectedProjectMeetingViews: [],
        selectedProjectMeetingNotes: [],
        selectedProjectMeetingGuests: [],
      };
    case PROJECT_ACTIONS.GET_PROJECT_MEETING_ANOTATION_INSTANCES:
      let instances = [];
      payload.forEach((element) => {
        instances.push(element);
      });
      return { ...state, selectedProjectMeetingAnnotationInstances: instances };
    case PROJECT_ACTIONS.RESET_MARKER_INFO:
      return { ...state, projects: payload}
    default:
      return state;
  }
};

export default ProjectReducer;
