import { PRODUCTS_ACTIONS } from "../constants";

const initialState = {
  productList: []
}

export const ProductsReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case PRODUCTS_ACTIONS.GET_PRODUCTS:
      return {
        ...state,
        productList: payload,
      };
    default:
      return state;
  }
}