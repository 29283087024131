import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAttachedFileAction, uploadProjectAttachedFile } from "../../actions/AttachedFileActions";
import { FileType } from "../../constants/FileTypes";
import { ReadActivities, ReadIntegration, ReadRestrictions } from "../../helpers/SpreadSheetHelper";
import "./styles/ActResDataFormStyle.css"

export const ActResDataForm = ({
  onCancel,
  setActive,
  selectedItem,
}) => {

  const dispatch = useDispatch();
  
  const selectedProject = useSelector((state) => state.ProjectReducer.selectedProject);

  const [actResData, setActResData] = useState({});

  
  const handleActivityFileChange = (event) => {

    ReadActivities({
      spreadSheetFile: event.target.files[0]
    }).then((activities)=>{
      setActResData({...actResData, ...activities})
    })

  }

  const handleRestrictionsFileChange = (event) => {
    ReadRestrictions({
      spreadSheetFile: event.target.files[0]
    }).then((restrictions)=>{
      setActResData({...actResData, ...restrictions})
    })
  }
  
  const handleIntegrationFileChange = (event) => {
    ReadIntegration({
      spreadSheetFile: event.target.files[0]
    }).then((integration)=>{
      setActResData({...actResData, ...integration})
    })
  }

  const submitHandler = (event) => {
    event.preventDefault()
    if (!Object.keys(actResData).length === 0) return;

    const actResBlob = new Blob([JSON.stringify(actResData)], {type : 'application/json'});
    if(setActive) setActive(false);
    if (selectedItem && selectedItem.id) {
      dispatch(UpdateAttachedFileAction(selectedProject.id, selectedItem.id, actResBlob, {
      fileName: 'ActRes.json',
        fileType: FileType.ActRes,
      })).then(() => {
        if (onCancel) onCancel();
      }).catch(err => {
        console.log(err);
        if(setActive) setActive(true);
      });
      return;  
    }
    dispatch(uploadProjectAttachedFile(selectedProject.id, actResBlob, {
      fileName: 'ActRes.json',
      fileType: FileType.ActRes,
    })).then(() => {
      if (onCancel) onCancel();
    }).catch(err => {
      console.log(err);
      if(setActive) setActive(true);
    })
  }

  return (
    <form className="act-res-data-form" onSubmit={submitHandler}>
      <label>
        <div>
          Actividades:
        </div>
        <input className="file-upload"
               type="file"
               name="activities"
               id="activities"
               onChange={handleActivityFileChange}
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv"/>
      </label>

      <label>
        <div>
          Restricciones:
        </div>
        <input onChange={handleRestrictionsFileChange} type="file" name="restrictions" id="restrictions" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv"/>
      </label>

      <label>
        <div>
          Integración:
        </div>
        <input onChange={handleIntegrationFileChange} type="file" name="integration" id="integration" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv"/>
      </label>

      <div className="spacer">
        
      </div>

      <div className="submit-cancel-options">
        <input className="cancel-button" type="button" value="Cancelar" onClick={onCancel} />
        <input className="submit-button" type="submit" value="Subir" />
      </div>
    </form>
  )
};
