import React from "react";
import { connect } from "react-redux";
import {setMeetingCategory} from "../actions";

import "./styles/Button.css";

// *Pendiente: Generalizar este caso particular (Tipo)

const mapStateToProps = state => {
  return { 
      projectMeetingCategories: state.ProjectReducer.projectMeetingCategories, // lista de categorias de un proyecto
      selectedCategoryId: state.MeetingReducer.selectedCategoryId,
    };
}

const initialState =  {
  meetingType:'',
};

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange = (event) => {
    this.props.dispatch(setMeetingCategory(event.target.value));
  }
  
  renderOptions(){
    const {projectMeetingCategories} = this.props;
    if(projectMeetingCategories && projectMeetingCategories.length !== 0){
      return projectMeetingCategories.map(cat => (<option className="select-item" key={cat.id} value={cat.id}>{cat.description}</option>) )
    }
    else{
      return (<option className="select-item" value="No existen">No existen categorias</option>)
    }
  }
  
  render() {
    return (
      <select className="dropdown-menu" value={this.props.selectedCategoryId} name='meeting-type' onChange={this.handleChange}>
        <option className="select-item" key='-1' value='-1'> Seleccione Categoria de Reunión... </option>
        {this.renderOptions()}
      </select>
    )
  }
}
  
const connectedDropdownMenu = connect(mapStateToProps)(DropdownMenu);

export { connectedDropdownMenu as DropdownMenu };
