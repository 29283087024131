import { MemberList } from "./lists/MemberList";
import { ReactComponent as Guests } from "../../../routes/svg/icono_invitado.svg";
import { MembersActions } from "./actions/MembersActions";

export const MemberContentType = {
  RenderList: MemberList,
  RenderTitle: () => "MIEMBROS DE LA ORGANIZACIÓN",
  RenderIcon: () => <Guests />,
  RenderActions: MembersActions,
};
