import React, { useEffect } from "react";
import { isBrowser, MobileView } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { getOrgMarkers } from "../actions";
import { ListWidget } from "../components";
import { ROUTES } from "../constants";
import { Button } from "../components/Button";
import { Links } from "../components/Links";
import { ReactComponent as IconMarkers } from './svg/icono_anotaciones.svg';
import { ReactComponent as Arrow } from "../routes/svg/icono_flecha.svg";
import "./styles/Redico.css";
import { useHistory, useLocation } from "react-router-dom";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";
import { OrgMarkersContentType } from "../components/ListWidget/contentType/OrgMarkersContentType";
  

export const MarkersRoute = () => {
  
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const annotationTypeList = useSelector((state) => state.OrganizationReducer.orgMarkers);

  useEffect(function getMarkers() {
    dispatch(getOrgMarkers());
  }, [dispatch]);

  const goTo = (routeName) => {
    if (location.pathname === routeName) return;
    history.push(routeName);
  };

  const links = [
    {
      link: '#',
      name: 'Anotaciones de la Organización',
    },
  ];

  const content = {
    type: LIST_WIDGET_CONTENT_TYPE.MARKERS,
    content: annotationTypeList,
    contentType: OrgMarkersContentType,
  };

  if (isBrowser) {
    return (
      <div className='main-content'>
        <Links links={ links }/>
        <ListWidget content={ content } />
      </div>
    );
  }

  return(
    <MobileView>
      <div className="mobile-title" style={{paddingTop: "1.5vh"}}>
        <IconMarkers />
        ANOTACIONES
      </div>
      <div className='main-content'>
        <ListWidget content={ content } />
        <div className="list-container" style={ { width: "45vh" } }>
          <Button className="move-to-bottom-mobile button-purple" onClick={() => goTo(ROUTES.LOGGED_HOME)}>
            <Arrow />
          </Button>
        </div>
      </div>
    </MobileView>
  )
};
