import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setConfigCategoryMeetingModal } from "../../../../actions";
import { MiniButton, ConfigCategoryMeetingModal, ImportTypesOfMeetingModal } from "../../..";
import { AddProjectMeetingCategoryModal } from "../../../modals/AddProjectMeetingCategoryModal";

export function MeetingSettings () {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleConfigMeetings = () => {
    dispatch(setConfigCategoryMeetingModal(true));
  };
  return (
    <div>
      <span className="left-line-white">
        <MiniButton type="config" onClick={()=> handleConfigMeetings()} />
      </span>
        <ConfigCategoryMeetingModal />
        <ImportTypesOfMeetingModal />
        <AddProjectMeetingCategoryModal history={history} />
    </div>
  );
};