import { MeetingSettings } from "./settings/MeetingSettings";

import { LIST_WIDGET_CONTENT_TYPE } from "../../../constants/ListWidgetContentType";
import { MeetingActions } from "./actions/MeetingActions";
import { MeetingList } from "./lists/MeetingList";

const RenderActions = () => (<MeetingActions />);

const RenderList = function ({
  list, 
  searchFilter, 
  type, 
  fromMeeting
}) {
  return(
    <MeetingList list={list} 
                 searchFilter={searchFilter} 
                 type={type} 
                 fromMeeting={fromMeeting} />
  );
};

export const MeetingContentType = {
  type: LIST_WIDGET_CONTENT_TYPE.MEETINGS,
  RenderSettings: () => (<MeetingSettings />),
  RenderActions,
  RenderList,
};