import { ACCESS_TOKEN, ACCESS_TOKEN_HEADER, API_ROUTES } from "../constants";

class AnnotationService {
    
    static async addAnnotation(projectId, annotationData) {
      const headers = {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };
      headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
      return fetch(`${API_ROUTES.PROJECTS}/${projectId}/annotation-types`, {
        method: "POST",
        credentials: "include",
        headers,
        body: JSON.stringify(annotationData),
      })
        .then(async (res) => {
          const resJson = await res.json();
          // handle success
          if (res.status === 200 || res.status === 201) {
            if(resJson.annotationType){
                return resJson.annotationType;
            }
          }
          // handle failure
          throw new Error(resJson.message);
        })
        .catch((err) => {
          console.error("Caught error: ", err);
          throw err;
        });
    }

    static async addAnnotationStandar(annotationData) {
      const headers = {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };
      headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
      return fetch(`${API_ROUTES.ORG_MARKERS}`, {
        method: "POST",
        credentials: "include",
        headers,
        body: JSON.stringify(annotationData),
      })
        .then(async (res) => {
          const resJson = await res.json();
          // handle success
          if (res.status === 200 || res.status === 201) {
            if(resJson.annotationType){
                return resJson.annotationType;
            }
          }
          // handle failure
          throw new Error(resJson.message);
        })
        .catch((err) => {
          console.error("Caught error: ", err);
          throw err;
        });
    }

    static async getAnnotationInstance(projectId, meetingId, instanceId) {
      const headers = {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };
      return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}/annotations/${instanceId}`, {
        method: "GET",
        credentials: "include",
        headers,
      }).then(async (res) => {
          const resJson = await res.json();
          // handle success
          if (res.status === 200 || res.status === 201) {
              return resJson;
          }
          // handle failure
          throw new Error(resJson.message);
        })
        .catch((err) => {
          console.error("Caught error: ", err);
          throw err;
        });
    }

    static async getAnnotationAudio(projectId, meetingId, instanceId) {
      const headers = {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "audio/mpeg",
      };
      return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}/annotations/${instanceId}/audio-comment`, {
        method: "GET",
        credentials: "include",
        headers,
      }).then(async (res) => {
          // handle success
          if (res.status === 200 || res.status === 201) {
              return true;
          }
          // handle failure
          else {
              return false;
          }
        })
        .catch((err) => {
          console.error("Caught error: ", err);
          throw err;
        });
    }
  }
  
  export { AnnotationService };
  