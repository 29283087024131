const { API_ROUTES } = require("../constants");

export const GetProductList = async () => {
  const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };
  return fetch( `${API_ROUTES.PRODUCTS}`, {
    method: 'GET',
    credentials: 'include',
    headers
  }).then(async res => {
    const resJson = await res.json();
    // handle success
    if (res.status === 200) {
      return resJson.products;
    }
    // handle failure
    throw new Error(resJson.message);
  }).catch(err => {
    console.error('Caught error: ', err);
    throw err;
  })
};

export const RequestProduct = async (productName) => {
  const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };
  return fetch( `${API_ROUTES.PRODUCTS}/${productName}`, {
    method: 'GET',
    credentials: 'include',
    headers
  }).then(async res => {
    const resJson = await res.json();
    // handle success
    if (res.status === 200) {
      return resJson.message;
    }
    // handle failure
    throw new Error(resJson.message);
  }).catch(err => {
    console.error('Caught error: ', err);
    throw err;
  })
};
