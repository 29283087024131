import React, { Component } from "react";
import { TextField } from "./TextField";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { signin } from "../actions";
import { ROUTES } from "../constants"
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const initialState = {
    email: '',
    password: '',
    showSecret: false,
    disabled: false,
};

class SigninForm extends Component{
    
    constructor(props) {
        super(props);
        this.state = initialState;
        this.notice = '';
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
        this.setState(initialState);
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleShowSecret = (event) => {
        this.setState({
            showSecret: event.type==="mousedown" || event.type==="touchstart",
        });
    }

    goTo(routeName) {
        this.props.history.push(routeName);
      }
    
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({ disabled: true });
        // handle validation
        const body = {
            email: this.state.email,
            password: this.state.password
        }
        // send request
        this.props.dispatch(signin(body)).then(()=>{
            this.setState(initialState);
            this.props.onSuccess();
        }).catch(error=>{
            this.notice = error.message;
            if (error.message === "The user is not asociated with any organization."){
                this.notice = "Su usuario no está asociado a ninguna organización";
            }
            this.setState({ disabled: false });
        })
    }


    render() {
        return(
            <div className="signin-content signin-box">
                <h2>Login</h2>
                <form onSubmit={this.handleSubmit}>
                    Correo:
                    <TextField className="login-field" 
                               placeholder="" 
                               fieldName="email" 
                               type="text" 
                               value={this.state.email} 
                               onChange={this.handleChange}/>
                    Contraseña:
                    <TextField className="login-field form-control" 
                               id="password-field" 
                               placeholder="" 
                               fieldName="password" 
                               type={ this.state.showSecret ? "text" : "password" } 
                               value={ this.state.password } 
                               onChange={ this.handleChange }/>
                    <span toggle="#password-field" 
                          className="fa fa-fw field-icon" 
                          onMouseDown={this.handleShowSecret} 
                          onMouseUp={this.handleShowSecret} 
                          onMouseMove={this.handleShowSecret} 
                          onTouchStart={this.handleShowSecret} 
                          onTouchEnd={this.handleShowSecret} 
                          onTouchMove={this.handleShowSecret}>
                              { this.state.showSecret ? <FaEyeSlash/> : <FaEye/> } 
                    </span>
                    <div className="notice-div">{this.notice}</div>
                    <div className="button-div"> 
                        <input className="login-button" type="submit" value="INGRESAR" disabled={this.state.disabled}/>
                    </div>

                    <div className="forgot-buttons"> 
                        <div className="forgot-password-button" onClick={() => this.goTo(ROUTES.SIGN_UP)}> 
                            Regístrate
                        </div>
                        {/* <div className="forgot-password-button" onClick={() => this.goTo(ROUTES.HOME)}> 
                            Volver a inicio
                        </div> */}
                    </div>
                </form> 
            </div>
        );
    }
}


const connectedSigninForm = connect(null)(SigninForm);

const SigninFormRouter = withRouter(connectedSigninForm);

export { SigninFormRouter as SigninForm };