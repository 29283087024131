import React, { Component } from "react";
import { connect } from "react-redux";
import { addProject, setProjectModal, getProjects } from "../../actions";
import { getOrgMaintainers } from "../../actions";
import { TextField } from "../TextField";
import "./styles/NewProjectModal.css"
import { BiRocket } from "react-icons/bi";
import { ListWidget } from "..";
import { FaTimes } from 'react-icons/fa';
import { ACCESS_TYPE } from "../../constants";
import { MaintainersContentType } from "../ListWidget/contentType/MaintainersContentType";

const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalProject,
        users: state.UserReducer.users,
        currentUser: state.UserReducer.currentUser,
        orgMaintainers: state.OrganizationReducer.orgMaintainers,
    };
}

const initialState =  {
    name: '',
    description: '',
    maintainers: [],
    disabled: false,
};

class NewProjectModal extends Component{
    
    constructor(props){
        super(props);
        this.state = initialState;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
        this.setMaintainersList = this.setOrgMaintainers.bind(this);
    }

    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
        this.setOrgMaintainers();
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        var newState = this.state;
        newState[event.target.name] = event.target.value
        this.setState(newState);
    }

    resetState(){
        this.setState(initialState);
    }

    goToProjects() {
        this.resetState();
        this.props.history.push(`/projects`);
    }

    setOrgMaintainers(){
        this.props.dispatch(getOrgMaintainers());
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled: true});
        const projectData = {}
        const fields = ['name', 'description', 'maintainers']
        fields.forEach(field=>{
            if (this.state[field] !== undefined || this.state[field] !== null || this.state[field] !== '') {
                projectData[field] =  this.state[field];
            }
        });
        projectData.accessType = this.props.accessType ?? ACCESS_TYPE.AR_VR_MR;

        const onSuccess = ()=>{
            this.closeModal();
            this.props.dispatch(getProjects());
        };
        const onFailure = (error)=>{
            console.error(error.fieldErrors);
            this.setState({...this.state, disabled:false});
        };
        this.props.dispatch(addProject(projectData)).then(onSuccess).catch(onFailure);
        return
    }

    closeModal(){
        this.props.dispatch(setProjectModal(false)).then(this.resetState);
    }

    // Falta implementar
    onClickOutside(event) {
        if (this.state.disabled && this.modal.contains(event.target));
        this.closeModal();
        return
    };

    handleCallback = (childData) =>{
        this.setState({maintainers: childData});
    }

    render(){
        var modalState = 'add-modal ';
        modalState += this.props.isVisible? 'modal-open':'modal-closed';

        const ADMINISTRADORES = {
            isSelectionList: true,
            content: this.props.orgMaintainers,
            contentType: MaintainersContentType,
          };

        return(
            <div className={modalState}>
                <div className="project-modal-box">
                    <div id="MyModal" className="modal-content">
                        <button className="closing-x" onClick={this.closeModal}>
                            <FaTimes/>
                        </button>
                        <h2>NUEVO PROYECTO</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="new-project-top">
                                <div className="new-project-left-content">
                                    <div className="new-project-logo">
                                        <BiRocket size={50} />
                                    </div>
                                </div>
                                <div className="new-project-right-content">
                                    <TextField 
                                        className="name-project-field" 
                                        fieldName="name" 
                                        type="text" 
                                        value={this.state.name} 
                                        placeholder={this.props.placeholder ? this.props.placeholder.name : "Nombre Proyecto"} 
                                        onChange={this.handleChange}>
                                    </TextField>
                                    <TextField
                                        className="description-project-field"
                                        fieldName="description" 
                                        maxlength={200}
                                        type="text" 
                                        value={this.state.description} 
                                        placeholder={this.props.placeholder ? this.props.placeholder.description : "Descripción Proyecto"} 
                                        onChange={this.handleChange}>
                                    </TextField>
                                </div>
                            </div>
                            <div className="new-project-bottom">
                                <div className="list-container-new-project">
                                    <ListWidget content={ADMINISTRADORES} parentCallback={this.handleCallback}/>
                                </div>
                                <input className="new-project-button" type="submit" value="CREAR PROYECTO" disabled={this.state.disabled}/>
                            </div>
                        </form>
                   </div>
                </div>
            </div>
        )
    }
}
/*
<select className="login-field" id="maintainers" name="maintainers" placeholder={<div>Type to search</div>} value={this.state.maintainers} onChange={this.handleChange}>
    <option value={mantainerVal} defaultValue disabled hidden>
        { mantainerLabel }
    </option>
    {this.props.users.map(user=>(
        <option value={user.id} key={user.id}>
            {user.username}
        </option>
    ))}
</select>
*/

const connectedNewProjectModal = connect(mapStateToProps)(NewProjectModal);

export { connectedNewProjectModal as NewProjectModal };