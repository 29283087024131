export const PARAMS = {
  USER_ID: ":userId",
  PROJECT_ID: ":projectId",
  PROJECT_VIEW: ":projectView",
  TOKEN: ":token",
  PASSWORD_BOOL: ":password",
  MARKER_ID:":noteId",
  MARKER_VIEW: ":markerView",
};

export const ROUTES = {
  HOME: "/",
  LOGGED_HOME: "/home",
  FREE_DOWNLOADS: "/free-downloads",
  DOWNLOADS: "/downloads",
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  MY_ACCOUNT: "/my-account",
  PROJECTS: "/projects",
  MARKERS: "/markers",
  MY_PAGE: "/my-page",
  USER_PAGE: "/user-page/" + PARAMS.USER_ID,
  PROJECT_PAGE: "/projects/" + PARAMS.PROJECT_ID,
  PROJECT_PAGE_VIEW:
    "/projects/" + PARAMS.PROJECT_ID + "/" + PARAMS.PROJECT_VIEW,
  VERIFY_ACCOUNT:
    "/verify-account/" + PARAMS.TOKEN + "/" + PARAMS.PASSWORD_BOOL,
  MY_ORG: "/my-org",
  ANNOTATION_TYPES: "/markers/" + PARAMS.MARKER_ID,
  ANNOTATION_TYPES_PAGE_VIEW: "/markers/" + PARAMS.MARKER_ID + "/" + PARAMS.MARKER_VIEW,
  ORGANIZATIONS: "/organizations",
  PROJECT_SELECTED: "/project-selected",
  ORG_INFO: "/organization",
  MEETINGS: "/meetings",
};
