import { LIST_WIDGET_CONTENT_TYPE } from "../../../constants/ListWidgetContentType";
import { DataActions } from "./actions/DataActions";
import { AttachedFileList } from "./lists/AttachedFileList";
import { ReactComponent as DocIcon } from '../../svg/icono_lecciones_aprendidas.svg';
import { AttachedFileListItemOptions } from "./lists/itemOptions/AttachedFileListItemOptions";

export const DataContentType = {
  type: LIST_WIDGET_CONTENT_TYPE.DATA,
  RenderTitle: () => "DOCUMENTOS",
  RenderActions: () => (<DataActions />),
  RenderList: AttachedFileList,
  ItemOptions: AttachedFileListItemOptions,
  RenderIcon: () => (<div className='attached-file-icon'> <DocIcon /> </div>),
};