import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProjectsAssigned } from "../../../../actions";
import { USER_TYPE } from "../../../../constants";
import { ValueAContainsB } from "../../../../helpers/FormatHelper";
import { ListWidgetItem } from "../../../ListWidgetItem";

export const MemberList = function ({ list, searchFilter }) {

  const dispatch = useDispatch();
  const history = useHistory();

  const goToUser = (member) => {
    if (!member || member.type !== USER_TYPE.MAINTAINER) return;
    dispatch(getProjectsAssigned(member.id));
    history.push({
      pathname: `/user-page/${ member.id }`,
      state: { member },
    });
  };

  return(
    <div>
      {
        list.map(
          (item, index) => ValueAContainsB({ valueA: item.User.username,  valueB: searchFilter}) && (
            <ListWidgetItem
              type={item.type === USER_TYPE.ADMIN? ("ADMINISTRADORES_LIST" ) : (item.type === USER_TYPE.MAINTAINER)? ("MAINTAINERS_LIST") : ("GUESTS_LIST")}
              key={index}
              itemId={item.id}
              itemName={item.User.username}
              subText= {`${USER_TYPE[item.type]}`} 
              description={"Correo: " + item.User.email}
              goToUser={() => goToUser(item)}
            />
          )
        )
      }
    </div>
  );
};
