import React, { Component } from "react";
import { connect } from "react-redux";
import { NewMeetingView, setMeetingModal } from "../../actions";
import "./styles/Modal.css"
import "./styles/AddMeetingViewModal.css"
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import { Button, ListWidget } from "..";
import { setAddMeetingViewModal } from "../../actions";
import { LIST_WIDGET_CONTENT_TYPE } from "../../constants/ListWidgetContentType";
import { ViewsContentType } from "../ListWidget/contentType/ViewsContentType";

const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalAddMeetingView,
        selectedProject: state.ProjectReducer.selectedProject,
        selectedProjectViews: state.ProjectReducer.projectViews,
    };
}

const initialState =  {
    name: '',
    description: '',
    viewId: [],
    disabled: false,
};

class AddMeetingViewModal extends Component{
    
    constructor(props){
        super(props);
        this.state = initialState;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
    }


    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        var newState = this.state;
        newState[event.target.name] = event.target.value;
        this.setState(newState);
    }

    resetState(){
        this.setState(initialState);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled:true});
        const onSuccess = ()=>{
            this.closeModal();
        };
        const onFailure = (error)=>{
            console.error(error.fieldErrors);
            this.setState({...this.state, disabled:false});
        };
        const newViews = this.props.selectedProjectViews.filter(({id}) => this.state.viewId.includes(id));
        this.props.dispatch(NewMeetingView(this.state.viewId, newViews)).then(onSuccess).catch(onFailure);
    }

    closeModal(){
        this.props.dispatch(setAddMeetingViewModal(false)).then(this.resetState);
        this.props.dispatch(setMeetingModal(true)).then(this.resetState);
    }

    // Falta implementar
    onClickOutside(event) {
        if (this.state.disabled && this.modal.contains(event.target)) return;
        this.closeModal();
    };

    SetSelectedViews = (childData) =>{
        this.setState({viewId: childData});
    }
    
    render(){
        var modalState = 'add-modal ';
        modalState += this.props.isVisible? 'modal-open':'modal-closed';

        const { selectedProject } = this.props;

        const VIEW_3D = {
            type: LIST_WIDGET_CONTENT_TYPE.VIEW_3D,
            isSelectionList: true,
            content: [],
            contentType: ViewsContentType,
        };
        if(this.props.isVisible && this.props.selectedProjectViews && this.props.selectedProjectViews.length !== 0 ){
            VIEW_3D.content = this.props.selectedProjectViews;
        }

        return(
            <div className={modalState}>
                <div className="meeting-view-modal">
                    <div id="MyModal" className="modal-content">
                        <Button className="modal-button" onClick={this.closeModal}>
                            <Close/>
                        </Button>
                        <div className="project-header-3" style={{borderTopLeftRadius: "10px"}}>
                            <div className="project-logo"></div>
                            <div className="project-data">
                                <div className="project-name">{selectedProject? (selectedProject.name):("No name")}</div>
                                <div className="project-context">
                                    <span> Creada el:<b> {selectedProject && selectedProject.createdAt ? (selectedProject.createdAt.substring(0,10)):("No Date")} </b></span>
                                    &nbsp;
                                    <span> Nombre del Proyecto: <b>{selectedProject? (selectedProject.name):("No Admin")}</b>{" "}</span>
                                </div>
                                <div className="project-description">
                                    {selectedProject? (selectedProject.description):("No description")}
                                </div>
                            </div>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            
                            <div className="meeting-view-list-content">
                                <ListWidget content={VIEW_3D} parentCallback={this.SetSelectedViews}/>
                            </div>
                            <div className="add-view-button-box"> 
                                <input className="add-view-button" type="submit" value="AGREGAR VISTA" disabled={this.state.disabled}/>
                            </div>
                        </form> 
                </div>
                </div>
            </div>
        )
    }
}

const connectedAddMeetingViewModal = connect(mapStateToProps)(AddMeetingViewModal);

export { connectedAddMeetingViewModal as AddMeetingViewModal };