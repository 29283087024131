import { React, useEffect, useRef, useState } from "react";
import "../../../../styles/ListWidget.css";
import "../../../../styles/List.css";
import "../../../../styles/ListMarker.css";
import "../../../../styles/ProjectSection.css";
import "../../../../styles/Meetings.css";
import { ReactComponent as View3D } from  "../../../../../routes/svg/icono_vista_3D.svg";

import { Button } from "../../../../Button";
import { DeleteConfirmModalView } from "../../../../modals/DeleteConfirmModalView";
import { useDispatch } from "react-redux";
import { setDeleteViewModal } from "../../../../../actions";
import { FormatDate } from "../../../../../helpers/FormatHelper";

export const ViewListItem = ({
  view,
  creando,
  deleting,
  onSelected,
  onDeselected,
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  // state
  const [selected, setSelected] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  
  let class_item = "list-item project";

  useEffect(() => {
    const listener = (event) => {
      if (!optionsVisible) return;
      // Do nothing if clicking ref's element or descendent elements
      if (ref.current && !ref.current.contains(event.target)) {
        setOptionsVisible(false);
      }
    };
    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsVisible]);
  
  const toggle = (index) => {
    if (selected) {
      onDeselected(index);
    } else {
      onSelected(index);
    }
    setSelected(!selected);
  };

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  const handleDelete = () => {
    setOptionsVisible(false);
    if (view.isValid) {
      dispatch(setDeleteViewModal(true, view.id));
    }
  };

  return (
    <div className={`${class_item}`} >
      <div className="project-left-content">
        {
          creando ? (
            <div className="list-item-icon-2">
              <input
                className="elegirAdms"
                readOnly
                checked={ selected }
                type="checkbox"
                onClick={() => toggle(view.id)}
              />
              <View3D />
            </div>
          ) : (
            <div className={`list-item-icon`}>
              <View3D />
            </div>
          )
        }
        <div className="project-data">
          <div className="project-name">
            {/* {itemName} */}
          </div>
          {/* <div className={`project-context ${idFinished}`}> */}
          <div className={`project-context`}>
            <span>{view.fileName}</span>
          </div>
          <div className="project-description">{view.versionIndex} {!view.isValid?'(Deleted)':''}</div>
        </div>
      </div>
      <div className="project-right-content">
        {
          <div className="date-box-container">
            { "" }
            <div className={`date-box`}>
              <div>
                <div className="date-box-text ">
                  <span className="Date-widget-tag">{"Creada el:"}</span>
                </div>
                <div className="date-box-date">
                  <span className="Date-widget">{ FormatDate(view.createdAt) }</span>
                </div>
              </div>
            </div>
          </div>
        }
        {
          creando || deleting ? (
            ""
          ) : (
            <div className="meeting-options-and-button">
              <Button className={"move-right general-button"} onClick={toggleOptions}>
                ...
              </Button>
              <div ref={ref} className={`meeting-options meeting-mouse-up ${optionsVisible ? "active" : "hidden"}`}>
                <div>
                  <Button className="button-vertical project-mouse-up" onClick={handleDelete}>
                    Eliminar
                  </Button>
                </div>
                {/* <Button className="button-vertical project-mouse-up" >
                  Editar
                </Button> */}
                {/* <Button className="button-vertical project-mouse-up" onClick={handleShowMeeting}>
                  Ver detalles
                </Button> */}
              </div>
              <DeleteConfirmModalView view={view}/>
            </div>
          )
        }
      </div>
    </div>
  );
};
