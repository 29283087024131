import React, { Component } from "react";
import { TextField } from "./TextField";
import { connect } from "react-redux";
import { signup } from "../actions";
import { ROUTES } from "../constants";
import { withRouter } from "react-router";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const initialState = {
  username: "",
  email: "",
  password: "",
  passwordVerification: "",
  orgName: "",
  alias: "",
  company: "",
  disabled: false,
  showSecret: false,
  showSecret2: false,
};

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.something = "";
    this.notice = "";
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  componentDidMount() {
    this.setState(initialState);
  }

  goTo(routeName) {
    this.props.history.push(routeName);
  }

  handleChange(event) {
    if (this.state.disabled) {
      return;
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });

    const body = {
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      passwordVerification: this.state.passwordVerification,
      orgName: this.state.orgName,
      alias: this.state.alias,
      company: this.state.company,
    };

    this.props
      .dispatch(signup(body))
      .then(() => {
        this.setState(initialState);
        this.props.onSuccess();
      })
      .catch((error) => {
        if (error[0] === "Validation isEmail on email failed") {
          this.notice = "Por favor ingrese un mail válido";
        } else if (error[0] === "email must be unique") {
          this.notice = "El mail que ingresó ya tiene una cuenta";
        } else if (error[0] === "username must be unique") {
          this.notice = "El nombre de usuario que ingresó ya existe";
        } else if (error[0] === "name must be unique") {
          this.notice = "El nombre de organización que ingresó ya existe";
        } else if (error === "Submitted paswords don't match") {
          this.notice = "Las contraseñas deben ser iguales";
        }

        console.error(error);
        this.setState({ disabled: false });
      });
  }

  handleShowSecret = (event) => {
    this.setState({
      showSecret: (event.type==="mousedown" || event.type==="touchstart"),
    });
  }

  handleShowSecret2 = (event) => {
    this.setState({
      showSecret2: (event.type==="mousedown" || event.type==="touchstart"),
    });
  }

  render() {
    return(
      <div className="signup-content">
        <span className="Login-Usuario signup-Usuario">
          Regístrate
        </span>

        <form onSubmit={this.handleSubmit}>
          <div className="form-container">
            <div className="user-info">
              Nombre de usuario:
              <TextField className="login-field" minlength="3" fieldName="username" type="text" value={this.state.username} placeholder="" onChange={this.handleChange}/>
              Correo:
              <TextField className="login-field" fieldName="email" type="email" value={this.state.email} placeholder="" onChange={this.handleChange}/>
              Contraseña:
              <TextField className="login-field" minlength="6" fieldName="password" type={this.state.showSecret?"text":"password"} value={this.state.password} placeholder="" onChange={this.handleChange}/>
              <span toggle="#password-field" 
                    className="fa fa-fw field-icon" 
                    onMouseDown={this.handleShowSecret} 
                    onMouseUp={this.handleShowSecret} 
                    onMouseMove={this.handleShowSecret} 
                    onTouchStart={this.handleShowSecret} 
                    onTouchEnd={this.handleShowSecret} 
                    onTouchMove={this.handleShowSecret}>
                      { this.state.showSecret ? <FaEyeSlash/> : <FaEye/> }
              </span>
              Repetir contraseña:
              <TextField className="login-field" minlength="6" fieldName="passwordVerification" type={this.state.showSecret2?"text":"password"} value={this.state.passwordVerification} placeholder="" onChange={this.handleChange}/>
              <span toggle="#password-field" 
                    className="fa fa-fw field-icon" 
                    onMouseDown={this.handleShowSecret2} 
                    onMouseUp={this.handleShowSecret2} 
                    onMouseMove={this.handleShowSecret2} 
                    onTouchStart={this.handleShowSecret2} 
                    onTouchEnd={this.handleShowSecret2} 
                    onTouchMove={this.handleShowSecret2}>
                      { this.state.showSecret2 ? <FaEyeSlash/> : <FaEye/> }
              </span>
            </div>
            <div className="org-info">
              Nombre de la organización:
              <TextField className="login-field" fieldName="orgName" type="text" value={this.state.orgName} placeholder="" onChange={this.handleChange}/>
            </div>
          </div>
          <div className="notice-div">{this.notice}</div>
          <div className="button-div">
            <input className="login-button" type="submit" value="REGISTRARME" disabled={this.state.disabled}/>
          </div>
        </form>
        
        <div className="forgot-buttons"> 
          <div className="forgot-password-button" onClick={() => this.goTo(ROUTES.SIGN_IN)}> 
            Ya tengo cuenta
          </div>
          {/* <div className="forgot-password-button" onClick={() => this.goTo(ROUTES.HOME)}> 
            Volver a inicio
          </div> */}
        </div>
      </div>
    );
  }
}

const connectedSignupForm = connect(null)(SignupForm);

const SignupFormRouter = withRouter(connectedSignupForm);

export { SignupFormRouter as SignupForm };
