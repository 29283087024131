const { ACCESS_TYPE } = require("../constants");

exports.CheckAccessTypeVR = (accessType) => accessType === ACCESS_TYPE.VR || accessType === ACCESS_TYPE.VR_MR || accessType === ACCESS_TYPE.AR_VR || accessType === ACCESS_TYPE.AR_VR_MR;

exports.CheckAccessTypeAR = (accessType) => accessType === ACCESS_TYPE.AR || accessType === ACCESS_TYPE.AR_MR || accessType === ACCESS_TYPE.AR_VR || accessType === ACCESS_TYPE.AR_VR_MR;

exports.CheckAccessTypeMR = (accessType) => accessType === ACCESS_TYPE.MR || accessType === ACCESS_TYPE.AR_MR || accessType === ACCESS_TYPE.VR_MR || accessType === ACCESS_TYPE.AR_VR_MR;

exports.CompareAccessTypeAinB = (accessTypeA, accessTypeB) => {
  if (accessTypeB === ACCESS_TYPE.AR_VR_MR || accessTypeB === ACCESS_TYPE.NONE || accessTypeA === accessTypeB) {
    return true;
  }
  if (accessTypeB === ACCESS_TYPE.AR_MR) {
    if (accessTypeA === ACCESS_TYPE.AR || accessTypeA === ACCESS_TYPE.MR) {
      return true;
    }
  }
  if (accessTypeB === ACCESS_TYPE.AR_VR) {
    if (accessTypeA === ACCESS_TYPE.AR || accessTypeA === ACCESS_TYPE.VR) {
      return true;
    }
  }
  if (accessTypeB === ACCESS_TYPE.VR_MR) {
    if (accessTypeA === ACCESS_TYPE.VR || accessTypeA === ACCESS_TYPE.MR) {
      return true;
    }
  }
  return false;
};
