import { MEETING_ACTIONS } from "../constants";

const initialState = {
  meetings: [],
  newGuests: [],
  newGuestsIds: [],
  newViews:[],
  newViewIds:[],
  newNotes:[],
  newNotesIds:[],
  newMeeting:null,
  selectedMeeting: null,
  selectedCategoryId:-1,
};

const MeetingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  var result, resultIds;

  switch (type) {
    case MEETING_ACTIONS.GET_USER_MEETINGS:
      return { ...state, meetings: payload };

    case MEETING_ACTIONS.ADD_MEETING:
      return  {...state, newMeeting: payload, meetings: [...state.meetings, payload] };

    case MEETING_ACTIONS.GET_PROJECT_MEETING:
      return {...state, selectedMeeting: payload}

    case MEETING_ACTIONS.NEW_MEETING_GUEST:
      const currentGuestIds = new Set(state.newGuests.map(item => item.id))
      const newGuests =  [...state.newGuests, ...payload.guests.filter(item => !currentGuestIds.has(item.id))];
      
      const newGuestIds = [
        ...state.newGuestsIds, 
        ...payload.guestIds.filter((newGuestId) => !state.newGuestsIds.some((currentGuestId) => currentGuestId === newGuestId))];
        
      return {
        ...state, 
        newGuests: newGuests,
        newGuestsIds: newGuestIds,
      };
      
    case MEETING_ACTIONS.NEW_MEETING_VIEWS:
      const currentViewsIds = new Set(state.newViews.map(item => item.id));
      const newViews = [...state.newViews, ...payload.views.filter(item=>!currentViewsIds.has(item.id))];

      const newViewIds = [
        ...state.newViewIds,
         ...payload.viewIds.filter((newViewId)=> !state.newViewIds.some((currentViewId)=>currentViewId===newViewId))];
      return {
        ...state, 
        newViews: newViews,
        newViewIds: newViewIds,
      };

    case MEETING_ACTIONS.NEW_MEETING_NOTES:
      const currentAnnotationTypeIds = new Set(state.newNotes.map(item => item.id));
      const newNotes = [...state.newNotes, ...payload.notes.filter(item=>!currentAnnotationTypeIds.has(item.id))];

      const newNotesIds = [
        ...state.newNotesIds,
        ...payload.noteIds.filter((newNoteId)=> !state.newNotesIds.some((currentNoteId)=>currentNoteId===newNoteId))];
      
      return {
        ...state, 
        newNotes: newNotes,
        newNotesIds: newNotesIds,
      };
    case MEETING_ACTIONS.RESET_MEETING_FORM:
      return {
        ...state,
        newNotes: [],
        newNotesIds: [],
        newViews: [],
        newViewIds: [],
        newGuests: [],
        newGuestsIds: [],
        selectedCategoryId: -1,
      }

    case MEETING_ACTIONS.RESET_MEETINGS:
      return initialState;

    case MEETING_ACTIONS.RESET_CREATE_MEETINGS:
      return {...initialState, meetings: state.meetings};

    case MEETING_ACTIONS.SET_MEETING_CATEGORY:
      return {...state, selectedCategoryId:payload}

    case MEETING_ACTIONS.DELETE_MEETING_LIST_VIEW:
      result = [];
      resultIds = [];
      state.newViews.forEach(element => {
        if(element.id !== payload){
          result.push(element);
          resultIds.push(element.id);
        }
      })
      return {...state, newViews: result, newViewIds: resultIds};

    case MEETING_ACTIONS.DELETE_MEETING_LIST_GUEST:
      result = [];
      resultIds = [];
      state.newGuests.forEach(element => {
        if(element.id !== payload){
          result.push(element);
          resultIds.push(element.id);
        }
      })
      return {...state, newGuests: result,  newGuestIds: resultIds};

    case MEETING_ACTIONS.DELETE_MEETING_LIST_NOTE:
      result = [];
      resultIds = [];
      state.newNotes.forEach(element => {
        if(element.id !== payload){
          result.push(element);
          resultIds.push(element.id);
        }
      })
      return {...state, newNotes: result,  newNoteIds: resultIds};

    default:
      return state;
  }
};

export default MeetingReducer;
