import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import ModalReducer from "./ModalReducer";
import OrganizationReducer from "./OrganizationReducer";
import ProjectReducer from "./ProjectReducer";
import SidePanelReducer from "./SidePanelReducer";
import MeetingReducer from "./MeetingReducer";
import MarkerReducer from "./MarkerReducer";
import { ProductsReducer } from './ProductsReducer';
import { AttachedFileReducer } from './AttachedFileReducer';

export default combineReducers({
  UserReducer,
  ModalReducer,
  OrganizationReducer,
  MeetingReducer,
  ProjectReducer,
  SidePanelReducer,
  MarkerReducer,
  ProductsReducer,
  AttachedFileReducer,
});
