export const USER_ACTION = {
  SIGN_UP: "SIGN_UP",
  SIGN_IN: "SIGN_IN",
  LOG_OUT: "LOG_OUT",
  GET_USERS: "GET_USERS",
  GET_USER_DATA: "GET_USER_DATA",
  GET_USERS_ORGANIZATIONS: "GET_USERS_ORGANIZATIONS",
  SELECT_ORGANIZATION: "SELECT_ORGANIZATION",
  ADD_USER: "ADD_USER",
  GET_USER: "GET_USER",
  VERIFY_USER: "VERIFY_USER",
  UPDATE_SELF: "UPDATE_SELF",
  SELECT_USER: "SELECT_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
  DESREGISTER: "DESREGISTER",
  ORG_LOG_OUT: "ORG_LOG_OUT",
};

export const ORG_ACTIONS = {
  ORG_INFO: "ORG_INFO",
  UPDATE_ORG: "UPDATE_ORG",
  GET_ORG_USERS: "GET_ORG_USERS",
  DELETE_ORG: "DELETE_ORG",
  NEW_MANTAINER: "NEW_MANTAINER",
  NEW_GUEST: "NEW_GUEST",
  GET_ORG_MAINTAINERS: "GET_ORG_MAINTAINERS",
  RESET_ORGS: "RESET_ORGS",
  GET_ORG_MARKERS: "GET_ORG_MARKERS",
  GET_ORG_MARKER: "GET_ORG_MARKER",
  RESET_MARKER_INFO: "RESET_MARKER_INFO",
};

export const PROJECT_ACTIONS = {
  GET_PROJECTS: "GET_PROJECTS",
  GET_VIEWS: "GET_VIEWS",
  GET_PROJECTS_ASSIGNED: "GET_PROJECTS_ASSIGNED",
  GET_PROJECT: "GET_PROJECT",
  SELECT_PROJECT: "SELECT_PROJECT",
  ADD_PROJECT: "ADD_PROJECT",
  UPDATE_PROJECT: "UPDATE_PROJECT",
  DELETE_PROJECT: "DELETE_PROJECT",
  DELETE_PROJECT_VIEW: "DELETE_PROJECT_VIEW",
  GET_PROJECT_MAINTAINERS: "GET_PROJECT_MAINTAINERS",
  ADD_PROJECT_MAINTAINER: "ADD_PROJECT_MAINTAINER",
  DELETE_PROJECT_MAINTAINER: "DELETE_PROJECT_MAINTAINER",
  GET_PROJECT_MEETING_CATEGORIES: "GET_PROJECT_MEETING_CATEGORIES",
  ADD_PROJECT_MEETING_CATEGORY: "ADD_PROJECT_MEETING_CATEGORY",
  ADD_PROJECT_MEETING_GUESTS: "ADD_PROJECT_MEETING_GUESTS",
  ADD_PROJECT_MEETING_VIEWS: "ADD_PROJECT_MEETING_VIEWS",
  ADD_PROJECT_MEETING_NOTES: "ADD_PROJECT_MEETING_NOTES",
  GET_PROJECT_VIEWS: "GET_PROJECT_VIEWS",
  GET_PROJECT_MEETINGS: "GET_PROJECT_MEETINGS",
  RESET_PROJECT_INFO: "RESET_PROJECT_INFO",
  RESET_PROJECTS: "RESET_PROJECTS",
  GET_PROJECT_ANNOTATIONS: "GET_PROJECT_ANNOTATIONS",
  GET_PROJECT_MEETING: "GET_PROJECT_MEETING",
  PUT_PROJECT_MEETING: "PUT_PROJECT_MEETING",
  GET_PROJECT_MEETING_GUESTS: "GET_PROJECT_MEETING_GUESTS",
  GET_PROJECT_MEETING_VIEWS: "GET_PROJECT_MEETING_VIEWS",
  ADD_PROJECT_IMPORT: "ADD_PROJECT_IMPORT",
  GET_PROJECT_MEETING_NOTES: "GET_PROJECT_MEETING_NOTES",
  GET_PROJECT_MEETING_ANOTATION_INSTANCES:"GET_PROJECT_MEETING_ANOTATION_INSTANCES",
  RESET_MEETING: "RESET_MEETING",
  RESET_MARKER_INFO: "RESET_MARKER_INFO",
  SET_ACCESS_TYPE_DISPLAYED: "SET_ACCESS_TYPE_DISPLAYED",
};

export const ATTACHED_FILE_ACTIONS = {
  GET_PROJECT_ATTACHED_FILE_LIST: "GET_PROJECT_ATTACHED_FILE_LIST",
  ADD_ATTACHED_FILE: "ADD_ATTACHED_FILE",
  UPDATE_ATTACHED_FILE: "UPDATE_ATTACHED_FILE",
  DELETE_ATTACHED_FILE: "DELETE_ATTACHED_FILE",
};

export const MODAL_ACTION = {
  SET_USER_MODAL: "SET_USER_MODAL",
  SET_DATA_MODAL: "SET_DATA_MODAL",
  SET_DELETE_USER_MODAL: "SET_DELETE_USER_MODAL",
  SET_DESREGISTER_MODAL: "SET_DESREGISTER_MODAL",
  SET_ORGANIZATION_MODAL: "SET_ORGANIZATION_MODAL",
  SET_PROJECT_MODAL: "SET_PROJECT_MODAL",
  SET_UPDATE_PROJECT_MODAL: "SET_UPDATE_PROJECT_MODAL",
  SET_PROJECT_MAINTAINER_MODAL: "SET_PROJECT_MAINTAINER_MODAL",
  CLOSE_ALL: "CLOSE_ALL",
  SET_DELETE_ORG_MODAL: "SET_DELETE_ORG_MODAL",
  SET_DELETE_PROJECT_MODAL: "SET_DELETE_PROJECT_MODAL",
  SET_DELETE_PROJECT_MAINTAINER: "SET_DELETE_PROJECT_MAINTAINER",
  SET_CATEGORY_MEETING_MODAL: "SET_CATEGORY_MEETING_MODAL",
  SET_ADD_MEETING_CATEGORY_MODAL: "SET_ADD_MEETING_CATEGORY_MODAL",
  SET_CONFIG_CATEGORY_MEETING_MODAL: "SET_CONFIG_CATEGORY_MEETING_MODAL",
  SET_MEETING_MODAL: "SET_MEETING_MODAL",
  SET_DELETE_MEETING_MODAL: "SET_DELETE_MEETING_MODAL",
  SET_DELETE_VIEW_MODAL: "SET_DELETE_VIEW_MODAL",
  SET_MARKER_MODAL: "SET_MARKER_MODAL",
  SET_MEETING_GUEST_MODAL: "SET_MEETING_GUEST_MODAL",
  SET_MEETING_VIEW_MODAL: "SET_MEETING_VIEW_MODAL",
  SET_MEETING_NOTE_MODAL: "SET_MEETING_NOTE_MODAL",
  SET_IMPORT_TYPES_OF_MEETING_MODAL: "SET_IMPORT_TYPES_OF_MEETING_MODAL",
  SET_GET_MEETING_MODAL: "SET_GET_MEETING_MODAL",
  SET_ANNOTATION_MODAL: "SET_ANNOTATION_MODAL",

  SET_ATTACHED_FILE_DELETE_MODAL: "SET_ATTACHED_FILE_DELETE_MODAL",
  SET_ATTACHED_FILE_UPDATE_MODAL: "SET_ATTACHED_FILE_UPDATE_MODAL",
};

export const SIDEPANEL_ACTIONS = {
  SET_SELECTED: "SET_SELECTED",
  SET_VISIBILITY: "SIDEPANEL_VISIBILITY",
  TOGGLE_VISIBILITY: "TOGGLE_VISIBILITY",
};

export const MEETING_ACTIONS = {
  GET_USER_MEETINGS: "GET_USER_MEETINGS",
  GET_PROJECT_MEETING: "SELECT_PROJECT_MEETING",
  PUT_PROJECT_MEETING: "UPDATE_PROJECT_MEETING",
  ADD_MEETING: "ADD_MEETING",
  DELETE_MEETING: "DELETE_MEETING",
  NEW_MEETING_GUEST: "NEW_MEETING_GUEST",
  NEW_MEETING_VIEWS: "NEW_MEETING_VIEWS",
  NEW_MEETING_NOTES: "NEW_MEETING_NOTES",
  RESET_MEETING_FORM: "RESET_MEETING_FORM", 
  RESET_MEETINGS: "RESET_MEETINGS",
  RESET_CREATE_MEETINGS: "RESET_CREATE_MEETINGS",
  SET_MEETING_CATEGORY: "SET_MEETING_CATEGORY",
  DELETE_MEETING_LIST_VIEW: "DELETE_MEETING_LIST_VIEW",
  DELETE_MEETING_LIST_GUEST: "DELETE_MEETING_LIST_GUEST",
  DELETE_MEETING_LIST_NOTE: "DELETE_MEETING_LIST_NOTE",
};

export const ANNOTATION_ACTIONS = {
  ADD_ANNOTATION: "ADD_ANNOTATION",
  ADD_ANNOTATION_STANDAR: "ADD_ANNOTATION_STANDAR",
  GET_INSTANCE_INFO: "GET_INSTANCE_INFO",
  GET_INSTANCE_AUDIO: "GET_INSTANCE_AUDIO",
  RESET_INSTANCE: "RESET_INSTANCE",
};

export const PRODUCTS_ACTIONS = {
  GET_PRODUCTS: "GET_PRODUCTS",
};
