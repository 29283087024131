import { ACCESS_TOKEN, ACCESS_TOKEN_HEADER, API_ROUTES } from "../constants";

class MeetingService {
  static async getMeetings() {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    return fetch(`${API_ROUTES.MEETINGS_DATA}`, {
      method: "GET",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json().then((data) => {
          return data.message;
        });
        // handle success
        if (res.status === 200) {
          return resJson;
        }
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }


  static async updateMeeting(projectId, meetingId, meetingData) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}`, {
      method: "PUT",
      credentials: "include",
      headers,
      body: JSON.stringify(meetingData),
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson.meeting;
        }
        console.error(resJson);
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async addMeeting(projectId, meetingData) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meetings`, {
      method: "POST",
      credentials: "include",
      headers,
      body: JSON.stringify(meetingData),
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson.meeting;
        }
        console.error(resJson);
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async getMeeting(projectId, meetingId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}`, {
      method: "GET",
      credentials: "include",
      headers
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          return resJson.meeting;
        }
        console.error(resJson);
        // handle failure
        throw new Error(resJson.message);
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }

  static async deleteMeeting(projectId, meetingId) {
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN);
    return fetch(`${API_ROUTES.PROJECTS}/${projectId}/meetings/${meetingId}`, {
      method: "DELETE",
      credentials: "include",
      headers,
    })
      .then(async (res) => {
        const resJson = await res.json();
        // handle success
        if (res.status === 200 || res.status === 201) {
          localStorage.setItem(ACCESS_TOKEN, resJson.accessToken);
          return resJson;
        }
        // handle failure
        throw resJson.message;
      })
      .catch((err) => {
        console.error("Caught error: ", err);
        throw err;
      });
  }


}

export { MeetingService };
