import { LIST_WIDGET_CONTENT_TYPE } from "../../../constants/ListWidgetContentType";
import { MeetingList } from "./lists/MeetingList";

const RenderList = function ({
  list, 
  searchFilter, 
  type,
  fromMeeting
}) {
  return(
    <MeetingList list={list} 
                 searchFilter={searchFilter} 
                 type={type} 
                 fromMeeting={fromMeeting} />
  );
};

export const AnnotationMeetingContentType = {
  type: LIST_WIDGET_CONTENT_TYPE.MEETINGS,
  RenderList,
};
