import { useSelector } from "react-redux";
import { ACCESS_TYPE } from "../../../../constants";
import { CompareAccessTypeAinB } from "../../../../helpers";
import { ValueAContainsB } from "../../../../helpers/FormatHelper";
import { ViewListItem } from "./items/ViewListItem";

export const ViewsList = ({
  list,
  searchFilter,
  AddItemToSelection,
  RemoveItemFromSelected,
  isSelectionList,
}) => {

  const accessTypeDisplayed = useSelector((state) => (state.ProjectReducer.accessTypeDisplayed));

  return(
    <div>
      {
        list.filter(item => {
          let res = CompareAccessTypeAinB(item.View3D ? item.View3D.accessType : ACCESS_TYPE.NONE, accessTypeDisplayed);
          res = res && ValueAContainsB({ valueA: item.fileName, valueB: searchFilter })
          return res;
        }).map(
          (item, index) => (
            <ViewListItem
              key={index}
              view={item}
              onSelected={AddItemToSelection}
              onDeselected={RemoveItemFromSelected}
              creando={isSelectionList}
            />
          )
        )
      }
    </div>
  );
};
