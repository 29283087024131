import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MiniButton, NewProjectModal } from "../../..";
import { ACCESS_TYPE, MODAL_ACTION, USER_TYPE } from "../../../../constants";

export function ProjectActions ({accessType, importing}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const orgUser = useSelector((state) => state.UserReducer.orgUser);

  const handleClick = () => {
    dispatch({
      type: MODAL_ACTION.SET_PROJECT_MODAL,
      payload: true,
    });
  };

  return (
    <div>
      {
        orgUser.accountType === USER_TYPE.ADMIN && !importing ? (
        <>
          <MiniButton type="create" onClick={handleClick} />
          <NewProjectModal history={history} accessType={ accessType ?? ACCESS_TYPE.VR }/>
        </>
        ) : (
        <></>
        )
      }
  </div>
  )
}