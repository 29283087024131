import { useDispatch, useSelector } from "react-redux";
import { AddMeetingGuestModal } from "../../../modals/AddMeetingGuestModal";
import { AddMeetingNoteModal } from "../../../modals/AddMeetingNoteModal";
import { AddMeetingViewModal } from "../../../modals/AddMeetingViewModal";
import { NewMeetingModal } from "../../../modals/NewMeetingModal";

import { MiniButton, AnnotationModal } from "../../..";
import { deselectMeeting, getProjectAnnotations, getProjectViews, setMeetingModal } from "../../../../actions";
import { useEffect } from "react";

export function MeetingActions () {
  const dispatch = useDispatch();
  const selectedProject = useSelector((state)=>state.ProjectReducer.selectedProject);
  const handleClick = () => {
    dispatch(getProjectViews(selectedProject.id));
    dispatch(getProjectAnnotations(selectedProject.id));
    dispatch(deselectMeeting()).then(
      dispatch(setMeetingModal(true))
    );
  };

  useEffect(function closeCreateModal() {
    dispatch(setMeetingModal(false));
  }, [dispatch]);


  return(
    <div>
      <MiniButton type="create" onClick={handleClick} />
      <NewMeetingModal />
      <AddMeetingGuestModal />
      <AddMeetingNoteModal />
      <AddMeetingViewModal />
      <AnnotationModal />
    </div>
  );
};