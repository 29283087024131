import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOrgMarker } from "../../../../actions";
import { FormatDate, ValueAContainsB } from "../../../../helpers/FormatHelper";
import { ListWidgetItem } from "../../../ListWidgetItem";

export const MarkerList = ({
  list,
  searchFilter,
  type,
  AddItemToSelection,
  RemoveItemFromSelected,
  isSelectionList,
}) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const goToMarker = (markerId) => {
    dispatch(getOrgMarker(markerId)).then(history.push(`/markers/${markerId}/projects`));
  };

  return (
    <div>
      {
        list.length !== 0 ? (
          list.map((item, index) => ValueAContainsB({ valueA: item.name,  valueB: searchFilter}) && (
            <ListWidgetItem
              type={type}
              key={index}
              itemId={item.id}
              itemName={item.name}
              subText={item.color}
              description={item.isStandard? "Anotación Estandar" : "Anotación Personalizada"}
              creationDate={FormatDate(item.createdAt)}
              creando={isSelectionList}
              goToMarker={goToMarker}
              funcionElegido={AddItemToSelection}
              funcionDescartado={RemoveItemFromSelected} />
            )
          )
        ) : ''
      }
    </div>
  );
};
