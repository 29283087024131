import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  closeAll,
  getProjects,
  getProjectsAssigned,
  getUsers,
  selectProject,
  setProjectModal,
} from '../actions/';
import { PARAMS, ROUTES } from '../constants';
import { Button } from './Button';
import { ListItem } from './ListItem';
import { NewProjectModal } from './modals';
import './styles/List.css';

const mapStateToProps = (state) => {
  return {
    currentUser: state.UserReducer.currentUser,
    projects: state.ProjectReducer.projects,
    users: state.UserReducer.indexedUsers,
  };
};

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.renderContent = this.renderContent.bind(this);
    this.SetProjectList = this.SetProjectList.bind(this);
    this.openModal = this.openModal.bind(this);
    this.renderOpenModalButton = this.renderOpenModalButton.bind(this);
  }

  componentDidMount() {
    this.SetProjectList();
  }

  SetProjectList() {
    if (this.props.targetUser) {
      this.props.dispatch(getProjectsAssigned(this.props.targetUser.id));
      return;
    }
    this.props.dispatch(getProjects());
    if (Object.keys(this.props.users).length === 0) {
      this.props.dispatch(getUsers());
    }
  }

  openModal() {
    this.props.dispatch(setProjectModal(true));
  }

  goToProject(projectIndex) {
    this.props.dispatch(selectProject(projectIndex)).then(() => {
      this.props.history.push(
        ROUTES.PROJECT_PAGE.replace(
          PARAMS.PROJECT_ID,
          this.props.projects[projectIndex].id
        )
      );
      this.props.dispatch(closeAll(true));
    });
  }

  renderContent() {
    var { projects, users } = this.props;
    projects = [{ name: 'test', description: 'test' }];
    return (
      <div className="list-content">
        {projects &&
          projects.length > 0 &&
          projects.map((project, index) => (
            <ListItem key={index} className="project">
              <div className="project-logo"></div>
              <div className="project-data">
                <div className="project-name">{project.name}</div>
                <div className="project-context">
                  <span>
                    <b> Created At: </b> {project.createdAt?.split('T')[0]}
                  </span>
                  &nbsp;
                  <span>
                    <b> Admin: </b> {users[project.MantainerId]?.username}
                  </span>
                </div>
                <div className="project-description">{project.description}</div>
              </div>
              <Button
                className="move-right"
                onClick={() => {
                  this.goToProject(index);
                }}
              >
                ▶
              </Button>
            </ListItem>
          ))}
      </div>
    );
  }

  renderOpenModalButton() {
    // prevent the current user from opening this modal if the circunstances don't allow it
    if (
      !this.props.currentUser ||
      // this.props.currentUser.orgUser.type !== USER_TYPE.ADMIN ||
      this.props.targetUser
    ) {
      return;
    }
    return (
      <Button className="list-button move-right" onClick={this.openModal}>
        + Add Project
      </Button>
    );
  }

  render() {
    return (
      <div className="list-container">
        <div className="list-header">
          <h3>
            {this.props.targetUser
              ? `Projects Assigned to ${this.props.targetUser.username}`
              : 'Projects'}
          </h3>
          <span className="left-line">
            Total Projects: {this.props.projects.length}
          </span>
          {this.renderOpenModalButton()}
        </div>
        {this.renderContent()}
        <NewProjectModal />
      </div>
    );
  }
}

const ConnectedProjectList = connect(mapStateToProps)(ProjectList);

const ProjectListWithRouter = withRouter(ConnectedProjectList);

export { ProjectListWithRouter as ProjectList };
