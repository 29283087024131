import { React, useEffect, useState } from "react";
import "./styles/ListWidget.css";
import "./styles/List.css";
import { MiniButton, ListWidgetItem, AnnotationItem } from "../components";
import { useHistory } from "react-router-dom";
import {
  setAddMeetingGuestModal,
  setAddMeetingViewModal,
  setAnnotationModal,
  setAddMeetingNoteModal,
  setGetMeetingModal,
} from "../actions";
import { useDispatch } from "react-redux";

import { ReactComponent as Markers } from "../routes/svg/icono_anotaciones.svg";
import { ReactComponent as Guests } from "../routes/svg/icono_invitado.svg";
import { ReactComponent as Meetings } from "../routes/svg/icono_reuniones_virtuales.svg";
import { ReactComponent as ThreeDView } from "../routes/svg/icono_vistas_3D.svg";
import { ReactComponent as Projects } from "../routes/svg/icono_proyectos.svg";
import { ReactComponent as GroupedMarkers } from "../routes/svg/icono_grupo_anotaciones.svg";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";

const CONTENT = {
  PROJECTS: {
    TITLE: "PROYECTOS",
    ICON: <Projects />,
  },
  MEETINGS: {
    TITLE: "REUNIONES VIRTUALES",
    ICON: <Meetings />,
  },
  VIEW_3D: {
    TITLE: "VISTAS 3D",
    ICON: <ThreeDView />,
  },
  MARKERS: {
    TITLE: "ANOTACIONES",
    ICON: <Markers />,
  },
  GROUPED_MARKERS: {
    TITLE: "GRUPO ANOTACIONES",
    ICON: <GroupedMarkers />,
  },
  GUESTS: {
    TITLE: "INVITADOS",
    ICON: <Guests />,
  },
};

export const EditListWidget = ({
  content,
  finished,
  get,
  isMeeting,
  annInstances,
  creatingMeeting = true,
}) => {
  const dispatch = useDispatch();

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(content.content ?? []);
  }, [content.content]);

  const handleAddItem = () => {
    if (content.type === "GUESTS") {
      dispatch(setAddMeetingGuestModal(true));
    } else if (content.type === LIST_WIDGET_CONTENT_TYPE.VIEW_3D) {
      dispatch(setAddMeetingViewModal(true));
    } else if (content.type === LIST_WIDGET_CONTENT_TYPE.MARKERS) {
      // dispatch(setAnnotationModal(true));
      dispatch(setAddMeetingNoteModal(true));
    }
  };

  // eslint-disable-next-line no-unused-vars
  const openAnnotationModal = () => {
    dispatch(setGetMeetingModal(false));
    dispatch(setAnnotationModal(true));
  }

  const displayInfo = () =>{
    // por implementar
  };
  const handleSearch = () => {
    // por implementar
  };
  const handleOrder = () => {
    // por implementar
  };
  const handleFilter = () => {
    // por implementar
  };

  let history = useHistory();
  const goTo = () => {
    history.push("./project-selected");
  };

  var listContent = "list-content-3-item";
  listContent += finished ? "-green" : "";

  var idFinished = finished ? "green" : "";

  return (
    <div className="list-container">
      <div className="list-header" id={idFinished}>
        <div className="list-info">
          <h3>{CONTENT[content.type].TITLE}</h3>
          <span className="left-line">{CONTENT[content.type].ICON}</span>
          <span> {list.length} </span>
          {!get && (
            <span className="middle-actions">
              <MiniButton id="clip" type="clip" onClick={handleAddItem} />
              <MiniButton type="circle_question_mark" onClick={displayInfo}/>
            </span>
          )}
        </div>
        <div className="right-list-actions">
          <MiniButton type="loupe" onClick={handleSearch} />
          <MiniButton type="up_down_arrow" onClick={handleOrder} />
          <MiniButton type="filter" onClick={handleFilter} />
        </div>
      </div>
      <div className={listContent}>
        {content.type === LIST_WIDGET_CONTENT_TYPE.VIEW_3D
          ? list.map((item, index) => (
              <ListWidgetItem
                type={content.type}
                key={index}
                itemId={item.id}
                description={item.View3D.fileName}
                creationDate={item.createdAt.substring(0, 10)}
                onClick={goTo}
                createReu={true}
                finished={finished}
                get={get}
                isMeeting={isMeeting}
              />
            ))
          : (content.type === LIST_WIDGET_CONTENT_TYPE.MARKERS) & creatingMeeting
          ? list.map((item, index) => (
              <ListWidgetItem
                type={content.type}
                key={index}
                itemId={item.id}
                itemName={item.name}
                subText={item.color}
                description={
                  item.isStandard
                    ? "Anotación Estandar"
                    : "Anotación Personalizada"
                }
                creationDate={item.createdAt ? item.createdAt.substring(0, 10) : item.creationDate}
                get={get}
                isMeeting={isMeeting}
              />
            ))
          : content.type === LIST_WIDGET_CONTENT_TYPE.MARKERS
          ?
          list.map((item, index) => (
              <AnnotationItem
                type={content.type}
                key={index}
                itemId={item.id}
                itemName={item.name}
                subText={item.color}
                description={"Anotación Estandar"}
                creationDate={item.createdAt ? item.createdAt.substring(0, 10) : item.creationDate}
                get={get}
                finished={finished}
                isMeeting={isMeeting}
                instances={annInstances}
                annotationColor={item.color}
              />
            ))
          : list.map((item, index) => (
              <ListWidgetItem
                type={content.type}
                key={index}
                itemId={item.id}
                itemName={item.subText}
                subText={item.itemName}
                description={item.description}
                onClick={goTo}
                createReu={true}
                finished={finished}
                get={get}
                isMeeting={isMeeting}
              />
            ))}
      </div>
    </div>
  );
};
