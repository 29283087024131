import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProjectImport, setConfigCategoryMeetingModal, selectProject } from "../../actions";
import { ListWidget } from "..";
import "./styles/Modal.css"
import "./styles/ImportTypesOfMeetingModal.css"
import "./styles/NewMeetingCategoryModal.css"
import { FaTimes } from 'react-icons/fa';
import { ProjectContentType } from "../ListWidget/contentType/ProjectContentType";
import { LIST_WIDGET_CONTENT_TYPE } from "../../constants/ListWidgetContentType";

export const ImportTypesOfMeetingModal = () => {

  const dispatch = useDispatch();

  const isVisible = useSelector((state) => state.ModalReducer.modalImportTypesOfMeeting);
  const selectedProject = useSelector((state) => state.ProjectReducer.selectedProject);
  const projects = useSelector((state) => state.ProjectReducer.projects);
  
  const [isActive, setActive] = useState(true);
  const [projectArray, setProjectArray] = useState([]);

  useEffect(function ResetState() {
    setActive(true);
    setProjectArray([]);
  },[isVisible]);

  const closeModal = () => {
    dispatch(setConfigCategoryMeetingModal(true));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isActive || projectArray.length === 0) return;
    setActive(false);

    const data = {
      projectArray,
    };

    const onSuccess = () => {
      closeModal();
    };
    const onFailure = (error) => {
        console.error(error.fieldErrors);
        setActive(true);
    };
    dispatch(addProjectImport(selectedProject.id, data)).then(onSuccess).catch(onFailure);
  }

  const handleCallback = (newArray) =>{
    setProjectArray(newArray);
  }

  const modalState = `add-modal ${ isVisible ? 'modal-open' : 'modal-closed' }`;

  const otherProjects = projects.filter(({id}) => id !== selectProject.id);
  const IMPORT_CATEGORIES = {
    type: LIST_WIDGET_CONTENT_TYPE.IMPORT_CATEGORIES,
    content: otherProjects ?? [],
    contentType: ProjectContentType,
  };

  return (
    <div className={modalState}>
      <div className="meeting-type-modal-box">
        <div id="MyModal" className="modal-content">
          <button className="closing-x" onClick={closeModal}>
            <FaTimes/>
          </button>
          <h2>IMPORTAR TIPOS DE REUNIÓN</h2>
          <form onSubmit={handleSubmit}>
            <div className="import-meeting-type-bottom">
              <div className="list-container-import-meeting-type">
                <ListWidget className="list-container-category" content={IMPORT_CATEGORIES} parentCallback={handleCallback}/>
              </div>
              <input className="import-meeting-type-button" type="submit" value="SELECCIÓN DEL PROYECTO" disabled={!isActive}/>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};