export const TextField = ({
    key,
    fieldName,
    children,
    className,
    minlength,
    type,
    value,
    placeholder,
    defaultValue,
    onChange,
}) => {
    return(
        <div>
            <label hrmlfor={ fieldName } >
                { children }
            </label >
            <div>
                <br/>
                    <input
                        key={ key }
                        className={ className }
                        required
                        minLength={ minlength }
                        type={ type } 
                        id={ fieldName } 
                        name={ fieldName } 
                        value={ value } 
                        placeholder={ placeholder ?? "" }
                        defaultValue={ defaultValue }
                        onChange={ onChange }/>
                <br/>
            </div>
        </div>
    );
};
