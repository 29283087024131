import React, { Component } from "react";
import { connect } from "react-redux";
import { addProjectMaintainer, setProjectMaintainerModal } from "../../actions";
import { getOrgMaintainers } from "../../actions";
import "./styles/Modal.css"
import "./styles/AddProjectMaintainerModal.css"
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import { Button, ListWidget } from "..";
import { MaintainersContentType } from "../ListWidget/contentType/MaintainersContentType";


const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalProjectMaintainer,
        users: state.UserReducer.users,
        currentUser: state.UserReducer.currentUser,
        selectedProject: state.ProjectReducer.selectedProject,
        orgMaintainers: state.OrganizationReducer.orgMaintainers,
        selectedProjectMaintainers: state.selectedProjectMaintainers,
    };
}

const initialState =  {
    name: '',
    description: '',
    maintainerId: [],
    disabled: false,
};

class AddProjectMaintainerModal extends Component{
    
    constructor(props){
        super(props);
        this.state = initialState;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
        this.setMaintainersList = this.setOrgMaintainers.bind(this);
    }


    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
        this.setOrgMaintainers();
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        var newState = this.state;
        newState[event.target.name] = event.target.value
        this.setState(newState);
    }

    resetState(){
        this.setState(initialState);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled:true});
        const onSuccess = ()=>{
            this.closeModal();
        };
        const onFailure = (error)=>{
            console.error(error.fieldErrors);
            this.setState({...this.state, disabled:false});
        };
        let maintainerId ={'maintainerId': this.state['maintainerId']};
        this.props.dispatch(addProjectMaintainer(this.props.selectedProject.id, maintainerId)).then(onSuccess).catch(onFailure);
    }

    closeModal(){
        this.props.dispatch(setProjectMaintainerModal(false)).then(this.resetState);
    }

    setOrgMaintainers(){
        this.props.dispatch(getOrgMaintainers());
    }

    // Falta implementar
    onClickOutside(event) {
        if (this.state.disabled && this.modal.contains(event.target)) return;
        this.closeModal();
    };

    handleCallback = (childData) =>{
        this.setState({maintainerId: childData});
    }

    render(){
        var modalState = 'add-modal ';
        modalState += this.props.isVisible? 'modal-open':'modal-closed';

        const {selectedProject} = this.props;

        const ADMINISTRADORES = {
            isSelectionList: true,
            content: this.props.orgMaintainers,
            contentType: MaintainersContentType
          };


        return(
                <div className={modalState}>
                    <div className="project-maintainer-modal">
                        <div id="MyModal" className="modal-content">
                            <Button className="modal-button" onClick={this.closeModal}>
                                <Close/>
                            </Button>
                            <div className="project-maintainer-header">
                                <div className="project-logo"></div>
                                <div className="project-data">
                                    <div className="project-name">{selectedProject? (selectedProject.name):("No name")}</div>
                                    <div className="project-context">
                                        <span> Creada el:<b> {selectedProject && selectedProject.createdAt ? (selectedProject.createdAt.substring(0,10)):("No Date")} </b></span>
                                        &nbsp;
                                        <span> Nombre del Proyecto: <b>{selectedProject? (selectedProject.name):("No Admin")}</b>{" "}</span>
                                    </div>
                                    <div className="project-description">
                                        {selectedProject? (selectedProject.description):("No description")}
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                
                                <div className="project-maintainer-list-content">
                                    <ListWidget content={ADMINISTRADORES} parentCallback={this.handleCallback}/>
                                </div>
                                <div className="add-maintainer-button-box">
                                    <input className="add-maintainer-button" type="submit" value="AGREGAR ADMINISTRADOR" disabled={this.state.disabled}/>
                                </div>
                            </form> 
                    </div>
                   </div>
               </div>
        )
    }
}

const connectedAddProjectMaintainerModal = connect(mapStateToProps)(AddProjectMaintainerModal);

export { connectedAddProjectMaintainerModal as AddProjectMaintainerModal };