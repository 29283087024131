import { API_ROUTES } from "../constants";

class OrganizationService {
    static async getOrganization(){
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        return fetch( `${API_ROUTES.ORG_INFO}`, {
            method: 'GET',
            credentials: 'include',
            headers
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.org;
            }
            // handle failure
            throw new Error(resJson.message);
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async updateOrganization(orgData){
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        return fetch( `${API_ROUTES.ORG_INFO}`, {
            method: 'PUT',
            credentials: 'include',
            headers,
            body: JSON.stringify(orgData)
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.org;
            }
            // handle failure
            throw new Error(resJson.message);
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }
    
    static async deleteOrg() {
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        return fetch( API_ROUTES.ORG_INFO, {
            method: 'DELETE',
            credentials: 'include',
            headers
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.message;
            }
            // handle failure
            throw resJson.message;
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }


    static async getOrgUsers(){
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        return fetch( `${API_ROUTES.ORG_USERS}`, {
            method: 'GET',
            credentials: 'include',
            headers
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson;
            }
            // handle failure
            throw new Error(resJson.message);
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async newMantainer(mantainerData) {
        return fetch(API_ROUTES.NEW_MANTAINER, {
            method: 'POST',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(mantainerData)
        }).then(async res => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson.message;
            }
            throw resJson.message;
        }).catch(err => {
            throw(err)
        })
    }

    static async newGuest(guestData) {
        return fetch(API_ROUTES.NEW_GUEST, {
            method: 'POST',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(guestData)
        }).then(async res => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson.message;
            }
            throw resJson.message;
        }).catch(err => {
            throw(err)
        })
    }

    static async getOrgMarkers(){
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        };
        return fetch( `${API_ROUTES.ORG_MARKERS}`, {
            method: 'GET',
            credentials: 'include',
            headers
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.annotationTypes;
            }
            // handle failure
            throw new Error(resJson.message);
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }

    static async getAnnotationType(annotationTypeId){
        return fetch( `${API_ROUTES.ORG_MARKERS}/${annotationTypeId}`, {
            method: 'GET',
            credentials: 'include',
        }).then(async res => {
            const resJson = await res.json();
            // handle success
            if (res.status === 200) {
                return resJson.annotationType;
            }
            // handle failure
            throw new Error(resJson.message);
        }).catch(err => {
            console.error('Caught error: ', err);
            throw err;
        })
    }
}

export {OrganizationService};