import React from "react";
import { Links } from "../components/Links";
import { useSelector } from "react-redux";
import { ROUTES } from "../constants";
import { MarkerSection } from "../components/MarkerSection";

import "./styles/NotesDetail.css";
import "./styles/Home.css"
import { useParams } from "react-router-dom";

export const Marker = () => {

  const { markerId } = useParams()
  
  const selectedAnnotationType = useSelector((state) => state.OrganizationReducer.selectedAnnotationType);

  const markerName = selectedAnnotationType ? selectedAnnotationType.name : `Marker ${markerId}`;

  
  const links = [
      {
          link: ROUTES.MARKERS,
          name: 'Anotaciones'
      },
      {
          link:'#',
          name:markerName
      }
  ]
  return(
      <div className='main-content'>
          <Links links={links} />  
          <MarkerSection selectedAnnotationType={selectedAnnotationType} />
      </div>
  )
};
