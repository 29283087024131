import { LIST_WIDGET_CONTENT_TYPE } from "../../../constants/ListWidgetContentType";
import { MaintainerActions } from "./actions/MaintainersActions";
import { MaintainerList } from "./lists/MaintainerList";
import { ReactComponent as Guests } from '../../../routes/svg/icono_invitado.svg';

export const MaintainersContentType = {
  type: LIST_WIDGET_CONTENT_TYPE.MAINTAINERS,
  RenderActions: MaintainerActions,
  RenderList: MaintainerList,
  RenderTitle: () => ("ADMINISTRADORES DE PROYECTO"),
  RenderIcon: () => (<Guests />),
};
