import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGetMeetingModal, resetInstance } from "../../actions";
import "./styles/Modal.css"
import "./styles/NewMeetingModal.css"
import "./styles/AnnotationModal.css"
import "../styles/ListMarker.css"
import { ReactComponent as MeetingDetails } from "../../routes/svg/icono_reunión_virtual.svg";
import { ReactComponent as View3D } from "../../routes/svg/icono_vistas_3D.svg";
import { ReactComponent as Marker } from "../../routes/svg/icono_anotaciones.svg";
import Marker2 from "../../routes/svg/icono_anotaciones.svg";
import { ReactComponent as Guest } from "../../routes/svg/icono_invitado.svg";
import { ReactComponent as Arrow } from "../../routes/svg/icono_flecha_lineal.svg";
import { API_ROUTES, USER_TYPE } from "../../constants";

export const AnnotationModal = ({
  putMargin,
}) => {

  const dispatch = useDispatch();

  const isVisible = useSelector((state) => state.ModalReducer.annotationModal);
  const meeting = useSelector((state) => state.ProjectReducer.selectedProjectMeeting);
  const instance = useSelector((state) => state.MarkerReducer.instanceInfo);
  const currentUser = useSelector((state) => state.UserReducer.orgUser);

  const closeModal = () => {
    dispatch(setGetMeetingModal(true));
    dispatch(resetInstance(true));
  }

  const RenderAudio = () => {
    if (!meeting || !instance || instance.audioFileKey === '') return "";
    return (
      <audio controls>
        <source src={`${API_ROUTES.PROJECTS}/${meeting.Project.id}/meetings/${meeting.id}/annotations/${instance.id}/audio-comment`} type="audio/mpeg" />
      </audio>
    );
  }

  const RenderScreenShot = () => {
    if (!meeting || !instance || instance.pictureFileKey === '') return "";
    return (
      <img alt="" src={`${API_ROUTES.PROJECTS}/${meeting.Project.id}/meetings/${meeting.id}/annotations/${instance.id}/screen-shot`} />
    )
  }

  if (!meeting || !instance || !isVisible) return (<div />);
  
  const modalState = `annotation-add-modal ${ isVisible ? 'modal-open' : 'modal-closed'} ${ currentUser.accountType === USER_TYPE.GUEST ? 'no-padding' : ''}`;
  const project = meeting.Project;
  const thisInstance = instance ?? {};
  const orgUser = thisInstance.OrgUser ?? {};
  const annotationType = thisInstance.AnnotationType ?? {};

  return(
    <div className={modalState}>
      <div className={`${ putMargin ? 'no-margin' : '' }`}>
        <div>
          <div className="new-category-meeting-modal-box">
            <button id="closing-x" onClick={closeModal}> X </button>
            <div className="project-header-2">
              <div className="project-logo" />
              <div className="project-data">
                <div className="project-name"> { project ? project.name : "" } </div>
                <div className="project-context">
                  <span> Creada el:<b> { project ? project.createdAt.substring(0,10) : "" }</b></span>
                  &nbsp;
                  <span> Nombre del proyecto: <b>{ project ? project.name : "" }</b></span>
                </div>
                <div className="project-description">
                  { project ? project.description : "" }
                </div>
              </div>
            </div>
            <div className="horizontal-flex" style={{'background-color': 'white'}}>
              <div className="meeting-details">
                <div className="meeting-details-child-logos">
                  <MeetingDetails className="green-svg-color"/>
                </div>
                <div className="meeting-details-child-3">
                  <div className="green-color-annotation "> <b>REALIZADA</b> </div> 
                  <div> { meeting.topic } </div>
                </div>
                <div className="meeting-details-child-1">
                  <div> <b className="green-color-annotation">Vistas Usadas 0/</b>{ meeting.VisualReferences.length }</div>
                </div>
                <div className="meeting-details-child-logos">
                  <View3D className="meeting-details-smaller-logo green-svg-color"/>
                </div>
                <div className="meeting-details-line" />
                <div className="meeting-details-child-2">
                  <div> <b className="green-color-annotation" >Anotaciones Usadas 0/</b>{ meeting.AnnotationTypes.length }</div>
                  <div> <b className="green-color-annotation" >Instancias Totales </b>{ meeting.AnnotationInstances.length }</div>
                </div>
                <div className="meeting-details-child-logos">
                  <Marker className="meeting-details-smaller-logo green-svg-color"/>
                </div>
                <div className="meeting-details-line" />
                <div className="meeting-details-child-1">
                  <div> <b className="green-color-annotation" > Asistentes 0/</b>{ meeting.Guests.length }</div>
                </div>
                <div className="meeting-details-child-logos">
                  <Guest className="meeting-details-smaller-logo green-svg-color"/>
                </div>
                <div className="meeting-details-line"></div>
                <div className="meeting-details-child-2">
                  <div>Fecha </div>
                  <div className="green-color-annotation"> 
                    <b> { meeting.date.substring(0, 10) } </b>
                  </div>
                </div>
              </div>
            </div>
            <div className="meeting-annotation-info">
              <div className="meeting-annotation-info-flex">
                <div className="meeting-annotation-horizontal-line" />
                <div className="meeting-annotation-user-audio">
                  <div className="meeting-annotation-user-logo">
                    <Guest className="white-color-svg"/>
                  </div>
                  <div className="meeting-annotation-user-data">
                    <div className="meeting-user-org"> <b> { orgUser.company ?? "" } </b> </div>
                    <div className="meeting-user-name"> { orgUser.alias ?? "" } </div>
                  </div>
                  { RenderAudio() }
                </div>
                <div>
                  { RenderScreenShot() }
                </div>
                <div className="meeting-annotation-user-audio">
                  <div className={`meeting-annotation-user-logo img-${ annotationType.color ?? "yellow" }`}>
                    <img src={Marker2}  width="25" height="25" className="filter no-max" alt=""/>
                  </div>
                  <div className="meeting-annotation-user-data">
                    <div className="meeting-user-org"> <b> { thisInstance.isStandard ? "Anotacíon Estándar" : "Anotación Personalizada" }</b> </div> 
                    <div className="meeting-user-name"> { annotationType.name ?? "" } </div>
                  </div>
                  <div className="meeting-annotation-user-logo">
                    <Arrow className="white-color-svg"/>
                  </div>
                  <div className="annotation-circle-div">
                    <div className={`annotation-circle img-${ annotationType.color ?? "yellow" }`}></div>
                  </div>
                  <div className="justified-for-annotation">
                    <div className="meeting-annotation-name"> { annotationType.name ?? "" } </div>    
                  </div>
                </div>
                <div className="meeting-annotation-horizontal-line-2" />
                <button className="meeting-annotation-close-button" onClick={closeModal}> CERRAR </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
