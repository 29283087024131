import { useDispatch } from "react-redux";
import { MiniButton } from "../../..";
import { MODAL_ACTION } from "../../../../constants";
import { NewMarkerModal } from "../../../modals/NewMarkerModal";

export const OrgMarkersActions = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch({
      type: MODAL_ACTION.SET_MARKER_MODAL,
      payload: true,
    });
  };
  return (
    <div>
      <MiniButton type="create" onClick={handleClick} />
      <NewMarkerModal boolFromOrg={true} />
    </div>
  );
};