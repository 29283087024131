import React from "react";
import { useSelector } from "react-redux";
import { Links } from "../components/Links";
import { ACCESS_TYPE, ROUTES } from "../constants";
import "./styles/Redico.css";
import "./../components/styles/RvipLayout.css";
import {
    MobileView,
    isBrowser,
  } from "react-device-detect";
import { ProjectSection } from "../components";

export const Project = () => {
  const selectedProject = useSelector((state) => state.ProjectReducer.selectedProject);
  
  if (!selectedProject) return(<div />);
  
  const links = [
    {
      link: selectedProject.accessType === ACCESS_TYPE.AR ? ROUTES.PROJECTS_AR : ROUTES.PROJECTS,
      name: selectedProject.accessType === ACCESS_TYPE.AR ? 'Proyectos AR' : 'Proyectos',
    },
    {
      link: '#',
      name: selectedProject.name,
    },
  ]

  if (isBrowser) {
    return(
      <div className='main-content'>
        <Links links={links} />
        <ProjectSection />
      </div>
    )
  }

  return(
    <MobileView>
      <div className='main-content'>
        <ProjectSection />
      </div> 
    </MobileView> 
  )
};
