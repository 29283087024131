import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ListWidget, ListWidgetMobile } from "../components";
import { Links } from "../components/Links";
import { ROUTES } from "../constants";
import { Button } from "../components/Button";
import { getProjects, resetProjectInfo } from "../actions";
import {
  MobileView,
  isBrowser,
} from "react-device-detect";
import { ReactComponent as Projectos } from '../routes/svg/icono_proyectos.svg';
import { ReactComponent as Arrow } from "../routes/svg/icono_flecha.svg";
import "./styles/Redico.css";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";
import { ProjectContentType } from "../components/ListWidget/contentType/ProjectContentType";

const mapStateToProps = (state) => {
  return {
    currentUser: state.UserReducer.currentUser,
    projects: state.ProjectReducer.projects,
  };
};

class Projects extends Component {
  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
    this.SetProjectsList = this.SetProjectsList.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.resetProjectInfo = this.resetProjectInfo.bind(this);
  }
  
  componentDidMount() {
    this.SetProjectsList();
    this.resetProjectInfo();
  }

  resetProjectInfo() {
    this.props.dispatch(resetProjectInfo());
  }

  SetProjectsList() {
    this.props.dispatch(getProjects()).catch((error) => {
      console.log(error);
    });
  }

  goTo(routeName) {
    if (this.props.location.pathname === routeName) return;
    this.props.history.push(routeName);
  }

  componentDidUpdate() {
    this.resetProjectInfo();
  }

  render() {
    const links = [
      {
        link: "#",
        name: "Proyectos",
      },
    ];

    let PROJECTS = {
      type: LIST_WIDGET_CONTENT_TYPE.PROJECTS,
      content: this.props.projects,
      contentType: ProjectContentType,
    };

    return (
      isBrowser ?
      <div className="main-content" selected="Projects">
        <Links links={links} />
        <ListWidget content={PROJECTS} />
      </div>
      :
      <MobileView>
        <div className="main-content" selected="Projects">
          <div className="mobile-title">
            <Projectos />
            PROYECTOS
          </div>
          <ListWidgetMobile content={PROJECTS} />
          <div className="list-container">
            <Button className="move-to-bottom-mobile button-purple" onClick={() => this.goTo(ROUTES.LOGGED_HOME)}>
              <Arrow />
            </Button>
          </div>
        </div>

      </MobileView>
    );
  } 
}

const connectedProjects = connect(mapStateToProps)(Projects);

const ProjectsWithRouter = withRouter(connectedProjects);

export { ProjectsWithRouter as Projects };
