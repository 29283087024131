import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers/index";

function saveToLocalStorage(state) {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
}

function loadFromLocalStorage() {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
       return JSON.parse(serializedState);
    }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeWithDevTools;

const presistedState = loadFromLocalStorage();

const store = createStore(
    rootReducer,
    presistedState,
    composeEnhancers(applyMiddleware(thunkMiddleware)),
 );

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;