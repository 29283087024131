exports.ValueIsNull = (value) => value === null || value === undefined;

exports.ValueIsNullOrEmptySpace = (value) => this.ValueIsNull(value) || value === '';

exports.FormatDate = (date) => {
  if (date) {
    if (date instanceof Date) {
      return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
    }
    return date.split('T')[0].split('-').reverse().join(' / ');
  } else {
    return '';
  }
};

exports.ValueAContainsB = ({valueA, valueB, ignoreCase = true}) => {
  // if (!valueA || !valueB) return false;
  if (ignoreCase) {
    return valueA.toUpperCase()?.includes(valueB.toUpperCase())
  }
  return valueA?.includes(valueB)

}