import { React } from "react";
import { useSelector } from "react-redux";

export const MyOrg = () => {
    const organization = useSelector((state) => state.OrganizationReducer.organization);
    var date;

    if (organization !== undefined && organization !== null){
        const year = organization.expirationDate.substring(0, 4);
        const month = organization.expirationDate.substring(5, 7);
        const day = organization.expirationDate.substring(8, 10);
        date = new Date(year, month, day);
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    }



    return (
        <div className="display-info-user">
                    <br/>
                        <img alt="" src="https://cdn-icons-png.flaticon.com/512/484/484522.png"></img>
                    <br/>
                    <span className="profile-items">Nombre de la Organización</span>
                    <br/>
                    {organization===undefined || organization===null ? "Loading" : organization.name}
                    <br/>
                    <br/>
                    <span className="profile-items">Tipo</span>
                    <br/>
                    { organization===undefined || organization===null ? "Loading" : organization.accountType === "VR" ? "Realidad Virtual" : "Otro tipo de empresa"} 
                    <br/>
                    <br/>
                    <span className="profile-items"> Fecha de pago </span>
                    <br/>
                    { date===undefined ? "Loading" : date.toLocaleDateString('es-ES', options)}
                        
                </div>
    );
  };