import { useEffect } from "react";
import { isBrowser } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { GetProductListAction } from "../actions/ProductsActions";
import { ListWidget } from "../components";
import { Links } from "../components/Links";
import { DownloadsContentType } from "../components/ListWidget/contentType/DownloadsContentType";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";

export const Downloads = () => {

  const dispatch = useDispatch();

  const productList = useSelector((state) => state.ProductsReducer.productList);

  useEffect(function GetProductList() {
    dispatch(GetProductListAction());
  }, [dispatch]);

  const links = [
    {
      link: "#",
      name: "Descargas",
    },
  ];

  const Downloads = {
    type: LIST_WIDGET_CONTENT_TYPE.DOWNLOADS,
    content: productList,
    contentType: DownloadsContentType,
  }

  if (!isBrowser) {
    // To-Do: Return mobile view.
  }

  return (
    <div className="main-content">
      <Links links={links} />
      <ListWidget content={Downloads} />
    </div>
  );
}