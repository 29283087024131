import { ValueAContainsB } from "../../../../helpers/FormatHelper";
import { MaintainerListItem } from "./items/MaintainerListItem";

export const MaintainerList = ({
  list,
  searchFilter,
  AddItemToSelection,
  RemoveItemFromSelected,
  isSelectionList,
}) => {
  return(
    <div>
      {
        list.length !== 0 ? (
          list.map((item, index) => ValueAContainsB({ valueA: item.description,  valueB: searchFilter}) && (
            <MaintainerListItem
              key={index}
              item={ item }
              isSelectionList={isSelectionList}
              onSelected={AddItemToSelection}
              onDeselected={RemoveItemFromSelected} />
            )
          )
        ) : (
          <div></div>
        )
      }
    </div>
  );
};
