import { ANNOTATION_ACTIONS } from "../constants";
import { AnnotationService } from "../services";

export const addAnnotation = (projectId, annotationData) => async (dispatch) => {
    try {
        const annotation = await AnnotationService.addAnnotation(projectId, annotationData);
        if (!annotation) {
            throw new Error("annotation not created.")
        }
        dispatch({
            type: ANNOTATION_ACTIONS.ADD_ANNOTATION,
            payload: annotation
        })
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const addAnnotationStandar = (annotationData) => async (dispatch) => {
    try {
        const annotation = await AnnotationService.addAnnotationStandar(annotationData);
        if (!annotation) {
            throw new Error("annotation not created.")
        }
        dispatch({
            type: ANNOTATION_ACTIONS.ADD_ANNOTATION_STANDAR,
            payload: annotation
        })
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getAnnotationInstance = (projectId, meetingId, instanceId) => async (dispatch) => {
    try {
        const instance = await AnnotationService.getAnnotationInstance(projectId, meetingId, instanceId);
        dispatch({
            type: ANNOTATION_ACTIONS.GET_INSTANCE_INFO,
            payload: instance
        })
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getAnnotationAudio = (projectId, meetingId, instanceId) => async (dispatch) => {
    try {
        const audio = await AnnotationService.getAnnotationAudio(projectId, meetingId, instanceId);
        dispatch({
            type: ANNOTATION_ACTIONS.GET_INSTANCE_AUDIO,
            payload: audio
        })
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const resetInstance = () => async (dispatch) => {
    dispatch({
        type: ANNOTATION_ACTIONS.RESET_INSTANCE,
        payload: null
    })
}