import { ListWidgetItem } from "../../ListWidgetItem";
import { LIST_WIDGET_CONTENT_TYPE } from "../../../constants/ListWidgetContentType";
import { ReactComponent as Markers } from '../../../routes/svg/icono_anotaciones.svg';
import { ReactComponent as Guests } from '../../../routes/svg/icono_invitado.svg';
import { ReactComponent as Meetings } from '../../../routes/svg/icono_reuniones_virtuales.svg';
import { ReactComponent as ThreeDView } from '../../../routes/svg/icono_vistas_3D.svg';
import { ReactComponent as GroupedMarkers } from '../../../routes/svg/icono_grupo_anotaciones.svg';
import { ReactComponent as Question } from '../../../routes/svg/icono_consulta.svg';

const RenderList = function ({
  list, 
  searchFilter, 
  type, 
  AddItemToSelection, 
  RemoveItemFromSelected, 
  isSelectionList}) {

  return(
    <div>
      {
        list.map(
          (item, index) => (
            <ListWidgetItem
              type={type}
              key={index}
              itemId={item.id}
              itemName={item.name ?? item.itemName}
              subText={item.OrganizationId}
              creationDate={item.creationDate ? item.creationDate : item.createdAt ? item.createdAt.substring(0, 10) : 'Missing Date'}
              description={item.description}
              importing={type === LIST_WIDGET_CONTENT_TYPE.IMPORT_CATEGORIES}
              funcionElegido={AddItemToSelection}
              funcionDescartado={RemoveItemFromSelected}
              creando={isSelectionList}
            />
          )
        )
      }
    </div>
  );
};

const CONTENT = {
  PROJECTS: {
    TITLE: 'PROYECTOS',
  },
  PROJECTS_MAN: {
    TITLE: 'PROYECTOS DEL USUARIO',
  },
  IMPORT_CATEGORIES: {
    TITLE: 'PROYECTOS',
  },
  [LIST_WIDGET_CONTENT_TYPE.MEETINGS]: {
    TITLE: 'REUNIONES VIRTUALES',
    ICON: <Meetings />,
  },
  [LIST_WIDGET_CONTENT_TYPE.ANN_MEETINGS]: {
    TITLE: 'REUNIONES VIRTUALES',
    ICON: <Meetings />,
  },
  VIEW_3D: {
    TITLE: 'VISTAS 3D',
    ICON: <ThreeDView />,
  },
  MARKERS: {
    TITLE: 'ANOTACIONES',
    ICON: <Markers />,
  },
  GROUPED_MARKERS: {
    TITLE: 'GRUPO ANOTACIONES',
    ICON: <GroupedMarkers />,
  },
  GUESTS: {
    TITLE: 'INVITADOS',
    ICON: <Guests />,
  },
  AUTHORS: {
    TITLE: 'AUTORES',
    ICON: <Guests />,
  },
  MEETING_CATEGORIES: {
    TITLE: 'TIPOS DE REUNION',
    ICON: <Meetings />,
  },
};

export const GenericContentType = {
  RenderActions: () => (<div />),
  RenderList,
  RenderTitle: ({ type }) => (CONTENT[type] ? CONTENT[type].TITLE : "No Title" ),
  RenderIcon: ({ type }) => (CONTENT[type] ? CONTENT[type].ICON : <Question />),
  RenderSettings: () => (<div />),
  ItemOptions: () => (<div />),
};
