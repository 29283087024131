import { useDispatch, useSelector } from "react-redux"
import { setDataModal } from "../../../../actions";
import { USER_TYPE } from "../../../../constants";
import { MiniButton } from "../../../MiniButton";
import { DataModal } from "../../../modals/DataModal";
import { AttachedFileDeleteModal } from "../../../modals/AttachedFileDeleteModal";

export const DataActions = () => {

  const dispatch = useDispatch();
  const orgUser = useSelector((state) => state.UserReducer.orgUser);
  const openModal = () => {
    dispatch(setDataModal(true));
  };


  return(
    <div>
      {
        orgUser.accountType !== USER_TYPE.GUEST ? (
          <div>
            <MiniButton type="create" onClick={openModal} />
            <DataModal />
            <AttachedFileDeleteModal />
          </div>
        ) : (
        <div />
        )
      }
    </div>
  )
}