import { React, useState, useRef, useEffect } from 'react';
import './styles/ProjectSection.css';
import { ReactComponent as IconEstandardNotes } from '../routes/svg/icono_anotacione_estandar.svg';
import { ListWidgetContainer } from './ListWidgetContainer';
import { NotesSidePanel } from './NotesSidePanel';
import './styles/Header.css';
import '../routes/styles/NotesDetail.css';
import { LIST_WIDGET_CONTENT_TYPE } from '../constants/ListWidgetContentType';
import { ViewsContentType } from './ListWidget/contentType/ViewsContentType';
import { MemberContentType } from './ListWidget/contentType/MemberContentType';
import { AnnotationMeetingContentType } from './ListWidget/contentType/AnnotationMeetingContentType';
import { AnnotationProjectContentType } from './ListWidget/contentType/AnnotationProjectContentType';
import { MeetingModal } from './modals';
import { useDispatch } from 'react-redux';
import { setMeetingModal } from '../actions';

export const ANNOTATION_SECTION_VIEW_TYPE = {
  MEETING_LIST: 'annmeetings',
  VIEW_LIST: '3d-views',
  AUTHOR_LIST: 'authors',
  PROJECT_LIST: 'projects',
};

export const MarkerSection = ({ selectedAnnotationType }) => {
  
  const ref = useRef();
  const dispatch = useDispatch();

  const [isActive, setActive] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [view, setView] = useState(ANNOTATION_SECTION_VIEW_TYPE.PROJECT_LIST);

  useEffect(function resetModals() {
    dispatch(setMeetingModal(false));
  }, [dispatch])

  // No funciona todavia
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (isActive && ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [isActive]);

  useEffect(function setProjectListView() {
    if (view !== ANNOTATION_SECTION_VIEW_TYPE.PROJECT_LIST) return;
    setWidgets([
      {
        type: LIST_WIDGET_CONTENT_TYPE.PROJECTS,
        content: selectedAnnotationType.Projects ?? [],
        contentType: AnnotationProjectContentType
      },
    ]);
  }, [selectedAnnotationType.Projects, view]);

  useEffect(function setMeetingListView() {
    if (view !== ANNOTATION_SECTION_VIEW_TYPE.MEETING_LIST) return;
    setWidgets([
      {
        type: LIST_WIDGET_CONTENT_TYPE.ANN_MEETINGS,
        content: selectedAnnotationType.Meetings ?? [],
        contentType: AnnotationMeetingContentType,
      },
    ]);
  }, [selectedAnnotationType.Meetings, view]);

  useEffect(function setViewListView() {
    if (view !== ANNOTATION_SECTION_VIEW_TYPE.VIEW_LIST) return;
    setWidgets([
      {
        type: LIST_WIDGET_CONTENT_TYPE.VIEW_3D,
        content: selectedAnnotationType.VisualReferences ?? [],
        contentType: ViewsContentType,
      },
    ]);
  }, [selectedAnnotationType.VisualReferences, view]);

  useEffect(function setAuthorListView() {
    if (view !== ANNOTATION_SECTION_VIEW_TYPE.AUTHOR_LIST) return;
    setWidgets([
      {
        type: 'AUTHORS',
        content: selectedAnnotationType.Authors ?? [],
        contentType: MemberContentType,
      },
    ]);
  }, [selectedAnnotationType.Authors, view]);

  const handleChangeView = (view) => {
    switch (view) {
      case ANNOTATION_SECTION_VIEW_TYPE.MEETING_LIST:
      case ANNOTATION_SECTION_VIEW_TYPE.VIEW_LIST:
      case ANNOTATION_SECTION_VIEW_TYPE.AUTHOR_LIST:
        setView(view);
        break;
      case ANNOTATION_SECTION_VIEW_TYPE.PROJECT_LIST:
      default:
        setView(ANNOTATION_SECTION_VIEW_TYPE.PROJECT_LIST);
        break;
    }
  };

  const color =  selectedAnnotationType ? selectedAnnotationType.color : '' ;

  return (
    <div className="project-page">
      <div className={`Top-container ${ color }`}>
        <IconEstandardNotes className={`Note-figure ${ color }`} />
        <div>
          <span className={`Note-group ${ color }`}>{selectedAnnotationType && selectedAnnotationType.isStandard ? 'ANOTACIÓN ESTÁNDAR' : 'ANOTACIÓN PERSONALIZADA'}</span>
          <span className="Date-tag">Fecha:</span>
        </div>
        <div className="Note-info">
          <span className="Note-title">
            {selectedAnnotationType && selectedAnnotationType.name
              ? selectedAnnotationType.name
              : 'No Name'}
          </span>
          <span className="Date">
            {selectedAnnotationType && selectedAnnotationType.createdAt
              ? selectedAnnotationType.createdAt.substring(0, 10)
              : 'DD/MM/AAAA'}
          </span>
        </div>
      </div>
      <MeetingModal  />
      <div className="project-content">
        <NotesSidePanel handleChangeView={handleChangeView} color={color} />
        <ListWidgetContainer widgets={widgets} color={color} />
      </div>
    </div>
  );
};
