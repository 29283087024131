import { useDispatch, useSelector } from "react-redux"
import { AddProjectMaintainerModal, MiniButton } from "../../..";
import { setProjectMaintainerModal } from "../../../../actions";
import { USER_TYPE } from "../../../../constants";

export function MaintainerActions () {

  const dispatch = useDispatch();
  const orgUser = useSelector((state) => state.UserReducer.orgUser);
  const openModal = () => {
    dispatch(setProjectMaintainerModal(true));
  };


  return(
    <div>
      {
        orgUser.accountType === USER_TYPE.ADMIN ? (
          <div>
            <MiniButton type="create" onClick={openModal} />
            <AddProjectMaintainerModal />
          </div>
        ) : (
        <></>
        )
      }
    </div>
  )
}