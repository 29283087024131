import { ValueAContainsB } from '../../../helpers/FormatHelper';
import { ReactComponent as DownloadIcon } from '../../Icons/download-svgrepo-com.svg';
import { ProductListItem } from './lists/items/ProductListItem';


const RenderList = function ({ list, searchFilter }) {

  return(
    <div>
      {
        list.map(
          (item, index) =>  ValueAContainsB({ valueA: item.name,  valueB: searchFilter}) && (
            <ProductListItem 
              key={ index } 
              name={ item.name ?? "NO NAME" }
              description={ item.description ?? "NO DESCRIPTION" }
              type={ item.type }
              fileKey={ item.fileKey }
            />
          )
        )
      }
    </div>
  );
};


export const DownloadsContentType = {
  RenderTitle: () => "Descargas",
  RenderIcon: () => <DownloadIcon />,
  RenderList,
}