import React from "react";

import { Links } from "../components/Links";
import { ProjectSection } from "../components/ProjectSection";
import "./styles/Redico.css";

export const ProjectSelected = () => {

  const links = [
    {
      link: "/projects",
      name: "Proyectos",
    },
    {
      link: "#",
      name: "Edificio Arq Rodó",
    },
  ];

  return (
    <div className="main-content">
      <Links links={links} />
      <ProjectSection />
    </div>
  );
};