import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProjectView, setDeleteViewModal, setMeetingModal } from "../../actions"
import { Button } from "../Button";
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import "./styles/Modal.css"
import { ViewListItem } from "../ListWidget/contentType/lists/items/ViewListItem";

export const DeleteConfirmModalView = ({
  view
}) => {

  const dispatch = useDispatch();

  const isVisible = useSelector((state) => state.ModalReducer.modalDeleteView);
  const selectedViewId = useSelector((state) => state.ModalReducer.modalViewId);
  const selectedProject = useSelector((state) => state.ProjectReducer.selectedProject);

  const [disabled, setDisabled] = useState(false);

  useEffect(function resetState() {
    if (!isVisible) setDisabled(false);
  }, [isVisible])

  const closeModal = () => {
    dispatch(setMeetingModal(false)).then(setDisabled(false));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (disabled || !selectedProject) return;
    setDisabled(true);
    dispatch(deleteProjectView(selectedProject.id, view.View3DId, view.id)).then(()=>{
      dispatch(setDeleteViewModal(true, -1));
      setDisabled(false);
    }).catch(()=>{
      setDisabled(false);
    })
  }
  
  return(
    <div className={`modal-fixed modal-${ isVisible && selectedViewId === view.id ? 'open' : 'closed' } `}>
      <div className="delete-modal">
        <Button className="modal-button" onClick={closeModal}>
          <Close/>
        </Button>
        <div className="modal-selectedProject">
          <form onSubmit={handleSubmit}>
            <div className='modal-2-title'>
              <div className="modal-avisar">
                Está seguro que quiere eliminar:
                <br/>
              </div>
            </div>

            <div>
              <ViewListItem
                view={view}
                deleting={true}
              />
            </div>
            <div className="modal-avisar-2">
              Este proceso eliminara la vista 3D y no tiene vuelta atras.
            </div>
            <div className="button-div-2">
              <Button className="purple-button-2" onClick={closeModal}>
                NO ELIMINAR
              </Button>
              <input className="delete-proyecto-button" type="submit" value="ELIMINAR VISTA 3D" disabled={disabled}/>
            </div>
          </form> 
        </div>
      </div>
    </div>
  )
} 