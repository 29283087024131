import { React, useState } from "react";
import "./styles/ListWidget.css";
import "./styles/List.css";
import "./styles/ListMarker.css";
import "./styles/ProjectSection.css";
import "./styles/Meetings.css";
import { ReactComponent as DownArrow } from "../routes/svg/icono_flecha_circular.svg";
import { ReactComponent as Meeting } from "../routes/svg/icono_reunión_virtual.svg";
import { ReactComponent as View3D } from "../routes/svg/icono_vista_3D.svg";
import { ReactComponent as Marker } from "../routes/svg/icono_anotacione_estandar.svg";
import { ReactComponent as Guest } from "../routes/svg/icono_invitado.svg";
import icono_marker from "../routes/svg/icono_anotaciones.svg";

import { Button } from "./Button";

import { AnnotationInstanceItem } from "./AnnotationInstanceItem";

const CONTENT = {
  PROJECTS: {
    BTN: "▶",
  },
  PROJECTS_MAN: {
    BTN: "▶",
  },
  MEETINGS: { BTN: "...", ICON: <Meeting /> },
  VIEW_3D: { BTN: "...", ICON: <View3D /> },
  MARKERS: { BTN: <DownArrow />, ICON: <Marker /> },
  GROUPED_MARKERS: {
    BTN: <DownArrow />,
    ICON: <Marker />,
  },
  GUESTS: { BTN: "...", ICON: <Guest /> },
  MAINTAINERS: { BTN: "...", ICON: <Guest /> },
  ADMINISTRADORES: { BTN: "▶", ICON: <Guest /> },
  ELEGIR_ADMINISTRADORES: { BTN: "▶", ICON: <Guest /> },
  MEETING_CATEGORIES: { BTN: <DownArrow />, ICON: <Meeting /> },
  ADMINISTRADORES_LIST: { BTN: "▶", ICON: <Guest /> },
  GUESTS_LIST: { BTN: "...", ICON: <Guest /> },
  MAINTAINERS_LIST: { BTN: "▶", ICON: <Guest /> },
};

export const AnnotationItem = ({
  itemId,
  type,
  itemName,
  subText,
  description,
  finished,
  instances,
  annotationColor,
}) => {

  const [active, setActive] = useState(false);
  let class_item = "list-item-adm project";

  const assignedInstances = instances.content.filter( (element) => {
    return element.AnnotationTypeId === itemId;
  });

  const assignedInstancesCount = assignedInstances.length;

  const toggleShowInstances = () => {
    setActive(!active);
  };

  var idFinished = finished ? "green" : "";

  return (
    <div className="anotation-instances-container">
      <div className={class_item} id={idFinished}>
        <div className="project-left-content">
          <div className={`list-item-icon-marker img-${subText}`}>
            <img src={icono_marker} width="25" height="25" className="filter" alt=""/>
            {/*<Marker fill="yellow"/>*/}
          </div>
          <div className="marker-data">
            <div className={`marker-name ${subText} ${annotationColor}`}>
              {`${description} - ${assignedInstancesCount} ${(assignedInstancesCount > 1) | (assignedInstancesCount === 0) ? "Instancias" : "Instancia"}`}
            </div>
            <div className="marker-context">{/*<span>{subText}</span>*/}</div>
            <div className="marker-description">{itemName}</div>
          </div>
        </div>
        <div className="project-right-content">
          {assignedInstances.length !== 0 ? (
            <Button
              className={`move-right general-button-marker ${subText} filter-${annotationColor}`}
              onClick={toggleShowInstances}
            >
              {CONTENT[type].BTN}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={`instances-container${finished ? "-green" : ""} ${
          active === true ? "" : "hidden"
        }`}
      >
        {assignedInstances.map((item, index) => (
          <AnnotationInstanceItem
            key={index}
            instanceId={item.id}
            instanceType={itemName}
            instanceColor={annotationColor}
            hasImage={item.pictureFileKey !== null && item.pictureFileKey !== ''}
            hasAudio={item.audioFileKey !== null && item.audioFileKey !== ''}
            annotationInstance={item}
            finished={finished} />
        ))}
      </div>
    </div>
  );
};
