import { useDispatch, useSelector } from "react-redux";
import { Button } from "../Button";
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import { ReactComponent as DocIcon } from '../svg/icono_lecciones_aprendidas.svg';

import "./styles/Modal.css"
import "./styles/DataModal.css"
import { setDataModal } from "../../actions";
import { useEffect, useState } from "react";
import { ActResDataForm } from "../forms/ActResDataForm";
import { AdjFileDataForm } from "../forms/AdjFileDataForm";
import { LoadingIcon } from "../Icons/LoadingIcon";

const FILE_TYPE = {
  NONE: -1,
  ACT_RES : 0,
  ADJ_FILE: 1,
  0: 'Actividades y Restricciones',
  1: 'Documento Adjunto',
};

export const DataModal = () => {

  const dispatch = useDispatch();

  const isVisible = useSelector((state) => (state.ModalReducer.modalData));
  const selectedItem = useSelector((state) => (state.ModalReducer.attachedFileModalSelectedItem));

  const [fileType, setFileType] = useState(FILE_TYPE.ACT_RES);
  const [active, setActive] = useState(true);
  //const [viewIdList, setViewIdList] = useState([]);

  useEffect(function ResetState() {
    if (!isVisible) {
      setFileType(FILE_TYPE.NONE);
      return;
    }
    setFileType(FILE_TYPE.ACT_RES);
  }, [isVisible]);

  const closeModal = () => {
    setFileType(FILE_TYPE.ACT_RES);
    setActive(true);
    dispatch(setDataModal(false));
  };
  
  const modalState = `add-modal modal-${isVisible? 'open':'closed'}`;

  return (
    <div className={modalState}>
      <div className="modal-content">
        <Button className="modal-button" onClick={closeModal}>
          <Close/>
        </Button>
        <div className="modal-header">
          <h2>
            {
              selectedItem && selectedItem.id ?
              'Reemplazar Documento Adjunto' :
              'Subir Documento Adjunto'
            }
          </h2>
        </div>
        <div className="modal-body">
          {
            selectedItem && selectedItem.id ?
            (
              <div style={{color: '#5344c1'}}>
                Archivo a reemplazar:
                <div className='list-item attached-file no-under-line'>
                  <div>
                    <DocIcon />
                  </div>
                  <div className='attached-file description'>
                    <h3>
                      { selectedItem.fileName?.replaceAll('_', ' ') }
                    </h3>
                    <p>
                      { `tipo: ${FILE_TYPE[selectedItem.fileType??0]??'Desconocido'}` }
                    </p>
                  </div>
                  <div className='spacer'/>
                </div>
              </div>
            ) :
            ""
          }
          {
            !active ? (
              <div className="overlay"> 
                <LoadingIcon />
              </div>
              ) : (
                ''
              )
          }
          <div className="data-options">
            <div>
              Tipo de Información:
            </div>
            <Button className={`info-type-button ${fileType===FILE_TYPE.ACT_RES?'active':'disabled'}`} onClick={()=>setFileType(0)}>
              Actividades y Restricciones
            </Button>
            <Button className={`info-type-button ${fileType===FILE_TYPE.ADJ_FILE?'active':'disabled'}`}onClick={()=>setFileType(1)}>
              Archivo Adjunto
            </Button>
          </div>
          <div className="data-form">
            { fileType === FILE_TYPE.ACT_RES ? (<ActResDataForm onCancel={closeModal} setActive={setActive} selectedItem={ selectedItem ?? {} }/>) : <div /> }
            { fileType === FILE_TYPE.ADJ_FILE ? (<AdjFileDataForm onCancel={closeModal} setActive={setActive} selectedItem={ selectedItem ?? {} }/>) : <div /> }
          </div>
        </div>
        </div>
    </div>
  )
};
