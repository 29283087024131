import { API_ROUTES } from "../constants";

export const UploadAttachedFile = async (projectId, attachedFile, attachedFileData) => {
  const body = new FormData();
  body.append('attachedFile', attachedFile, 'ActRes.json');
  Object.keys(attachedFileData).forEach(paramName => {
    body.append(paramName, attachedFileData[paramName])
  });
  return fetch(`${API_ROUTES.PROJECTS}/${projectId}/attached-files`, {
    method: "POST",
    credentials: "include",
    body,
  })
    .then(async (res) => {
      const resJson = await res.json();
      // handle success
      if (res.status === 200 || res.status === 201) {
        if(resJson.attachedFile){
            return resJson.attachedFile;
        }
        return resJson;
      }
      // handle failure
      throw new Error(resJson.message);
    })
    .catch((err) => {
      console.error("Caught error: ", err);
      throw err;
    });
};

export const UpdateAttachedFile = async (projectId, attachedFileId, attachedFile, attachedFileData) => {
  const body = new FormData();
  body.append('attachedFile', attachedFile, 'ActRes.json');
  Object.keys(attachedFileData).forEach(paramName => {
    body.append(paramName, attachedFileData[paramName])
  });
  return fetch(`${API_ROUTES.PROJECTS}/${projectId}/attached-files/${attachedFileId}`, {
    method: "PUT",
    credentials: "include",
    body,
  })
    .then(async (res) => {
      const resJson = await res.json();
      // handle success
      if (res.status === 200 || res.status === 201) {
        if(resJson.attachedFile){
            return resJson.attachedFile;
        }
        return resJson;
      }
      // handle failure
      throw new Error(resJson.message);
    })
    .catch((err) => {
      console.error("Caught error: ", err);
      throw err;
    });
};

export const getAttachedFileList = async (projectId) => {
  return fetch(`${API_ROUTES.PROJECTS}/${projectId}/attached-files`, {
    method: "GET",
    credentials: "include",
  })
    .then(async (res) => {
      const resJson = await res.json();
      // handle success
      if (res.status === 200) {
        return resJson.attachedFileList;
      }
      // handle failure
      throw new Error(resJson);
    })
    .catch((err) => {
      console.error("Caught error: ", err);
      throw err;
    });
};

export const DeleteAttachedFile = async (attachedFile) => {
  return fetch(`${API_ROUTES.PROJECTS}/${attachedFile.ProjectId}/attached-files/${attachedFile.id}`, {
    method: "DELETE",
    credentials: "include",
  })
    .then(async (res) => {
      const resJson = await res.json();
      // handle success
      if (res.status === 200) {
        return resJson.attachedFile;
      }
      // handle failure
      throw new Error(resJson);
    })
    .catch((err) => {
      console.error("Caught error: ", err);
      throw err;
    })
};
