import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteProjectMaintainer } from "../../../../../actions";
import { USER_TYPE } from "../../../../../constants";
import { ReactComponent as Guest } from '../../../../../routes/svg/icono_invitado.svg';
import { Button } from "../../../../Button";
import { DeleteProjectMaintainerModal } from "../../../../modals";

export const MaintainerListItem = ({
  item,
  isSelectionList,
  onSelected,
  onDeselected,

}) => {
  
  const dispatch = useDispatch();

  const orgUser = useSelector((state) => state.UserReducer.orgUser);

  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState(false);
  
  const toggle = () => {
    if (selected) {
      setChecked(false);
      onDeselected(item.id);
      setSelected(false);
    } else {
      setChecked(true);
      onSelected(item.id);
      setSelected(true);
    }
  };

  const handleDeleteMaintainer = () => {
    dispatch(setDeleteProjectMaintainer(true, item.id));
  };

  return (
    <div className={`list-item project`}>
      <div className="project-left-content">
        {
          isSelectionList ? (
            <div className="list-item-icon-2">
              <input
                className="elegirAdms"
                readOnly
                checked={ checked }
                type="checkbox"
                onClick={ toggle }
              />
              <Guest />
            </div>
          ) :  (
            <div className={ `list-item-icon` }>
              <Guest />
            </div>
          )
        }
        <div className="project-data">
          <div className="project-name">
            { item.itemName }
          </div>
          <div className={`project-context`}>
            <span>
              { item.subText}
            </span>
          </div>
          <div className="project-description">
            { item.description }
          </div>
        </div>
      </div>
      <div className="project-right-content">
        <div className="date-box-container">
          <div className={`date-box`}>
            <div>
              <div className="date-box-text ">
                <span className="Date-widget-tag">
                  { "Creada el:" }
                </span>
              </div>
              <div className="date-box-date">
                <span className="Date-widget">
                  { item.creationDate }
                </span>
              </div>
            </div>
          </div>
        </div>
        {
          isSelectionList ? (
            ""
          ) : orgUser.accountType === USER_TYPE.ADMIN ? (
            <div>
              <Button className={ "move-right general-button" } onClick={ handleDeleteMaintainer }>
                ...
              </Button>
              <DeleteProjectMaintainerModal
                itemId={ item.id }
                description={ item.description }/>
            </div>
          ) : (
            <div />
          )
        }
      </div>
    </div>
  );
};
