import { useHistory } from "react-router-dom";
import { React, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles/ListWidget.css";
import "./styles/List.css";
import "./styles/ListMarker.css";
import "./styles/ProjectSection.css";
import "./styles/Meetings.css";
import { BiRocket } from "react-icons/bi";
import { ReactComponent as DownArrow } from "../routes/svg/icono_flecha_circular.svg";
import { ReactComponent as Meetings } from "../routes/svg/icono_reuniones_virtuales.svg";
import { ReactComponent as Meeting } from "../routes/svg/icono_reunión_virtual.svg";
import { ReactComponent as View3D } from "../routes/svg/icono_vista_3D.svg";
import { ReactComponent as Marker } from "../routes/svg/icono_anotacione_estandar.svg";
import { ReactComponent as Guest } from "../routes/svg/icono_invitado.svg";
import { ReactComponent as Generic } from "./svg/icono_lecciones_aprendidas.svg";
import { DeleteConfirmModalMeeting } from "./modals/DeleteConfirmModalMeeting";
import {
  getProjectMeeting,
  setDeleteMeeting,
  setGetMeetingModal,
  deleteMeetingListView,
  deleteMeetingListGuest,
  deleteMeetingListNote,
  getProject,
} from "../actions";
import { USER_TYPE } from "../constants";
import icono_marker from "../routes/svg/icono_anotaciones.svg";

import { Button } from "./Button";
import { MeetingModal } from "./modals/MeetingModal";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";

const CONTENT = {
  [LIST_WIDGET_CONTENT_TYPE.MEETINGS]: { BTN: '...', ICON: <Meeting /> },
  [LIST_WIDGET_CONTENT_TYPE.ANN_MEETINGS]: { BTN: '...', ICON: <Meeting /> },
  VIEW_3D: { BTN: '...', ICON: <View3D /> },
  MARKERS: { BTN: '...', ICON: <Marker /> },
  GROUPED_MARKERS: {
    BTN: <DownArrow />,
    ICON: <Marker />,
  },
  GUESTS: { BTN: '...', ICON: <Guest /> },
  AUTHORS: { BTN: '...', ICON: <Guest /> },
  ADMINISTRADORES: { BTN: '▶', ICON: <Guest /> },
  ELEGIR_ADMINISTRADORES: { BTN: '▶', ICON: <Guest /> },
  MEETING_CATEGORIES: { BTN: <DownArrow />, ICON: <Meeting /> },
  ADMINISTRADORES_LIST: { BTN: "▶", ICON: <Guest /> },
  GUESTS_LIST: { BTN: "...", ICON: <Guest /> },
  MAINTAINERS_LIST: { BTN: "▶", ICON: <Guest /> },
  GENERIC: {
    BTN: "...",
    ICON: <Generic />,
  },
};

export const ListWidgetItem = ({
  type,
  itemId,
  itemName,
  subText,
  creationDate,
  description,
  creando,
  eliminando,
  createReu,
  funcionElegido,
  funcionDescartado,
  goToUser,
  finished,
  get,
  importing,
  isMeeting,
  goToMarker,
  fromMeeting,
  guestProject
}) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const history = useHistory();

  // store references
  const orgUser = useSelector((state) => state.UserReducer.orgUser);
  const project = useSelector((state) => state.ProjectReducer.selectedProject);
  const meetings = useSelector((state) => state.MeetingReducer.meetings);

  // state
  const [elegido, setElegido] = useState(false);
  const [checked, setChecked] = useState(false);
  const [importProject, setImportProject] = useState(false);
  const [checkedProject, setCheckedProject] = useState(false);
  const [active, setActive] = useState(false);
  
  let class_item = "list-item project";
  if (
    type === LIST_WIDGET_CONTENT_TYPE.MEETING_CATEGORIES ||
    type === "ADMINISTRADORES_LIST" ||
    type === "MAINTAINERS_LIST" ||
    type === "GUESTS_LIST" ||
    type === LIST_WIDGET_CONTENT_TYPE.MARKERS ||
    createReu
  ) {
    class_item = "list-item-adm project";
  }

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (active && ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [active]);

  const toggle = (index) => {
    if (elegido) {
      setChecked(false);
      funcionDescartado(index);
      setElegido(false);
    } else {
      setChecked(true);
      funcionElegido(index);
      setElegido(true);
    }
  };

  const toggleImportProject = (index) => {
    if (importProject) {
      setCheckedProject(false);
      funcionDescartado(index);
      setImportProject(false);
    } else {
      setCheckedProject(true);
      funcionElegido(index);
      setImportProject(true);
    }
  };

  const toggleMeetingOptions = () => {
    setActive(!active);
  };

  const handleDeleteMeeting = () => {
    if (fromMeeting) {
      const result = meetings.find((x) => x.id === itemId);
      if (result) {
        dispatch(getProject(result.Project.id));
      }
    }
    dispatch(setDeleteMeeting(true, itemId));
    toggleMeetingOptions();
  };

  const handleDeleteMeetingItem = () => {
    if (type === LIST_WIDGET_CONTENT_TYPE.VIEW_3D) {
      // dispatch(deleteMeetingView(meetingId, itemId));
      dispatch(deleteMeetingListView(itemId));
    } else if (type === "GUESTS") {
      // dispatch(deleteMeetingView(meetingId, itemId));
      dispatch(deleteMeetingListGuest(itemId));
    } else if (type === LIST_WIDGET_CONTENT_TYPE.MARKERS) {
      // dispatch(deleteMeetingView(meetingId, itemId));
      dispatch(deleteMeetingListNote(itemId));
    }
  };

  const handleShowMeeting = () => {
    if (orgUser.accountType === USER_TYPE.GUEST) {
      dispatch(getProjectMeeting(guestProject.id, itemId)).then(
        dispatch(setGetMeetingModal(true))
      );
    }
    else if (fromMeeting) {
      const result = meetings.find((x) => x.id === itemId);
      if (result) {
        const projectId = result.Project.id;
        dispatch(getProjectMeeting(projectId, itemId)).then(
          dispatch(setGetMeetingModal(true))
        );
      }
    } else {
      dispatch(getProjectMeeting(project.id, itemId)).then(
        dispatch(setGetMeetingModal(true))
      );
    }
    toggleMeetingOptions();
  };

  var idFinished = finished ? "green" : "";

  let ContentType = CONTENT[type];
  if (!ContentType) ContentType = CONTENT.GENERIC;


  return (
    <div className={`${class_item} ${idFinished}`} id={idFinished}>
      <div className="project-left-content">
        {
          creando ? (
            <div className="list-item-icon-2">
              <input
                className="elegirAdms"
                readOnly
                checked={checked || false}
                type="checkbox"
                onClick={() => toggle(itemId)}
              />
              { type !== LIST_WIDGET_CONTENT_TYPE.MARKERS && ContentType.ICON }
            </div>
          ) : importing ? (
            <div className="list-item-icon-3 round">
              <input
                className="elegirProject"
                readOnly
                checked={checkedProject || false}
                type="checkbox"
                id="checkbox"
                onClick={() => toggleImportProject(itemId)}
              />
              <div className="import-project-logo">
                <BiRocket size={50} />
              </div>
            </div>
          ) : type === LIST_WIDGET_CONTENT_TYPE.MARKERS ? (
            <div className={`list-item-icon-marker img-${subText}`}>
              <img src={icono_marker} width="25" height="25" className="filter" alt=""/>
              {/*<Marker fill="yellow"/>*/}
            </div>
          ) : (
            <div className={`list-item-icon ${idFinished}`}>{ContentType.ICON}</div>
          )
        }
        {
        type === "ADMINISTRADORES_LIST" ||
          type === "MAINTAINERS_LIST" ||
          type === "GUESTS_LIST" ? (
          <div className="project-data">
            <div className="user-name">{itemName}</div>
          </div>
        ) : type === LIST_WIDGET_CONTENT_TYPE.MARKERS ? (
          <div className="marker-data">
            <div className={`marker-name ${subText}`}>{description}</div>
            <div className="marker-context">{/*<span>{subText}</span>*/}</div>
            <div className="marker-description">{itemName}</div>
          </div>
        ) : (
          <div className="project-data">
            <div className="project-name">{itemName}</div>
            <div className={`project-context ${idFinished}`}>
              <span>{subText}</span>
            </div>
            <div className="project-description">{description}</div>
          </div>
        )}
      </div>
      <div className="project-right-content">
        { 
          type === LIST_WIDGET_CONTENT_TYPE.MEETINGS || type === LIST_WIDGET_CONTENT_TYPE.ANN_MEETINGS ? (
            ''
          ) : (
            <div className="date-box-container">
              {
                ( type === 'GUESTS' || type === 'AUTHORS' ) && !createReu ? (
                  <Button className="svg-container">
                    <Meetings />
                  </Button>
                ) : type === LIST_WIDGET_CONTENT_TYPE.MEETING_CATEGORIES ? (
                  <Button className="svg-container">
                    <Guest />
                  </Button>
                ) : (
                  ""
                )
              }
              <div className={`date-box ${(
                type === LIST_WIDGET_CONTENT_TYPE.MEETING_CATEGORIES) && !createReu ? "data-left-line" : ""
                }`}>
                { 
                  type === "ADMINISTRADORES_LIST" || type === "MAINTAINERS_LIST" || type === "GUESTS_LIST" ? (
                    <div>
                      <div className="user-type">
                        <span>{subText}</span>
                      </div>
                    </div>
                  ) : type === LIST_WIDGET_CONTENT_TYPE.MARKERS ? (
                    <div>
                      <div className="marker-date-box-text ">
                        <span className="marker-Date-widget-tag">{"Creada el:"}</span>
                      </div>
                      <div className="marker-date-box-date">
                        <span className={`marker-Date-widget ${subText}`}>
                          {creationDate}
                        </span>
                      </div>
                    </div>
                  ) : !createReu ? (
                    <div>
                      <div className="date-box-text ">
                        <span className="Date-widget-tag">{"Creada el:"}</span>
                      </div>
                      <div className="date-box-date">
                        <span className="Date-widget">{creationDate}</span>
                      </div>
                    </div>
                  ) : ( type !== "GUESTS" && type !== "AUTHORS" && (
                    <div>
                      <div className="date-box-text ">
                        <span className="Date-widget-tag">{"Creada el:"}</span>
                      </div>
                      <div className="date-box-date">
                        <span className="Date-widget-create-reunion">
                          {creationDate}
                        </span>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
        {
          creando ? (
            ""
          ) : type === "MAINTAINERS_LIST" ? (
            orgUser.accountType === USER_TYPE.ADMIN ? (
              <Button className={"move-right project-button"} onClick={() => goToUser(itemId)}>
                {ContentType.BTN}
              </Button>
            ) : (
              <Button className={'move-right nothing'}>
                {" "}
              </Button>
            )
          ) : !eliminando && type === LIST_WIDGET_CONTENT_TYPE.MEETINGS ? (
            <div>
              <div className="meeting-options-and-button">
                <Button className={"move-right general-button"} onClick={toggleMeetingOptions}>
                  {get ? "" : ContentType.BTN}
                </Button>
                <div ref={ref} className={`meeting-options meeting-mouse-up ${active ? "active" : "hidden"}`}>
                  <div>
                    <Button className="button-vertical project-mouse-up" onClick={handleDeleteMeeting}>
                      Eliminar reunión
                    </Button>
                  </div>
                  <Button className="button-vertical project-mouse-up" >
                    Editar reunión
                  </Button>
                  <Button className="button-vertical project-mouse-up" onClick={handleShowMeeting}>
                    Ver detalles
                  </Button>
                </div>
              </div>
              <DeleteConfirmModalMeeting
                history={history}
                meetingId={itemId}
                meetingDescription={description}
                fromMeeting={fromMeeting}
                projectId={project && project.id}
              />
            </div>
          ) : !eliminando && type === 'ANN_MEETINGS' ? (
            <div>
              <div className="meeting-options-and-button">
                <Button className={'move-right general-button'} onClick={handleShowMeeting}>
                  {get?(''):(ContentType.BTN)}
                </Button>
              </div>
              <MeetingModal />
            </div>
          ) : !eliminando && (type === LIST_WIDGET_CONTENT_TYPE.VIEW_3D || type === "GUESTS") ? (
            <Button className={"move-right general-button"} onClick={handleDeleteMeetingItem}>
              {get ? " " : ContentType.BTN}
            </Button>
          ) : isMeeting && type === LIST_WIDGET_CONTENT_TYPE.MARKERS ? (
          <Button
            className={`move-right general-button-marker ${subText}`}
            onClick={handleDeleteMeetingItem}
          >
            {get ? " " : ContentType.BTN}
          </Button>
        ) : type === LIST_WIDGET_CONTENT_TYPE.MARKERS ? (
          <Button
            className={`move-right ${subText}-second-button`}
            onClick={() => goToMarker(itemId)}
          >
            ▶
          </Button>
        ) : !eliminando &&
          type !== "GUESTS_LIST" &&
          type !== "ADMINISTRADORES_LIST" ? (
          <Button className={"move-right general-button"}>
            {get ? " " : ContentType.BTN}
          </Button>
        ) : (
          <Button className={"move-right nothing"}> </Button>
        )}
      </div>
    </div>
  );
};
