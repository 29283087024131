import React, { Component } from "react";
import { TextField } from "./TextField";

import { connect } from "react-redux";
import { updateSelf } from "../actions";
import "./styles/MyInfo.css"


const mapStateToProps = state => {
    return{
        placeholder: state.UserReducer.currentUser
    }
}

const initialState = {
    username: '',
    email: '',
    password: '',
    passwordVerification: '',
    disabled: false,
};

class UpdateInfoForm extends Component{
    
    constructor(props) {
        super(props);
        this.state = initialState;
        this.notice = '';
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState(initialState);
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        var newState = this.state;
        newState[event.target.name] = event.target.value

        this.setState(newState);
    }
    
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled: true});
        // handle validation
        const userData = {}
        Object.keys(this.state).forEach(key=>{
            if (this.state[key] !== initialState[key]) {
                userData[key] = this.state[key];
            }
        })
        // send request
        this.props.dispatch(updateSelf(userData)).then(()=>{
            this.setState(initialState);
            this.props.onSuccess();
        }).catch(error=>{
            if (error === "Validation error: Validation isEmail on email failed"){
                this.notice = "Por favor ingrese un mail válido"
            }
            else if (error === "Validation error"){
                this.notice = "El mail o nombre que ingresó ya tiene una cuenta"
            }
            else if (error === "Passwords do not match"){
                this.notice = "Las contraseñas deben ser iguales"
            }
            console.log(this.notice);
            console.log(error);
            this.setState({...this.state, disabled: false});
        })
    }


    render() {
        return(
            <div>
                <h2>
                    Update User Information
                </h2>

                <form onSubmit={this.handleSubmit}>
                <div className="display-info-user">
                    <div>
                        <br/>
                            <img alt="" src="https://es.ai.org.mx/wp-content/uploads/2020/05/user-default.jpg"></img>
                        <br/>
                    <div/>
                    <div>
                        <TextField 
                                className="input-box"
                                fieldName="username" 
                                type="text" 
                                value={this.state.username} 
                                placeholder={this.props.placeholder ? this.props.placeholder.username : ""}
                                onChange={this.handleChange}>
                            <span className="profile-items">Nombre de Usuario</span>
                        </TextField>
                        <br/>
                        <TextField 
                                className="input-box"
                                fieldName="email" 
                                type="text" 
                                value={this.state.email} 
                                placeholder={this.props.placeholder ? this.props.placeholder.email : ""}
                                onChange={this.handleChange}>
                            <span className="profile-items">Email</span>
                        </TextField>
                        <br/>
                    </div>
                    <div>
                    <TextField className="input-box" fieldName="password" type="password" value={this.state.password} onChange={this.handleChange}>
                        <span className="profile-items">Nueva contraseña</span>
                    </TextField>
                    <br/>
                    <TextField className="input-box" fieldName="passwordVerification" type="password" value={this.state.passwordVerification} onChange={this.handleChange}>
                        <span className="profile-items">Confirmación de contraseña</span>
                    </TextField>
                        <div className="profile-notice">
                            {this.notice}
                        </div>
                        <input className="submit-info-button" type="submit" value="Submit" disabled={this.state.disabled}/>
                        </div>
                    </div>
                    </div>
                </form> 
            </div>
        );
    }
}

const connectedUpdateInfoForm = connect(mapStateToProps)(UpdateInfoForm);

export {connectedUpdateInfoForm as UpdateInfoForm};