import {React, useEffect, useRef, useState } from "react";
import "./styles/ListWidget.css";
import "./styles/List.css";
import "./styles/ListMarker.css";
import "./styles/ProjectSection.css";
import "./styles/Meetings.css";
import { BiRocket } from "react-icons/bi";
import { ReactComponent as DownArrow } from "../routes/svg/icono_flecha_circular.svg";
import { ReactComponent as Meetings } from "../routes/svg/icono_reuniones_virtuales.svg";
import { ReactComponent as Meeting } from "../routes/svg/icono_reunión_virtual.svg";
import { ReactComponent as View3D } from "../routes/svg/icono_vista_3D.svg";
import { ReactComponent as Marker } from "../routes/svg/icono_anotacione_estandar.svg";
import { ReactComponent as Guest } from "../routes/svg/icono_invitado.svg";
import { ReactComponent as  Project } from "./svg/icono_proyectos.svg";
import icono_marker from "../routes/svg/icono_anotaciones.svg";

import { Button } from './Button';
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";

const CONTENT = {
  PROJECTS: {
    BTN: '▶',
  },
  PROJECTS_MAN: {
    BTN: '▶',
  },
  MEETINGS: { BTN: '...', ICON: <Meeting /> },
  VIEW_3D: { BTN: '...', ICON: <View3D /> },
  MARKERS: { BTN: '...', ICON: <Marker /> },
  GROUPED_MARKERS: {
    BTN: <DownArrow />,
    ICON: <Marker />,
  },
  GUESTS: { BTN: '...', ICON: <Guest /> },
  MAINTAINERS: { BTN: '...', ICON: <Guest /> },
  ADMINISTRADORES: { BTN: '▶', ICON: <Guest /> },
  ELEGIR_ADMINISTRADORES: { BTN: '▶', ICON: <Guest /> },
  MEETING_CATEGORIES: { BTN: <DownArrow />, ICON: <Meeting /> },
  ADMINISTRADORES_LIST: { BTN: '▶', ICON: <Guest /> },
  GUESTS_LIST: { BTN: '...', ICON: <Guest /> },
  MAINTAINERS_LIST: { BTN: '▶', ICON: <Guest /> },
};

export const ListWidgetItemMobile = ({
  type,
  itemId,
  itemName,
  subText,
  creationDate,
  description,
  onClick,
  creando,
  eliminando,
  createReu,
  funcionElegido,
  funcionDescartado,
  goToUser,
  finished,
  get,
  importing,
  funcionProjectElegido,
  funcionProjectDescartado,
  isMeeting,
  isStandard,
  goToMarker,
  fromMeeting,
}) => {
  const ref = useRef();
  const [selected, setSelected] = useState(false);
  const [checked, setChecked] = useState(false);
  const [importProject, setImportProject] = useState(false);
  const [checkedProject, setCheckedProject] = useState(false);
  const [active, setActive] = useState(false);
  let class_item = 'list-item project';
  if (type === 'ADMINISTRADORES' 
    || type === LIST_WIDGET_CONTENT_TYPE.MEETING_CATEGORIES
    || type === 'ADMINISTRADORES_LIST'
    || type === 'MAINTAINERS_LIST'
    || type === "GUESTS_LIST"
    || type === LIST_WIDGET_CONTENT_TYPE.MARKERS
    || createReu) {
    class_item = 'list-item-adm project';
  } else {
    class_item = 'list-item project';
  }

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (active && ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [active]);

  const toggle = (index) => {
    if (selected) {
      setChecked(false);
      funcionDescartado(index);
      setSelected(false);
    } else {
      setChecked(true);
      funcionElegido(index);
      setSelected(true);
    }
  };

  const toggleImportProject = (index) => {
    if (importProject) {
      setCheckedProject(false);
      funcionProjectDescartado(index);
      setImportProject(false);
    } else {
      setCheckedProject(true);
      funcionProjectElegido(index);
      setImportProject(true);
    }
  };

  var idFinished = finished?'green':'';

  return (
    <div className={class_item} id={idFinished}>
      <div className="project-left-content">
        {(type === LIST_WIDGET_CONTENT_TYPE.PROJECTS || type === LIST_WIDGET_CONTENT_TYPE.PROJECTS_MAN || type === LIST_WIDGET_CONTENT_TYPE.IMPORT_CATEGORIES) && !importing ? (
          <div className="project-logo">
            <BiRocket size={50} />
          </div>
        ) : creando ? (
          <div className="list-item-icon-2">
            <input
              className="elegirAdms"
              readOnly
              checked={checked || false}
              type="checkbox"
              onClick={() => toggle(itemId)}
            />
            {type !== LIST_WIDGET_CONTENT_TYPE.MARKERS && CONTENT[type].ICON }
          </div>
        ) : importing ? (
          <div className="list-item-icon-3 round">
            <input
              className="elegirProject"
              readOnly
              checked={checkedProject || false}
              type="checkbox"
              id="checkbox"
              onClick={() => toggleImportProject(itemId)}
            />
            <div className="import-project-logo">
              <BiRocket size={50} />
            </div>
          </div>
        ): type === LIST_WIDGET_CONTENT_TYPE.MARKERS ? (
            <div className={`list-item-icon-marker img-${subText}`}>
              <img src={icono_marker} width="25" height="25" class="filter" alt=""/>
            </div>
         ) : (
         <div className="list-item-icon">{CONTENT[type].ICON}</div>
        )}
        {type === 'ADMINISTRADORES'? (
          <div className="project-data">
            <div className="user-name">{itemName}</div>
          </div>
        ) : ((type === LIST_WIDGET_CONTENT_TYPE.PROJECTS || type === LIST_WIDGET_CONTENT_TYPE.PROJECTS_MAN)&& !eliminando) || type ===  LIST_WIDGET_CONTENT_TYPE.IMPORT_CATEGORIES? (
          <div className="project-data">
            <div className="project-name">{itemName}</div>
            <div className="project-context">
              <span>Organización a la que pertenece: {subText}</span>
            </div>
            <div className="project-description">{description}</div>
          </div>
        ) : type === LIST_WIDGET_CONTENT_TYPE.PROJECTS && eliminando ? (
          <div className="project-data">
            <div className="project-name">{itemName}</div>
          </div>
        ) : ( type === 'ADMINISTRADORES_LIST'|| type === 'MAINTAINERS_LIST' || type === "GUESTS_LIST"? (
          <div className="project-data">
            <div className="user-name">{itemName}
            <div className="user-type">
              <span>{subText}</span>
            </div>
            </div>
          </div>
        ) : ( type === LIST_WIDGET_CONTENT_TYPE.MARKERS ? (
          <div className="marker-data">
            <div className={`marker-name ${subText}`}>{description}</div>
            <div className="marker-context">
              {/*<span>{subText}</span>*/}
            </div>
            <div className="marker-description">{itemName}</div>
          </div>
        ): (
          <div className="project-data"> 
            <div className="project-name">{itemName}</div>
            <div className="project-context">
              <span>{subText}</span>
            </div>
            <div className="project-description">{description}</div>
          </div>
        )))}
      </div>
      <div className="project-right-content">
        {type === LIST_WIDGET_CONTENT_TYPE.MEETINGS ? (
          ''
        ) : (
          <div className="date-box-container">
            {type === 'GUESTS' && !createReu ? (
              <Button className="svg-container">
                <Meetings />
              </Button>
            ) : type === 'ADMINISTRADORES' ? (
              <Button className="svg-container">
                <Project />
              </Button>
            ) : type === LIST_WIDGET_CONTENT_TYPE.MEETING_CATEGORIES ? (
              <Button className="svg-container">
                <Guest />
              </Button>
            ) : (
              ''
            )}
            <div
              className={`date-box ${
                (type === LIST_WIDGET_CONTENT_TYPE.MAINTAINERS ||
                  type === 'ADMINISTRADORES' ||
                  type === LIST_WIDGET_CONTENT_TYPE.MEETING_CATEGORIES) &&
                !createReu
                  ? 'data-left-line'
                  : ''
              }`}
            >
              {type === 'ADMINISTRADORES' || type === 'ADMINISTRADORES_LIST'|| type === 'MAINTAINERS_LIST' || type === "GUESTS_LIST"? (
                <div>
                  {/*<div className="date-box-text ">
                    <span className="Date-widget-tag">{'Tipo de Usuario'}</span>
                  </div>*/}
                  <div className="user-type">
                    {/* <span>{subText}</span> */}
                  </div>
                </div>
              ) : type === LIST_WIDGET_CONTENT_TYPE.MARKERS ? (
                <div>
                  <div className="marker-date-box-text ">
                    <span className="marker-Date-widget-tag">{'Creada el:'}</span>
                  </div>
                  <div className="marker-date-box-date">
                    <span className={`marker-Date-widget ${subText}`}>{creationDate}</span>
                  </div>
                </div>
              ) : !createReu ? (
                <div>
                  <div className="date-box-text ">
                    <span className="Date-widget-tag">{'Creada el:'}</span>
                  </div>
                  <div className="date-box-date">
                    <span className="Date-widget">{creationDate}</span>
                  </div>
                </div>
              ) : ( type !== "GUESTS" && (
                <div>
                  <div className="date-box-text ">
                    <span className="Date-widget-tag">{'Creada el:'}</span>
                  </div>
                  <div className="date-box-date">
                    <span className="Date-widget-create-reunion">{creationDate}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
