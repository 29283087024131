import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "../Button";
import { deleteOrg, setDeleteOrgModal, closeAll, setVisibility } from "../../actions"
import "./styles/Modal.css"
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";

const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalDeleteOrg,
        organization: state.OrganizationReducer.organization
    };
}

const initialState = {
    disabled: false,
};

class DeleteOrgModal extends Component{

    constructor(props){
        super(props);
        this.state = initialState;
        this.setUserOptions = this.setUserOptions.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
    }

    resetState(){
        this.setState(initialState);
    }

    setUserOptions(visibility) {
        this.setState({ ...this.state, userOptionsIsActive: visibility });
      }

    goTo(routeName) {
        this.setUserOptions(false);
        this.props.history.push(routeName);
        this.props.dispatch(closeAll(true));
        this.props.dispatch(setVisibility(false));
      }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled:true});
        this.props.dispatch(deleteOrg()).then(()=>{
            this.goTo("/organizations");
        }).catch(()=>{
            this.setState({...this.state, disabled:false});
        })
    }

    closeModal(){
        // this.resetState();
        // this.props.dispatch(selectUser(-1));
        this.props.dispatch(setDeleteOrgModal(false)).then(this.resetState);
    }

    render(){
        var modalState = 'modal ';
        modalState += this.props.isVisible? 'modal-open':'modal-closed';

        return(
            <div className={modalState}>
                    <div className="delete-modal">
                        <div className="delete-modal-box">
                                <Button className="modal-button"  onClick={this.closeModal}>
                                    <Close />
                                </Button>
                                <div id="MyModal" className="modal-content">
                                <div className="modal-warning">
                                    Está seguro que desea <span>eliminar</span> la organización <span>{this.props.organization===undefined  || this.props.organization===null ? "Loading" : this.props.organization.name}</span>? Se borrará todo el contenido de esta y ya no podrá acceder a esta organización.
                                </div>
                                <div className='bottom-buttons'>
                                <Button className="red-button" onClick={this.handleSubmit}>
                                    ELIMINAR
                                </Button>
                                <Button className="purple-button" onClick={this.closeModal}>
                                    NO ELIMINAR
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

const connectedDeleteOrgModal = connect(mapStateToProps)(DeleteOrgModal);

export { connectedDeleteOrgModal as DeleteOrgModal };