import { ORG_ACTIONS } from "../constants";
import { OrganizationService } from "../services";

export const getOrganization = () => async (dispatch) => {
    try {
        const organization = await OrganizationService.getOrganization();
        dispatch({
            type: ORG_ACTIONS.ORG_INFO,
            payload: organization
        })
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const updateOrganization = ( orgData ) => async (dispatch) => {
    try {
        const organization = await OrganizationService.updateOrganization( orgData );
        if (!organization) {
            throw new Error("organization not found.")
        }
        dispatch({
            type: ORG_ACTIONS.UPDATE_ORG,
            payload: organization
        })
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const deleteOrg = () => async (dispatch) => {
    try {
        await OrganizationService.deleteOrg();
        dispatch({
            type: ORG_ACTIONS.DELETE_ORG,
            payload: true
        })
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getOrgUsers = () => async (dispatch) => {
    try {
        const orgUsers = await OrganizationService.getOrgUsers();
        dispatch({
            type: ORG_ACTIONS.GET_ORG_USERS,
            payload: orgUsers.orgUsers
        })
    } catch (error) {
        console.error(error);
        // throw error;
    }
}

export const newMantainer = (mantainerData) => async (dispatch) => {
    try {
        const mantainer = await OrganizationService.newMantainer(mantainerData);
        dispatch( { 
            type: ORG_ACTIONS.NEW_MANTAINER, 
            payload: mantainer
        })
    } catch (error) {
        throw error;
    }
}

export const newGuest = (guestData) => async (dispatch) => {
    try {
        const mantainer = await OrganizationService.newGuest(guestData);
        dispatch( { 
            type: ORG_ACTIONS.NEW_GUEST, 
            payload: mantainer
        })
    } catch (error) {
        throw error;
    }
}

export const getOrgMaintainers = () => async (dispatch) => {
    try {
        const orgUsers = await OrganizationService.getOrgUsers();
        dispatch({
            type: ORG_ACTIONS.GET_ORG_MAINTAINERS,
            payload: orgUsers
        })
    } catch (error) {
        console.error(error);
        // throw error;
    }
}

export const getOrgMarkers = () => async (dispatch) => {
    try {
        const orgMarkers = await OrganizationService.getOrgMarkers();
        dispatch({
            type: ORG_ACTIONS.GET_ORG_MARKERS,
            payload: orgMarkers
        })
    } catch (error) {
        console.error(error);
        // throw error;
    }
}

export const getOrgMarker = (annotationTypeId) => async (dispatch) => {
    try {
        const annotationType = await OrganizationService.getAnnotationType(annotationTypeId);
        dispatch({
            type: ORG_ACTIONS.GET_ORG_MARKER,
            payload: annotationType
        })
    } catch (error) {
        console.error(error);
        // throw error;
    }
}
