import "./styles/Button.css";

export const Button = ({
    className,
    onClick,
    tooltip,
    tooltipPosition,
    children,
}) => {

    const getClassName = () => {
        if (className !== "modal-button") {
            return ["button", "non-select", className].join(" ");
        } else {
            return [className].join(" ");
        }
    }

    const renderTooltip = (tooltip, tooltipPos = "") => {
        if (tooltip) {
            return (
                <span className={`button-tooltip ${ tooltipPos }`}>
                    { tooltip }
                </span>
            );
        }
    }

    return(
        <div className={ getClassName()} onClick={ onClick }>
        { children ? children : "Button" }
        { renderTooltip(tooltip, tooltipPosition??"")}
    </div>
    )
}