import { React, useEffect, useState } from "react";
import "./styles/ListWidget.css";
import "./styles/List.css";
import { MiniButton } from "../components";
import { ListWidgetItemMobile } from '../components';
import { useHistory } from "react-router-dom";
import { selectOrganization } from "../actions";
import store from "../store";
import {
  MobileView,
  isBrowser,
} from "react-device-detect";
import { ReactComponent as Meeting } from "../routes/svg/icono_reuniones_virtuales.svg";
import { ReactComponent as Question } from "../routes/svg/icono_consulta.svg";
import { MeetingModal } from "./modals/MeetingModal";
import { AnnotationModal } from "./modals/AnnotationModal";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";
import { MeetingContentType } from "./ListWidget/contentType/MeetingContentType";

const formatDate = (date) => {
  if ( date ) {
    return date.split("T")[0].split("-").reverse().join(" / ");
  }
  else {
    return ("");
  }
};

const goTo = (orgId, history) => {
  const body = {
    orgId: orgId,
  };
  store.dispatch(selectOrganization(body));
  history.push("./home");
};

export const MeetingList = ({ content }) => {
  const [list, setList] = useState(content);

  let history = useHistory();

  useEffect(() => {
    setList(content);
  }, [content]);

  if (isBrowser) {
    return (
      <div className="list-container">
        <div className="list-header">
          <div className="list-info">
            <h3>REUNIONES</h3>
            <span className="left-line">
              <div className="content-icon-container">
                <Meeting />
                <span className="content-counter">{content.length}</span>
              </div>
            </span>
            <span className="left-line">
              <div className="help-icon">
                <Question />
              </div>
            </span>
          </div>
          <div className="list-actions">
            <div className="search-box">
              <MiniButton type="to_search" />
            </div>
            <MiniButton type="filter" />
          </div>
        </div>
        <div className="list-content">
          {
            MeetingContentType.RenderList({
              list: content ?? [],  
              searchFilter: "",
              type: LIST_WIDGET_CONTENT_TYPE.MEETINGS, 
              fromMeeting: true,
            })
          }
          <MeetingModal />
        </div>
        <AnnotationModal putMargin={true}/>
      </div>
    );
  }

  return (
      <MobileView>
        <div className="list-container">
          <div className="list-header">
            <div className="list-info-mobile">
              <Meeting />{content.length}
            </div>
            <div className="list-actions">
              <MiniButton type="filter" />
            </div>
          </div>
          <div className="list-content">
            {list.map((item, index) => (
              <ListWidgetItemMobile
                type={LIST_WIDGET_CONTENT_TYPE.MEETINGS}
                key={index}
                itemId={item.id}
                subText={`AGENDADA: ${formatDate(item.date)}`}
                description={item.topic}
                onClick={goTo.bind(null, item.id, history)}
                fromMeeting={true}
              />
            ))}
          </div>
        </div>
      </MobileView>
  );
};
