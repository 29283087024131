import { PRODUCTS_ACTIONS } from "../constants";
import { GetProductList } from "../services/ProductsService";

export const GetProductListAction = () => async (dispatch) => {
  try {
      const productList = await GetProductList();
      dispatch( { 
          type: PRODUCTS_ACTIONS.GET_PRODUCTS, 
          payload: productList,
      })
  } catch (error) {
      throw error;
  }
}
