import { ValueAContainsB, ValueIsNull } from "../../../../helpers/FormatHelper";
import { MeetingListItem } from "./items/MeetingListItem";

export const MeetingList = function ({ list, searchFilter, type, fromMeeting }) {
  
  if (!list || list.length === 0) return (<div />);

  const isFromMeeting = ValueIsNull(fromMeeting) ? type === "ANN_MEETINGS" : fromMeeting;
   
  return(
    <>
    {
      list.map(
        (item, index) => ValueAContainsB({ valueA: item.topic, valueB: searchFilter }) && (
          <MeetingListItem
            type={type}
            key={index}
            meeting={item}
            itemId={item.id}
            itemName=""
            subText={ `${ item.isFinished ? 'REALIZADA' : 'AGENDADA' }: ${item.date.substring(0, 10)} a las ${item.date.substring(11, 19)}` }
            finished={item.isFinished}
            description={item.topic}
            projectId={item.ProjectId}
            fromMeeting={isFromMeeting}
          />
        )
      )
    }
    </>
  )
};
