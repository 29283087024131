import React, { Component } from "react";
import { connect } from "react-redux";
import { addProjectMeetingCategory, setAddMeetingCategoryModal, setConfigCategoryMeetingModal } from "../../actions";
import { setMeetingModal } from "../../actions";
import { TextField } from "../TextField";
import "./styles/Modal.css"
import "./styles/AddProjectMeetingCategoryModal.css"
import { IS_INT } from "../../constants";
import { FaTimes } from 'react-icons/fa';

const mapStateToProps = state => {
    return { 
        addCategoryisVisible: state.ModalReducer.modalAddCategoryMeetings,
        parent: state.ModalReducer.addCategoryParentCallBack,
        users: state.UserReducer.users,
        currentUser: state.UserReducer.currentUser,
        selectedProject: state.ProjectReducer.selectedProject,
    };
}

const initialState =  {
    name: '',
    description: '',
    disabled: false,
};

class AddProjectMeetingCategoryModal extends Component{
    
    constructor(props){
        super(props);
        this.state = initialState;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        var newState = this.state;
        newState[event.target.name] = event.target.value
        this.setState(newState);
    }

    resetState(){
        this.setState(initialState);
    }

    goToProjects() {
        this.resetState();
        this.props.history.push(`/projects`);
    }


    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled:true});
        const projectData = {}
        const fields = ['name', 'description']
        fields.forEach(field=>{
            if (this.state[field] !== undefined || this.state[field] !== null || this.state[field] !== '' || this.state[field] !== []) {
                projectData[field] = IS_INT.test(this.state[field]) ? parseInt(this.state[field]) : this.state[field];
            }
        });

        const onSuccess = ()=>{this.closeModal();};
        const onFailure = (error)=>{
            console.error(error.fieldErrors);
            this.setState({...this.state, disabled:false});
        };
        this.props.dispatch(addProjectMeetingCategory(this.props.selectedProject.id, projectData)).then(onSuccess).catch(onFailure);
        // this.goToProjects();
        return
    }

    closeModal(){
        const {parent} = this.props;
        if (parent){
            if(parent === 'Config'){
                this.props.dispatch(setConfigCategoryMeetingModal(true)).then(this.resetState);
            }
            else if (parent === 'newMeeting'){
                this.props.dispatch(setMeetingModal(true)).then(this.resetState);
            }
        }
        else{
            this.props.dispatch(setAddMeetingCategoryModal(false)).then(this.resetState);
        }
    }

    // Falta implementar
    // onClickOutside(event) {
    //     if (this.state.disabled && this.modal.contains(event.target));
    //     this.closeModal();
    //     return
    // };


    render(){
        const{selectedProject} = this.props;
        var modalState = 'add-modal ';
        modalState += this.props.addCategoryisVisible? 'modal-open':'modal-closed';

        return(
            <div className={modalState}>
                <div className="project-modal-box">
                    <div id="MyModal" className="modal-content">
                        <button className="closing-x" onClick={this.closeModal}>
                            <FaTimes/>
                        </button>
                        <h2>Tipos de Reunion / {selectedProject? (selectedProject.name):("No name")}</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="add-meeting-category-top">
                                <div className="add-meeting-category-right-content">
                                    <TextField 
                                        className="name-meeting-category-field" 
                                        fieldName="name" 
                                        type="text" 
                                        value={this.state.name} 
                                        placeholder={this.props.placeholder ? this.props.placeholder.name : "Nombre de la categoría"} 
                                        onChange={this.handleChange}>
                                    </TextField>
                                    <TextField
                                        className="description-meeting-category-field"
                                        fieldName="description" 
                                        maxlength={200}
                                        type="text" 
                                        value={this.state.description} 
                                        placeholder={this.props.placeholder ? this.props.placeholder.description : "Descripción de la categoría"} 
                                        onChange={this.handleChange}>
                                    </TextField>
                                </div>
                            </div>
                            <div className="add-meeting-category-bottom"> 
                                <input className="add-meeting-category-button" type="submit" value="CREAR TIPO DE REUNION" disabled={this.state.disabled}/>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        )
    }
}
/*
<select className="login-field" id="maintainers" name="maintainers" placeholder={<div>Type to search</div>} value={this.state.maintainers} onChange={this.handleChange}>
    <option value={mantainerVal} defaultValue disabled hidden>
        { mantainerLabel }
    </option>
    {this.props.users.map(user=>(
        <option value={user.id} key={user.id}>
            {user.username}
        </option>
    ))}
</select>
*/

const connectedAddProjectMeetingCategoryModal = connect(mapStateToProps)(AddProjectMeetingCategoryModal);

export { connectedAddProjectMeetingCategoryModal as AddProjectMeetingCategoryModal };