import React, { Component } from "react";
import { withRouter,  } from "react-router";

import { ROUTES } from "../constants";

import { VerifyAccountForm } from "../components";

import "./styles/SignUp.css";
import { connect } from "react-redux";
import { verifyUser } from "../actions";

import {ReactComponent as RedicoLogo } from '../components/Icons/logo_redico.svg';
import {ReactComponent as LoginLogo } from '../components/Icons/logo_login.svg';
class VerifyAccount extends Component{
    
    goTo(routeName){
        if (this.props.location.pathname === routeName) {
            return;
        }
        this.props.history.push(routeName);
    }

    render(){
        if ( this.props.match.params.password === "false" ) {
            const token = {
                token: this.props.match.params.token,
            }
            // send request
            this.props.dispatch(verifyUser(token, {})).then(()=>{
                this.goTo({
                    pathname: ROUTES.SIGN_IN, 
                    state: { notice: "Su cuenta ha sido verificada" }
                }) 
            }).catch(error=>{
                console.log(error);
            })
        }
        return(
            <div className='grid'>
                {/* <h1> Token: {this.props.match.params.token} Password: {this.props.match.params.password}</h1> */}
                <div className='first-mid-row'>
                    <LoginLogo className='login-logo'/>
                </div>
                <div className='mid-grid'>
                    <VerifyAccountForm token={this.props.match.params.token} onSuccess={()=>this.goTo({
                            pathname: ROUTES.SIGN_IN, 
                            state: { notice: "Su cuenta ha sido verificada" }
                        })} /> 
                </div>
                <div className='third-third-row'>
                    <RedicoLogo className='redico-logo'/>
                </div>
            </div>
        )
        
    }
}

const connectedVerifyAccount= connect(null)(VerifyAccount);

const VerifyAccountWithRouter = withRouter(connectedVerifyAccount);

export { VerifyAccountWithRouter as VerifyAccount};