import { useState } from "react";
import { BiRocket } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectProject } from "../../../../../actions";
import { LIST_WIDGET_CONTENT_TYPE } from "../../../../../constants/ListWidgetContentType";
import { FormatDate } from "../../../../../helpers/FormatHelper";
import { Button } from "../../../../Button";

export const ProjectListItem = ({
  type,
  item,
  onDeselected,
  onSelected,
}) => {
  
  const history = useHistory();
  const dispatch = useDispatch();

  const [checkedProject, setCheckedProject] = useState(false);
  const [importProject, setImportProject] = useState(false);

  const toggleImportProject = () => {
    if (importProject) {
      setCheckedProject(false);
      onDeselected(item.id);
      setImportProject(false);
    } else {
      setCheckedProject(true);
      onSelected(item.id);
      setImportProject(true);
    }
  };


  const goToProject = () => {
    dispatch(selectProject(item.id)).then(()=>{
      history.push(`/projects/${item.id}`);
    });
  };

  const importing = type === LIST_WIDGET_CONTENT_TYPE.IMPORT_CATEGORIES;

  return (
    <div className={`list-item project`}>
      <div className="project-left-content">
        {
          !importing ? (
            <div className="project-logo">
              <BiRocket size={50} />
            </div>
          ) : (
            <div className="list-item-icon-3 round">
              <input
                className="elegirProject"
                readOnly
                checked={checkedProject || false}
                type="checkbox"
                id="checkbox"
                onClick={toggleImportProject}
              />
              <div className="import-project-logo">
                <BiRocket size={50} />
              </div>
            </div>
          )
        }
        <div className="project-data">
          <div className="project-name">
            { item.name }
          </div>
          <div className="project-context">
            <span>
              Organización a la que pertenece: { item.organization ? item.organization.name : '-' }
            </span>
          </div>
          <div className="project-description">
            { item.description }
          </div>
        </div>
      </div>
      <div className="project-right-content">
        <div className="date-box-container">
          <div className={`date-box`}>
            <div>
              <div className="date-box-text ">
                <span className="Date-widget-tag">{"Creada el:"}</span>
              </div>
              <div className="date-box-date">
                <span className="Date-widget">{FormatDate(item.createdAt)}</span>
              </div>
            </div>
          </div>
        </div>
        <Button className={`move-right ${type === LIST_WIDGET_CONTENT_TYPE.PROJECTS ? "project-button" : "nothing"}`} onClick={goToProject}>
          ▶
        </Button>
      </div>
    </div>
  );
};
