import { ValueAContainsB } from "../../../../helpers/FormatHelper";
import { AttachedFileListItem } from "./items/AttachedFileListItem";

export const AttachedFileList = ({ 
  list,
  searchFilter,
  onItemOptionsClick,
}) => {
  return(
    <div>
      {
        list.map(
          (item, index) => ValueAContainsB({ valueA: item.fileName,  valueB: searchFilter}) && (
            <AttachedFileListItem key={index} item={item} onItemOptionsClick={onItemOptionsClick}/>
          )
        )
      }
    </div>
  );
};
