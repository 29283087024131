import { MODAL_ACTION } from "../constants";

const initialState = {
    modalUser: false,
    modalDeleteUser: false,
    modalDesregister: false,
    modalOrganization: false,
    modalData: false,
    modalProject: false,
    modalProjectMaintainer:false,
    modalDeleteOrg: false,
    modalDeleteProjectMaintainer:false,
    modalProjectMaintainerid:0,
    modalAddCategoryMeetings: false,
    modalConfigCategoryMeetings: false,
    modalMeeting: false,
    modalDeleteMeeting: false,
    modalMeetingid: -1,
    modalDeleteView: false,
    modalViewId: -1,
    modalAddMeetingGuest: false,
    modalAddMeetingNote: false,
    modalAddMeetingView: false,
    modalGetMeeting:false,
    addCategoryParentCallBack: '',
    annotationModal: false,

    attachedFileModalSelectedItem: {},
    attachedFileDeleteModalVisibility: false,
};

const rootReducer = (state = initialState, action) => {
    const {type, payload} = action;
    
    switch (type) {
        case MODAL_ACTION.SET_DATA_MODAL:
            return {...initialState, modalData: payload};
        case MODAL_ACTION.SET_PROJECT_MODAL:
            return {...initialState, modalProject: payload};
        case MODAL_ACTION.SET_PROJECT_MAINTAINER_MODAL:
            return {...initialState, modalProjectMaintainer: payload};
        case MODAL_ACTION.SET_ORGANIZATION_MODAL:
            return {...initialState, modalOrganization: payload};
        case MODAL_ACTION.SET_DESREGISTER_MODAL:
            return {...initialState, modalDesregister: payload};
        case MODAL_ACTION.SET_DELETE_ORG_MODAL:
            return {...initialState, modalDeleteOrg: payload};
        case MODAL_ACTION.SET_DELETE_USER_MODAL:
            return {...initialState, modalDeleteUser: payload};
        case MODAL_ACTION.SET_DELETE_PROJECT_MODAL:
            return {...initialState, modalDeleteUser: payload};
        case MODAL_ACTION.SET_UPDATE_PROJECT_MODAL:
            return {...initialState, modalProject: payload};
        case MODAL_ACTION.SET_USER_MODAL:
            return {...initialState, modalUser: payload};
        case MODAL_ACTION.SET_DELETE_PROJECT_MAINTAINER:
            return {...initialState, modalDeleteProjectMaintainer:payload['modalVisibility'], modalProjectMaintainerid:payload['maintainerId']};
        case MODAL_ACTION.SET_ADD_MEETING_CATEGORY_MODAL:
            return {...initialState, modalAddCategoryMeetings:payload['modalVisibility'], addCategoryParentCallBack:payload['parent']};
        case MODAL_ACTION.SET_CONFIG_CATEGORY_MEETING_MODAL:
            return {...initialState, modalConfigCategoryMeetings: payload}
        case MODAL_ACTION.SET_IMPORT_TYPES_OF_MEETING_MODAL:
            return {...initialState, modalImportTypesOfMeeting: payload}
        case MODAL_ACTION.SET_MEETING_MODAL:
            return {...initialState, modalMeeting: payload};
        case MODAL_ACTION.SET_DELETE_MEETING_MODAL:
            return {...initialState, modalDeleteMeeting: payload['modalVisibility'], modalMeetingid: payload['meetingId']};
        case MODAL_ACTION.SET_DELETE_VIEW_MODAL:
            return {...initialState, modalDeleteView: payload['modalVisibility'], modalViewId: payload['viewId']};
        case MODAL_ACTION.SET_MARKER_MODAL:
            return {...initialState, modalMarker: payload};
        case MODAL_ACTION.SET_MEETING_GUEST_MODAL:
            return {...initialState, modalAddMeetingGuest: payload['modalVisibility']};
        case MODAL_ACTION.SET_MEETING_VIEW_MODAL:
            return {...initialState, modalAddMeetingView: payload['modalVisibility']};
        case MODAL_ACTION.SET_MEETING_NOTE_MODAL:
            return {...initialState,  modalAddMeetingNote: payload['modalVisibility']};
        case MODAL_ACTION.SET_GET_MEETING_MODAL:
            return { ...initialState, modalGetMeeting: payload };
        case MODAL_ACTION.SET_ANNOTATION_MODAL:
            return {...initialState,  annotationModal: payload};

        case MODAL_ACTION.SET_ATTACHED_FILE_DELETE_MODAL:
            return {
                ...initialState,
                attachedFileDeleteModalVisibility: payload['modalVisibility'],
                attachedFileModalSelectedItem: payload['selectedItem'] ?? {},
            };
        case MODAL_ACTION.SET_ATTACHED_FILE_UPDATE_MODAL:
            return {
                ...initialState,
                modalData: payload['modalVisibility'],
                attachedFileModalSelectedItem: payload['selectedItem'] ?? {},
            };

        case MODAL_ACTION.CLOSE_ALL:
            return initialState;
        default:
            return state;
    }
}

export default rootReducer;