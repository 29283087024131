import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";

import {
  FreeDownload,
  LoggedHome,
  SignUp,
  SignIn,
  MyPage,
  UsersRoute,
  User,
  Projects,
  Project,
  ProjectSelected,
  Marker,
  VerifyAccount,
  Organizations,
  Meetings,
  MarkersRoute,
} from "./routes";
import "./App.css";
import { ROUTES } from "./constants";
import store from "./store";
import { RvipLayout } from "./components";
import { Downloads } from "./routes/DownloadsRoute";

class App extends Component {
  render() {
    return (
      
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path={ROUTES.SIGN_UP} component={SignUp} />
            <Route path={ROUTES.SIGN_IN} component={SignIn} />
            <Route path={ROUTES.VERIFY_ACCOUNT} component={VerifyAccount} />
            <Route path={ROUTES.ORGANIZATIONS} component={Organizations} />
            <Route exact path={ROUTES.HOME} component={SignIn} />
            <RvipLayout>
              <Route exact path={ROUTES.FREE_DOWNLOADS} component={FreeDownload} />
              <Route exact path={ROUTES.DOWNLOADS} component={Downloads} />
              <Route exact path={ROUTES.LOGGED_HOME} component={LoggedHome} />
              <Route exact path={ROUTES.MY_ORG} component={UsersRoute} />
              <Route exact path={ROUTES.MEETINGS} component={Meetings} />
              <Route exact path={ROUTES.MY_ACCOUNT} component={MyPage} />
              {/* <Route path={ROUTES.MY_PAGE} component={User}/> */}

              <Route exact path={ROUTES.PROJECT_SELECTED} component={ProjectSelected}/>
              <Route exact path={ROUTES.USER_PAGE} component={User} />
              <Route exact path={ROUTES.PROJECT_PAGE_VIEW} component={Project} />
              <Route exact path={ROUTES.PROJECT_PAGE} component={Project} />
              <Route exact path={ROUTES.PROJECTS} component={Projects} />
              <Route exact path={ROUTES.MARKERS} component={MarkersRoute} />
              <Route exact path={ROUTES.ANNOTATION_TYPES} component={Marker}/>
              <Route exact path={ROUTES.ANNOTATION_TYPES_PAGE_VIEW} component={Marker}/>
            </RvipLayout>
            <Route path={ROUTES.HOME} component={SignIn} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
