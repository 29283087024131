import { SIDEPANEL_ACTIONS } from "../constants";

export const setSelected = (selected) => async (dispatch) => {
    try {
        dispatch({
            type: SIDEPANEL_ACTIONS.SET_SELECTED,
            payload: selected
        });
    } catch (error) {
        throw error;
    }
}

export const setVisibility = (visibility) => async (dispatch) => {
    try {
        dispatch({
            type: SIDEPANEL_ACTIONS.SET_VISIBILITY,
            payload: visibility
        });
    } catch (error) {
        throw error;
    }
}

export const toggleVisibility = () => async (dispatch) => {
    try {
        dispatch({
            type: SIDEPANEL_ACTIONS.TOGGLE_VISIBILITY,
            payload: null
        });
    } catch (error) {
        throw error;
    }
}