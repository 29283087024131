import { Button } from '../../../..';
import { ReactComponent as DeployMenu } from '../../../../svg/icono_menu_desplegable.svg';
import { ReactComponent as DocIcon } from '../../../../svg/icono_lecciones_aprendidas.svg';

import './styles/AttachedFileListItem.css';
import './styles/ListItem.css';
import { useRef } from 'react';

const FILE_TYPE = {
  0: 'Actividades y Restricciones',
  1: 'Documento Adjunto',
}

export const AttachedFileListItem = ({ item, onItemOptionsClick }) => {

  const ref = useRef();

  const OnItemOptionsClick = () => {
    if (!ref) return;
    if (!ref.current) return;
    const rect = ref.current.children[3].getBoundingClientRect();
    onItemOptionsClick({
      item: item,
      posX: rect.left,
      posY: rect.top,
    });
  }

  return(
    <div className='list-item attached-file' ref={ref}>
      <div>
        <DocIcon />
      </div>
      <div className='attached-file description'>
        <h3>
          { item.fileName.replaceAll('_', ' ') }
        </h3>
        <p>
          { `tipo: ${FILE_TYPE[item.fileType]??'Desconocido'}` }
        </p>
      </div>
      <div className='spacer' />
      <Button className='attached-file-button' onClick={ OnItemOptionsClick }>
        <DeployMenu />
      </Button>
    </div>
  );
};
