import { ValueAContainsB } from "../../../../helpers/FormatHelper";
import { ProjectListItem } from "./items/ProjectListItem";

export const ProjectList = ({list, searchFilter, type, AddProjectToSelection, funcionProjectDescartado}) => {
  return(
    <div>
      {
        list.map(
          (item, index) => ValueAContainsB({ valueA: item.name,  valueB: searchFilter}) && (
            <ProjectListItem
              type={type}
              key={index}
              item={item}
              onSelected={AddProjectToSelection}
              onDeselected={funcionProjectDescartado}
            />
          )
        )
      }
    </div>
  );
};
