import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { newMantainer, getOrgUsers, setUserModal } from '../actions';
import "./styles/NewUsers.css";

export const NewMantainer = ( props ) => {

    let history = useHistory();
    const dispatch = useDispatch()

    const [email, setEmail] = useState(props.email);
    const [username, setUsername] = useState(props.username);
    const [notice, setNotice] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const body = {
            email: email,
            username: username
        }
        dispatch(newMantainer(body)).then(()=> {
            dispatch(getOrgUsers());
            dispatch(setUserModal(false)); 
            history.push("/my-org")
        }).catch(error=>{
            // setNotice(error);
            console.log(error);
            if (error[0] === "Validation isEmail on email failed") {
                setNotice("Por favor, ingrese un mail válido");
            } else if (error[0] === "User already belongs in your organization.") {
                setNotice("Este usuario ya pertenece a la organización");
            } else if (error === "User already belongs in your organization.") {
                setNotice("Este usuario ya pertenece a la organización");
            } else if (error[0] === "username must be unique") {
                setNotice("Este nombre de usuario ya se encuentra registrado");
            }
        });
    }

    const handleClick = (event)  => {
        event.preventDefault();
        props.switch(username, email)
    }

    const closeModal = (event)  => {
        event.preventDefault();
        props.close()
    }



    return (
        <div className="display-new-user">
            <form onSubmit={handleSubmit}> 
                <span className="new-user-items"> Nombre: </span>
                <br/>
                <input 
                    required
                    className="new-user-input-box" 
                    fieldname="username" 
                    type="text"
                    value={username}
                    onChange={e => setUsername(e.target.value)}>
                </input>
                <br/>
                <span className="new-user-items"> Correo: </span>
                <br/>
                <input 
                    required
                    className="new-user-input-box" 
                    fieldname="email" 
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}>
                </input>
                <br/>
                <span className="new-user-items"> Tipo: </span>
                <br/>
                <div className="user-type-buttons">
                    <button className="user-type-button" onClick={handleClick}> Invitado </button>
                    <button className="selected-user-type-button" > Maintainer </button>
                </div>
                <br/>
                <span className="disabled-new-user-items"> Nombre de la compañía: </span>
                <br/>
                <input
                    className="disabled-new-user-input-box" 
                    type="text" 
                    disabled
                    >
                </input>
                <div>
                    <div className="profile-notice">
                        {notice}
                    </div>
                    <br/>
                    <div className="user-type-buttons">
                        <button className="submit-new-user cancel-red-color" onClick={closeModal}> Cancelar </button>
                        <input className="submit-new-user" type="submit" value="Crear"/>
                    </div>
                    <br/>
                    <br/>
                </div>
            </form>
        </div>
    );
  };