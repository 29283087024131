import React from "react";
import { ListWidget } from "../components";
import { Links } from "../components/Links";
import { ROUTES } from "../constants";
import { Button } from "../components/Button";
import {
    MobileView,
    isBrowser,
  } from "react-device-detect";
import { ReactComponent as IconAdmin } from './svg/iconos_adm_proyectos.svg';
import { ReactComponent as Arrow } from "../routes/svg/icono_flecha.svg";
import "./styles/Redico.css";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";
import { MemberContentType } from "../components/ListWidget/contentType/MemberContentType";

export const UsersRoute = () => {

  const location = useLocation();
  const history = useHistory();
  
  const orgUsers = useSelector((state) => state.OrganizationReducer.orgUsers);

  const links = [
    {
      link: '#',
      name: 'Miembros de la organización',
    },
  ];

  const goTo = (routeName) => {
    if ( location.pathname === routeName ) return;
    history.push(routeName);
  }

  const content = {
    type: LIST_WIDGET_CONTENT_TYPE.orgUsers,
    content: orgUsers,
    contentType: MemberContentType,
  }

  if (isBrowser) {
    return (
      <div className='main-content'>
        <Links links={ links }/>
        <ListWidget content={ content } />
      </div>
    );
  }

  return(
    <MobileView>
      <div className='main-content'>
        <div className="mobile-title">
          <IconAdmin />
          USUARIOS
        </div>
        <div className="mobile-content-content">
          <ListWidget content={ content } />
          <div className="list-container" style={ { width: "67%" } } >
            <Button className="move-to-bottom-mobile button-purple" onClick={() => goTo(ROUTES.LOGGED_HOME)}>
              <Arrow />
            </Button>
          </div>
        </div>
      </div>
    </MobileView>
  )
};
