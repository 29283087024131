import React, { Component } from "react";
import { connect } from "react-redux";
import { getOrgUsers, NewMeetingGuest, setMeetingModal } from "../../actions";
import "./styles/Modal.css"
import "./styles/AddMeetingGuestModal.css"
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import { Button, ListWidget } from "..";
import { setAddMeetingGuestModal } from "../../actions";
import { USER_TYPE } from "../../constants";

const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalAddMeetingGuest,
        selectedProject: state.ProjectReducer.selectedProject,
        orgUsers: state.OrganizationReducer.orgUsers,
    };
}

const initialState =  {
    name: '',
    description: '',
    guestId: [],
    disabled: false,
    guests: [],
    newGuests:[],
};

class AddMeetingGuestModal extends Component{
    
    constructor(props){
        super(props);
        this.state = initialState;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
        this.getMembers = this.getMembers.bind(this);
    }


    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
        this.getMembers();
    }

    handleChange(event) {
        if (this.state.disabled)return;
        this.setState({[event.target.name]: event.target.value});
    }

    resetState(){
        this.setState(initialState);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({disabled:true});
        const onSuccess = ()=>{
            this.setState({
                disabled:false
            });
            this.closeModal();
        };
        const onFailure = (error)=>{
            console.error(error.fieldErrors);
            this.setState({disabled:false});
        };
        const newGuests = this.props.orgUsers.filter(({id}) => this.state.guestId.includes(id));
        this.props.dispatch(NewMeetingGuest(this.state.guestId, newGuests)).then(onSuccess).catch(onFailure);
    }

    closeModal(){
        this.props.dispatch(setAddMeetingGuestModal(false)).then(this.resetState);
        this.props.dispatch(setMeetingModal(true));
    }

    getMembers(){
        this.props.dispatch(getOrgUsers());
    }

    // Falta implementar
    onClickOutside(event) {
        if (this.state.disabled && this.modal.contains(event.target)) return;
        this.closeModal();
    };

    onSelected = (childData) =>{
        this.setState({guestId: childData});
    }

    render(){
        var modalState = 'add-modal ';
        modalState += this.props.isVisible? 'modal-open':'modal-closed';

        const { selectedProject } = this.props;

        const GUESTS = {
            type: "GUESTS",
            isSelectionList: true,
            content: [],
        };

        this.props.isVisible && this.props.orgUsers.forEach((orgUser) => {
            if (orgUser.type === USER_TYPE.GUEST || orgUser.type === USER_TYPE.MAINTAINER) {
                const dateObj = new Date(orgUser.createdAt);
                let creationDate = (dateObj.getFullYear() + "-" + (dateObj.getMonth() + 1)+ "-" + (dateObj.getDate()));
                GUESTS.content.push(
                    {
                        id: orgUser.id,
                        itemName: orgUser.User.username,
                        creationDate,
                        description: orgUser.User.email,
                    }
                );
            }
        });

        return(
            <div className={modalState}>
                <div className="meeting-guest-modal">
                    <div id="MyModal" className="modal-content" style={{position:"relative"}}>
                        <Button className="modal-button" onClick={this.closeModal}>
                            <Close/>
                        </Button>
                        <div className="project-header-3" style={{borderTopLeftRadius: "10px"}}>
                            <div className="project-logo"></div>
                            <div className="project-data">
                                <div className="project-name">{selectedProject? (selectedProject.name):("No name")}</div>
                                <div className="project-context">
                                    <span> Creada el:<b> {selectedProject && selectedProject.createdAt? (selectedProject.createdAt.substring(0,10)):("No Date")} </b></span>
                                    &nbsp;
                                    <span> Nombre del Proyecto: <b>{selectedProject? (selectedProject.name):("No Admin")}</b>{" "}</span>
                                </div>
                                <div className="project-description">
                                    {selectedProject? (selectedProject.description):("No description")}
                                </div>
                            </div>
                        </div>
                        
                        <form onSubmit={this.handleSubmit}>
                            
                            <div className="meeting-guest-list-content">
                                <ListWidget content={GUESTS} parentCallback={this.onSelected}/>
                            </div>
                            <div className="add-guest-button-box"> 
                                <input className="add-guest-button" type="submit" value="AGREGAR INVITADO" disabled={this.state.disabled}/>
                            </div>
                        </form> 
                </div>
                </div>
            </div>
        )
    }
}

const connectedAddMeetingGuestModal = connect(mapStateToProps)(AddMeetingGuestModal);

export { connectedAddMeetingGuestModal as AddMeetingGuestModal };