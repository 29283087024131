import { PROJECT_ACTIONS, ORG_ACTIONS } from "../constants";
import { ProjectService } from "../services";

export const getProjects = () => async (dispatch) => {
  try {
    const projects = await ProjectService.getProjects();
    if (!projects) {
      throw new Error("projects not found.");
    }
    dispatch({
      type: PROJECT_ACTIONS.GET_PROJECTS,
      payload: projects,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getViews = () => async (dispatch) => {
    try {
        var total_views = [];
        const projects = await ProjectService.getProjects();
        if (!projects) {
            throw new Error("projects not found.")
        }
        else{
            for (const project of projects){
                try{
                    const views = await ProjectService.getProjectViews(project.id);
                    if (!views) {
                        throw new Error("views not found.")
                    }
                    total_views = [...total_views, ...views];
                }
                catch (error) {
                console.error(error);
                throw error;
            }}
            dispatch({
                type: PROJECT_ACTIONS.GET_VIEWS,
                payload: total_views,
            });
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const addProject = (projectData) => async (dispatch) => {
  try {
    const project = await ProjectService.addProject(projectData);
    if (!project) {
      throw new Error("projects not created.");
    }
    dispatch({
      type: PROJECT_ACTIONS.ADD_PROJECT,
      payload: project,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjectsAssigned = (mantainerId) => async (dispatch) => {
  try {
    const projects = await ProjectService.getProjectsAssigned(mantainerId);
    if (!projects) {
      throw new Error("projects not found.");
    }
    dispatch({
      type: PROJECT_ACTIONS.GET_PROJECTS_ASSIGNED,
      payload: projects,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const selectProject = (projectId) => async (dispatch) => {
  try {
    dispatch({
      type: PROJECT_ACTIONS.SELECT_PROJECT,
      payload: projectId,
    });
  } catch (error) {
    throw error;
  }
};

export const getProject = (projectId) => async (dispatch) => {
  try {
    const project = await ProjectService.getProject(projectId);
    dispatch({
      type: PROJECT_ACTIONS.GET_PROJECT,
      payload: project,
    });
  } catch (error) {
    throw error;
  }
};

export const getProjectMaintainers = (projectId) => async (dispatch) => {
  try {
    const maintainers = await ProjectService.getProjectMaintainers(projectId);
    if (!maintainers) {
      throw new Error("Mantainers not found.");
    }
    dispatch({
      type: PROJECT_ACTIONS.GET_PROJECT_MAINTAINERS,
      payload: maintainers,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjectMeetings = (projectId) => async (dispatch) => {
  try {
    const meetings = await ProjectService.getProjectMeetings(projectId);
    dispatch({
      type: PROJECT_ACTIONS.GET_PROJECT_MEETINGS,
      payload: meetings,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addProjectMaintainer =
  (projectId, maintainerId) => async (dispatch) => {
    try {
      const maintainers = [];
      for (const element of maintainerId["maintainerId"]) {
        const dict = { maintainerId: element };
        const maintainer = await ProjectService.addProjectMaintainer(
          projectId,
          dict
        );
        if (!maintainer) {
          throw new Error("Mantainer not found.");
        }
        maintainers.push(maintainer);
      }
      dispatch({
        type: PROJECT_ACTIONS.ADD_PROJECT_MAINTAINER,
        payload: maintainers,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const deleteProject = (projectId) => async (dispatch) => {
  try {
    await ProjectService.deleteProject(projectId);
    // if(!deletedUser){
    //     throw new Error(`user ${userId} not deleted`);
    // }
    dispatch({
      type: PROJECT_ACTIONS.DELETE_PROJECT,
      payload: projectId,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateProject = (projectId, projectData) => async (dispatch) => {
  try {
    var isResolved = false;
    let p = new Promise(() => {
      ProjectService.updateProject(projectId, projectData);
    });
    const info = await ProjectService.updateProject(projectId, projectData);
    p.then(function () {
      isResolved = true;
    });
    if (!info.updatedData && isResolved) {
      throw new Error("project not updated.");
    }
    dispatch({
      type: PROJECT_ACTIONS.UPDATE_PROJECT,
      payload: info.updatedData,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteProjectMaintainer =
  (projectId, maintainerId) => async (dispatch) => {
    try {
      await ProjectService.deleteProjectMaintainer(projectId, maintainerId);
      // if(!deletedUser){
      //     throw new Error(`user ${userId} not deleted`);
      // }
      dispatch({
        type: PROJECT_ACTIONS.DELETE_PROJECT_MAINTAINER,
        payload: maintainerId,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getProjectMeetingCategories = (projectId) => async (dispatch) => {
  try {
    const categories = await ProjectService.getProjectMeetingCategories(
      projectId
    );
    if (!categories) {
      throw new Error("Mantainers not found.");
    }
    dispatch({
      type: PROJECT_ACTIONS.GET_PROJECT_MEETING_CATEGORIES,
      payload: categories,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addProjectMeetingCategory =
  (projectId, categoryData) => async (dispatch) => {
    try {
      const category = await ProjectService.addProjectMeetingCategory(
        projectId,
        categoryData
      );
      if (!category) {
        throw new Error("Category not found.");
      }
      dispatch({
        type: PROJECT_ACTIONS.ADD_PROJECT_MEETING_CATEGORY,
        payload: category,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getProjectViews = (projectId) => async (dispatch) => {
  try {
    const views = await ProjectService.getProjectViews(projectId);
    dispatch({
      type: PROJECT_ACTIONS.GET_PROJECT_VIEWS,
      payload: views,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjectMeeting = (projectId, meetingId) => async (dispatch) => {
  try {
    const meeting = await ProjectService.getProjectMeeting(
      projectId,
      meetingId
    );
    meeting.id=meetingId
    if (!meeting) {
      throw new Error("Meeting not found.");
    }
    dispatch({
      type: PROJECT_ACTIONS.GET_PROJECT_MEETING,
      payload: meeting,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProjectMeetingAnnotationInstances =
  (projectId, meetingId) => async (dispatch) => {
    try {
      const instances =
        await ProjectService.getProjectMeetingAnnotationInstances(
          projectId,
          meetingId
        );
      if (!instances) {
        throw new Error("Instances not found.");
      }
      dispatch({
        type: PROJECT_ACTIONS.GET_PROJECT_MEETING_ANOTATION_INSTANCES,
        payload: instances,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getProjectMeetingGuests =
  (projectId, meetingId) => async (dispatch) => {
    try {
      const guests = await ProjectService.getProjectMeetingGuests(
        projectId,
        meetingId
      );
      if (!guests) {
        throw new Error("Meeting not found.");
      }
      dispatch({
        type: PROJECT_ACTIONS.GET_PROJECT_MEETING_GUESTS,
        payload: guests,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getProjectMeetingViews =
  (projectId, meetingId) => async (dispatch) => {
    try {
      const views = await ProjectService.getProjectMeetingViews(
        projectId,
        meetingId
      );
      if (!views) {
        throw new Error("Meeting not found.");
      }
      dispatch({
        type: PROJECT_ACTIONS.GET_PROJECT_MEETING_VIEWS,
        payload: views,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const resetProjectInfo = () => async (dispatch) => {
  dispatch({
    type: PROJECT_ACTIONS.RESET_PROJECT_INFO,
    payload: [],
  });
};

export const getProjectAnnotations = (projectId) => async (dispatch) => {
  try {
    const annotations = await ProjectService.getProjectAnnotations(projectId);
    dispatch({
      type: PROJECT_ACTIONS.GET_PROJECT_ANNOTATIONS,
      payload: annotations,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addProjectMeetingGuests =
  (projectId, meetingId, guestsIds) => async (dispatch) => {
    try {
      const guests = [];
      for (const element of guestsIds) {
        const dict = { orgUserId: element.id };
        const guest = await ProjectService.addProjectMeetingGuests(
          projectId,
          meetingId,
          dict
        );
        if (!guest) {
          throw new Error("Guest not found.");
        }
        guests.push(guest);
      }
      dispatch({
        type: PROJECT_ACTIONS.ADD_PROJECT_MEETING_GUESTS,
        payload: guests,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const addProjectMeetingViews =
  (projectId, meetingId, selectedViews) => async (dispatch) => {
    try {
      const views = [];
      for (const element of selectedViews) {
        const dict = { visualReferenceId: element.id };
        const view = await ProjectService.addProjectMeetingViews(
          projectId,
          meetingId,
          dict
        );
        if (!view) {
          throw new Error("View not found.");
        }
        views.push(view);
      }
      dispatch({
        type: PROJECT_ACTIONS.ADD_PROJECT_MEETING_VIEWS,
        payload: views,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const addProjectImport = (projectId, data) => async (dispatch) => {
  try {
    const importCategory = await ProjectService.addProjectImport(
      projectId,
      data
    );
    if (!importCategory) {
      throw new Error("Import doesn't work.");
    }
    dispatch({
      type: PROJECT_ACTIONS.ADD_PROJECT_IMPORT,
      payload: importCategory,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addProjectMeetingNotes =
  (projectId, meetingId, notesIds) => async (dispatch) => {
    try {
      const notes = [];
      for (const element of notesIds) {
        const dict = { visualReferenceId: element.id };
        const note = await ProjectService.addProjectMeetingNotes(
          projectId,
          meetingId,
          dict
        );
        if (!note) {
          throw new Error("Note not found.");
        }
        notes.push(note);
      }
      dispatch({
        type: PROJECT_ACTIONS.ADD_PROJECT_MEETING_NOTES,
        payload: notes,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const resetMeeting = () => async (dispatch) => {
  dispatch({
    type: PROJECT_ACTIONS.RESET_MEETING,
    payload: [],
  });
};

export const resetMarkerInfo = () => async (dispatch) => {
  dispatch({
    type: PROJECT_ACTIONS.RESET_MARKER_INFO,
    payload: []
  })
  dispatch({
    type: ORG_ACTIONS.RESET_MARKER_INFO,
    payload: null
  })
}

export const deleteProjectView = (projectId, viewId, viewVersionId) => async (dispatch) => {
  try {
    const viewVersion = await ProjectService.deleteProjectView(projectId, viewId, viewVersionId);
    dispatch({
      type: PROJECT_ACTIONS.DELETE_PROJECT_VIEW,
      payload: viewVersion,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setAccessTypeDisplayed = (accessType) => async (dispatch) => {
  dispatch({
    type: PROJECT_ACTIONS.SET_ACCESS_TYPE_DISPLAYED,
    payload: accessType,
  });
};
