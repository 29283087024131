import React from "react";
import "./styles/AnnotationInstanceItem.css";
import { setAnnotationModal, getAnnotationInstance } from "../actions";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ImgIcon } from "./Icons/icono_img.svg";
import { ReactComponent as AudioIcon } from "./Icons/icono_audio.svg";

export const AnnotationInstanceItem = ({
    instanceId,
    instanceType,
    annotationInstance,
    instanceColor,
    hasImage,
    hasAudio,
    finished }) => {
  const dispatch = useDispatch();
  const meeting = useSelector((state) => state.ProjectReducer.selectedProjectMeeting);

  const handleClick = ({isValid}) => {
    if (!isValid) return;
    const projectId = meeting.Project.id;
    dispatch(getAnnotationInstance( projectId, meeting.id, instanceId)).then(
      dispatch(setAnnotationModal(true))
    );
  };

  return (
    <div className="instance-container">
      <div className="instance-content">
        <div className="instance-left-content">
          <div className={ `circle filter-${ instanceColor }` } />
          {instanceType}
        </div>
        <div className="instance-right-content">
          <div className={ `button svg-container ${ hasImage ? instanceColor : "grey" }` }>
            <ImgIcon onClick={ () => handleClick({ isValid: hasImage }) }/>
          </div>
          <div className={ `button svg-container ${ hasAudio ? instanceColor : "grey" }` }>
            <AudioIcon onClick={ () => handleClick({ isValid: hasAudio }) }/>
          </div>
        </div>
      </div>
      <hr className={ `instance-divider${ finished ? "-green" : "" }` }></hr>
    </div>
  );
};
