import { useDispatch, useSelector } from "react-redux";
import { setAccessTypeDisplayed } from "../../../../actions";
import { ACCESS_TYPE } from "../../../../constants";
import { CheckAccessTypeAR, CheckAccessTypeMR, CheckAccessTypeVR } from "../../../../helpers";
import { Button } from "../../../Button";

import './styles/Settings.css';

export const ViewsSettings = () => {

  const dispatch = useDispatch();

  const accessTypeDisplayed = useSelector((state) => (state.ProjectReducer.accessTypeDisplayed));

  let isVRchecked = CheckAccessTypeVR(accessTypeDisplayed);
  
  let isARchecked = CheckAccessTypeAR(accessTypeDisplayed); 

  let isMRchecked = CheckAccessTypeMR(accessTypeDisplayed);

  const inactiveClassName = 'inactive';

  const getAccessType = (isAR, isVR, isMR) => {
    if (isAR) {
      if (isVR) {
        if (isMR) {
          return ACCESS_TYPE.AR_VR_MR;
        }
        return ACCESS_TYPE.AR_VR;
      }
      if (isMR) {
        return ACCESS_TYPE.AR_MR;
      }
      return ACCESS_TYPE.AR;
    }
    if (isVR) {
      if (isMR) {
        return ACCESS_TYPE.VR_MR;
      }
      return ACCESS_TYPE.VR;
    }
    if (isMR) {
      return ACCESS_TYPE.MR;
    }
    return ACCESS_TYPE.NONE;
  }

  const toggleVRCheck = () => {
    let newAccessType = getAccessType(isARchecked, !isVRchecked, isMRchecked);
    dispatch(setAccessTypeDisplayed(newAccessType));
  };

  const toggleARCheck = () => {
    let newAccessType = getAccessType(!isARchecked, isVRchecked, isMRchecked);
    dispatch(setAccessTypeDisplayed(newAccessType));
  };

  const toggleMRCheck = () => {
    let newAccessType = getAccessType(isARchecked, isVRchecked, !isMRchecked);
    dispatch(setAccessTypeDisplayed(newAccessType));
  };

  return(
    <div className="settings-buttons-list">
      <Button className={ `${ isVRchecked ? '' : inactiveClassName }` } onClick={toggleVRCheck} >
        VR
      </Button>
      <Button className={ `${ isARchecked ? '' : inactiveClassName }` } onClick={toggleARCheck} >
        AR        
      </Button>
      <Button className={ `${ isMRchecked ? '' : inactiveClassName }` } onClick={toggleMRCheck} >
        MR
      </Button>
    </div>
  );
};
