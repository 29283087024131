import { React, useEffect, useState } from 'react';
import './styles/ListWidget.css';
import './styles/List.css';
import { MiniButton } from '../components';
import { GenericContentType } from './ListWidget/contentType/GenericContentType';

export const ListWidget = ({ 
  content, 
  parentCallback, 
  color,
  fromMeeting,
}) => {

  if (!content) content = {};
  if (!content.content) content.content = [];

  const [searchFilter, setSearchFilter] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [optPos, SetOptPos] = useState({
    selectedItem: {},
    posX: 0,
    posY: 0,
    isVisible: false,
  })

  useEffect(()=>{
    parentCallback && parentCallback(selectedItems);
  }, [parentCallback, selectedItems]);

  const onItemOptionsClick = ({
    item,
    posX,
    posY,
  }) => {
    SetOptPos((prev) => ({
      ...prev,
      selectedItem: item,
      isVisible: true,
      posX,
      posY,
    }));
  };

  const setVisibility = (isVisible) => SetOptPos((prev)=>({
    ...prev,
    selectedItem: isVisible ? prev.selectedItem : {},
    isVisible,
  }));

  const AddItemToSelection = (item) => {
    setSelectedItems((prev) => ([...prev, item]));
  };

  const RemoveItemFromSelected = (item) => {
    setSelectedItems((prev) => (prev.filter(selectedItem => item !== selectedItem)));
  };

  const headerColor = color? color : "";

  // content.contentType = content.contentType ?? {};
  const contentType = { 
    ...GenericContentType,
    ...content.contentType??{}, 
  };

  return (
    <div className="list-container"> 
      <div className="list-header" id={headerColor}>
        <div className="list-info">
          <h3>
            <contentType.RenderTitle type={ content.type } />
          </h3>
          <span className="left-line">
            <contentType.RenderIcon type={ content.type } />
          </span>
          <span> {content.content ? content.content.length ?? 0 : '-'} </span>
          <contentType.RenderSettings />
        </div>
        <div className="list-actions">
          <div className="search-box">
            <input
              className="search-input"
              type="text"
              placeholder="Buscar..."
              onChange={(event) => {
                setSearchFilter(event.target.value);
              }}
            />
          </div>
          <MiniButton type="filter" />
          {
            content.isSelectionList ? '' : (
              <contentType.RenderActions accessType={content.accessType} type={content.type} />
            )
          }
          <contentType.ItemOptions 
            posX={ optPos.posX }
            posY={ optPos.posY }
            isVisible={ optPos.isVisible }
            item={ optPos.selectedItem }
            setVisibility={ setVisibility }
          />
        </div>
      </div>

      <div className="list-content">
        <contentType.RenderList
          list={ content.content ?? [] }
          searchFilter={ searchFilter }
          type={ content.type }
          AddItemToSelection={ AddItemToSelection }
          RemoveItemFromSelected={ RemoveItemFromSelected } 
          isSelectionList={ content.isSelectionList }
          fromMeeting={ fromMeeting }
          onItemOptionsClick={ onItemOptionsClick }
        />
      </div>
    </div>
  );
};
