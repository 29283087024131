import React, { Component } from "react";
import { connect } from "react-redux";
import { getUsersOrganizations } from "../actions";
import { withRouter } from "react-router";
import './styles/MyInfo.css'

const mapStateToProps = state => {
    return {
        currentUser : state.UserReducer.currentUser,
        organizations : state.UserReducer.organizations,
        orgUser : state.UserReducer.orgUser,
    }
}

class MyInfo extends Component{

    constructor(props){
        super(props);
        this.SetOrganizationsList = this.SetOrganizationsList.bind(this);
        this.renderInfo = this.renderInfo.bind(this);
    }

    componentDidMount() {
        this.SetOrganizationsList();
      }

    SetOrganizationsList() {
        this.props.dispatch(getUsersOrganizations());
       }
    

    renderInfo(){
        if(this.props.currentUser){
            return(
                <div className="display-info-user">
                    <br/>
                        <img alt="" src="https://es.ai.org.mx/wp-content/uploads/2020/05/user-default.jpg"></img>
                    <br/>
                    <span className="profile-items">Nombre de Usuario</span>
                    <br/>
                    {this.props.currentUser.username}
                    <br/>
                    <br/>
                    <span className="profile-items">Email</span>
                    <br/>
                    {this.props.currentUser.email}
                    <br/>
                    <br/>
                    { this.props.orgUser ? 
                        <>
                        <span className="profile-items">Organización actual</span>
                        <br/>

                        {this.props.organizations.map((org, index) => (
                            org.id !== this.props.orgUser.orgId ?
                                <></>
                            : 
                                <span key={org.id}>{org.name}</span>))}
                        </>
                        : <></>
                    }
                </div>
            )
        }
        return;
    }

    render(){
        return(
            <div className='my-info'>
                {this.renderInfo()}
            </div>
        )
    }
}

const ConnectedMyInfo = connect(mapStateToProps)(MyInfo);

const MyinfoWithRouter = withRouter(ConnectedMyInfo);

export { MyinfoWithRouter as MyInfo };