import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  closeAll,
  logout,
  setVisibility,
  toggleVisibility,
  orgLogout,
} from "../actions";
import { ROUTES } from "../constants";
import { Button } from "./Button";
import { USER_TYPE } from "../constants";

import { ReactComponent as RedicoLogo } from "./Icons/isotipo_redico.svg";
import { ReactComponent as LogoHeader } from "./svg/logo_header.svg";

import "./styles/Header.css";

const mapStateToProps = (state) => {
  return {
    currentUser: state.UserReducer.currentUser,
    orgUser: state.UserReducer.orgUser,
    organizations: state.UserReducer.organizations,
  };
};

const initialState = {
  userOptionsIsActive: false,
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.toggleUserOptions = this.toggleUserOptions.bind(this);
    this.setUserOptions = this.setUserOptions.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.logout = this.logout.bind(this);
    this.goTo = this.goTo.bind(this);
    this.orgLogout = this.orgLogout.bind(this);
  }

  componentDidMount() {
    if (!this.props.currentUser) {
      this.goTo(ROUTES.SIGN_IN);
      return;
    }
    document.addEventListener("mouseup", this.handleMouseUp);
    this.sideBar = document.getElementsByClassName("side-panel")[0];
    this.navBar = document.getElementsByClassName("header-logo")[0];

    if (this.sideBar && this.navBar) {
      document.addEventListener("click", this.handleClick);
    }
  }

  componentDidUpdate() {
    if (!this.props.currentUser) {
      this.goTo(ROUTES.SIGN_IN);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleMouseUp);
    if (this.sideBar && this.navBar) {
      document.removeEventListener("click", this.handleClick);
    }
  }

  goTo(routeName) {
    this.setUserOptions(false);
    if (this.props.location.pathname === routeName) {
      return;
    }
    this.props.history.push(routeName);
    this.props.dispatch(closeAll(true));
    this.props.dispatch(setVisibility(false));
  }

  logout() {
    this.props.dispatch(logout()).then(() => {
      this.goTo(ROUTES.HOME);
    });
  }

  orgLogout() {
    this.props.dispatch(orgLogout()).then(() => {
      this.goTo(ROUTES.ORGANIZATIONS);
    });
  }

  handleClick(event) {
    if (
      !this.sideBar.contains(event.target) &&
      !this.navBar.contains(event.target)
    ) {
      this.props.dispatch(setVisibility(false));
    }
  }

  handleMouseUp(event) {
    if (
      typeof event.target.className === "string" &&
      !event.target.className.includes("header-mouse-up")
    ) {
      this.setUserOptions(false);
    }
  }

  toggleUserOptions() {
    this.setState({ userOptionsIsActive: !this.state.userOptionsIsActive });
  }

  setUserOptions(visibility) {
    this.setState({ userOptionsIsActive: visibility });
  }

  render() {
    return (
      <div className="header">
        <div className="header-logo" onClick={ () => this.props.dispatch(toggleVisibility()) } >
          <RedicoLogo fill="current" />
        </div>
        <div className="logo-header">
          <LogoHeader fill="current" />
        </div>
        <div className="header-user-info">
          <div className="header-user-icon"></div>
          <div>
            <div className="header-user-name">
              <span>
                {" "}
                {this.props.currentUser ? this.props.currentUser.username : "User Name"}
                {" "}
              </span>
              <span>
                {this.props.orgUser ? USER_TYPE[this.props.orgUser.accountType] : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="header-user-options-button">
          <Button className="vertical-button header-mouse-up" onClick={() => this.toggleUserOptions()} >
            ...
          </Button>
          <div className={`header-user-options header-mouse-up ${ this.state.userOptionsIsActive ? "active" : "hidden" }`} >
            <Button className="button-vertical header-mouse-up" onClick={() => this.goTo(ROUTES.MY_ACCOUNT)} >
              Mi cuenta
            </Button>
            {
              this.props.orgUser !== null && this.props.organizations.length !== 1 ? (
              <Button className="button-vertical header-mouse-up" onClick={this.orgLogout} >
                Organizaciones
              </Button>
              ) : (
              <></>
              )
            }

            <Button className="button-vertical header-mouse-up" onClick={this.logout}>
              Cerrar Sesión
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const ConnectedHeader = connect(mapStateToProps)(Header);

const HeaderWithRouter = withRouter(ConnectedHeader);

export { HeaderWithRouter as Header };
