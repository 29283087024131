import { MEETING_ACTIONS, PROJECT_ACTIONS } from "../constants";
import { MeetingService } from "../services";

export const getMeetings = () => async (dispatch) => {
  try {
    const meetings = await MeetingService.getMeetings();
    dispatch({
      type: MEETING_ACTIONS.GET_USER_MEETINGS,
      payload: meetings,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addMeeting = (projectId, meetingData) => async (dispatch) => {
  try {
    const meeting = await MeetingService.addMeeting(projectId, meetingData);
    if (!meeting) {
      throw new Error("meeting not created.");
    }
    dispatch({
      type: MEETING_ACTIONS.ADD_MEETING,
      payload: meeting,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const updateMeeting = (projectId, meetingId, meetingData) => async (dispatch) => {
  try {
    const meeting = await MeetingService.updateMeeting(projectId, meetingId, meetingData);
    if (!meeting) {
      throw new Error("meeting not created.");
    }
    dispatch({
      type: PROJECT_ACTIONS.PUT_PROJECT_MEETING,
      payload: meeting,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const selectMeeting = (projectId, meetingId) => async (dispatch) => {
  try {
    const meeting = await MeetingService.getMeeting(projectId, meetingId);
    if(!meeting){
        throw new Error(`meeting ${meetingId} does not exists.`);
    }
    dispatch({
      type: MEETING_ACTIONS.GET_PROJECT_MEETING,
      payload: meeting,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deselectMeeting = () => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ACTIONS.GET_PROJECT_MEETING,
      payload: null,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMeeting = (projectId, meetingId) => async (dispatch) => {
  try {
    await MeetingService.deleteMeeting(projectId, meetingId);
    // if(!deletedUser){
    //     throw new Error(`user ${userId} not deleted`);
    // }
    dispatch({
      type: MEETING_ACTIONS.DELETE_MEETING,
      payload: meetingId,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const NewMeetingGuest = (guestIds, guests) => async (dispatch) => {
  let listas_invitados = {
    "guestIds": guestIds,
    "guests": guests,
  }
  try {
    dispatch({
      type: MEETING_ACTIONS.NEW_MEETING_GUEST,
      payload: listas_invitados,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const NewMeetingView = (viewIds, views) => async (dispatch) => {
  let selectedViews = {
    "viewIds": viewIds,
    "views": views,
  }
  try {
    dispatch({
      type: MEETING_ACTIONS.NEW_MEETING_VIEWS,
      payload: selectedViews,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const NewMeetingNote = (noteIds, notes) => async (dispatch) => {
  let listas_anotaciones = {
    "noteIds": noteIds,
    "notes": notes,
  }
  try {
    dispatch({
      type: MEETING_ACTIONS.NEW_MEETING_NOTES,
      payload: listas_anotaciones,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const ResetMeetingFormAction = () => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ACTIONS.RESET_MEETING_FORM,
      payload: '',
    });    
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const resetMeetings = () => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ACTIONS.RESET_MEETINGS,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resetCreateMeeting = () => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ACTIONS.RESET_CREATE_MEETINGS,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setMeetingCategory = (selectedCategoryId) => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ACTIONS.SET_MEETING_CATEGORY,
      payload: selectedCategoryId,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMeetingListView = (viewId) => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ACTIONS.DELETE_MEETING_LIST_VIEW,
      payload: viewId,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMeetingListGuest = (guestId) => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ACTIONS.DELETE_MEETING_LIST_GUEST,
      payload: guestId,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMeetingListNote = (noteId) => async (dispatch) => {
  try {
    dispatch({
      type: MEETING_ACTIONS.DELETE_MEETING_LIST_NOTE,
      payload: noteId,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};