import { React, useState, useRef, useEffect } from "react";
import "./styles/ProjectSection.css";
import { Button } from "./Button";
import { MODAL_ACTION, PROJECT_SECTION_TABS as VIEW_TYPE } from "../constants";
import { ProjectSidePanel } from "./ProjectSidePanel";
import { ListWidgetContainer } from "./ListWidgetContainer";
import {
  getProjectMaintainers,
  getProjectViews,
  getProjectMeetings,
  getProjectAnnotations,
} from "../actions";
import "./styles/Header.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteConfirmModalProject } from "./modals/DeleteConfirmModalProject";
import { UpdateModalProject } from "./modals/UpdateModalProject";
import { USER_TYPE } from "../constants"
import { MeetingModal } from "./modals/MeetingModal";
import {
  MobileView,
  isBrowser,
} from "react-device-detect";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";
import { MeetingContentType } from "./ListWidget/contentType/MeetingContentType";
import { MaintainersContentType } from "./ListWidget/contentType/MaintainersContentType";
import { MarkersContentType } from "./ListWidget/contentType/MarkersContentType";
import { ViewsContentType } from "./ListWidget/contentType/ViewsContentType";
import { DataContentType } from "./ListWidget/contentType/DataContentType";
import { getProjectAttachedFileList } from "../actions/AttachedFileActions";

export const ProjectSection = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const history = useHistory();

  // store references
  const selectedProject = useSelector((state) => state.ProjectReducer.selectedProject);
  const selectedProjectMaintainers = useSelector((state) => state.ProjectReducer.selectedProjectMaintainers);
  const meetings = useSelector((state) => state.ProjectReducer.meetings);
  const annotations = useSelector((state) => state.ProjectReducer.annotations);
  const orgUser = useSelector((state) => state.UserReducer.orgUser);
  const projectViews = useSelector((state) => state.ProjectReducer.projectViews);
  const attachedFileList = useSelector((state) => state.AttachedFileReducer.attachedFileList);

  // state
  const [isActive, setActive] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [view, setView] = useState("meetings");

  const projectName = selectedProject ? selectedProject.name : "No Name";
  const creationDate = selectedProject ? selectedProject.createdAt.substr(0,10) : "No Date";
  const projectDescription = selectedProject ? selectedProject.description : "No description";
  
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if ((isActive && ref.current && !ref.current.contains(event.target))) {
          setActive(false)
        }
      };
      document.addEventListener("mousedown", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
      };
    },
    [isActive]
  );

  useEffect(function SetMeetingListView() {
    if( view !== VIEW_TYPE.MEETINGS) return;
    setWidgets([
      {
        type: LIST_WIDGET_CONTENT_TYPE.MEETINGS,
        content: meetings,
        contentType: MeetingContentType,
      },
    ]);
  }, [view, meetings]);

  useEffect(function SetAnnotationListView() {
    if( view !== VIEW_TYPE.MARKERS) return;
    setWidgets([
      {
        type: LIST_WIDGET_CONTENT_TYPE.MARKERS,
        content: annotations,
        contentType: MarkersContentType,
      },
    ]);
  }, [view, annotations]);

  const handleDelete = () => {
    dispatch({
        type: MODAL_ACTION.SET_DELETE_PROJECT_MODAL,
        payload: true
    });
  }

  const handleEdit = () => {
    dispatch({
      type: MODAL_ACTION.SET_UPDATE_PROJECT_MODAL,
      payload: true
    });
  }

  const toggleProjectOptions = () => {
    setActive(!isActive);
  };

  useEffect(function SetMaintainerListView() {
    if( view !== VIEW_TYPE.MAINTAINERS) return;
    setWidgets([
      {
        type: LIST_WIDGET_CONTENT_TYPE.MAINTAINERS,
        content: selectedProjectMaintainers,
        contentType: MaintainersContentType
      },
    ]);
  }, [selectedProjectMaintainers, view]);

  useEffect(function SetViewListView() {
    if ( view !== VIEW_TYPE.VIEW_3D) return;
    setWidgets([
      {
        type: LIST_WIDGET_CONTENT_TYPE.VIEW_3D,
        content: projectViews,
        contentType: ViewsContentType,
      },
    ]);
  }, [projectViews, view])
  
  useEffect(function SetDocumentListView() {
    if ( view !== VIEW_TYPE.DOCUMENTS) return;
    setWidgets([
      {
        type: LIST_WIDGET_CONTENT_TYPE.DATA,
        content: attachedFileList,
        contentType: DataContentType,
      },
    ]);
  }, [attachedFileList, view])

  useEffect(function ComponentDidMount() {
    handleChangeView(VIEW_TYPE.MEETINGS);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeView = (view) => {
    if (!selectedProject) return;
    switch (view) {
      case VIEW_TYPE.VIEW_3D:
        dispatch(getProjectViews(selectedProject.id)).then(() => {
          setView(VIEW_TYPE.VIEW_3D);
        });
        break;
      case VIEW_TYPE.DOCUMENTS:
        dispatch(getProjectAttachedFileList(selectedProject.id)).then(() => {
          setView(VIEW_TYPE.DOCUMENTS);
        });
        break;
      case VIEW_TYPE.MARKERS:
        dispatch(getProjectAnnotations(selectedProject.id)).then(() => {
          setView(VIEW_TYPE.MARKERS);
        })
        break;
      case VIEW_TYPE.MAINTAINERS:
        dispatch(getProjectMaintainers(selectedProject.id)).then(() => {
          setView(VIEW_TYPE.MAINTAINERS);
        });
        break;
      case VIEW_TYPE.MEETINGS:
      default :
        dispatch(getProjectMeetings(selectedProject.id)).then(() => {
          setView(VIEW_TYPE.MEETINGS);
        });
        break;
    }
  };

  if (isBrowser) return (
      <div className="project-page">
        <div className="project-header">
          <div className="project-logo"></div>
          <div className="project-data">
            <div className="project-name">{ projectName }</div>
            <div className="project-context">
              <span>
                Creada el:<b> { creationDate } </b>
              </span>
              &nbsp;
            </div>
            <div className="project-description">
              {projectDescription}
            </div>
          </div>
          { 
            orgUser.accountType === USER_TYPE.ADMIN ?
              <>
                <Button className="move-right" onClick={toggleProjectOptions}>...</Button>
                <div ref={ref} className={`project-options project-mouse-up ${ isActive ? "active" : "hidden"}`} >
                  <Button className="button-vertical project-mouse-up" onClick={handleDelete} >
                    Eliminar proyecto
                  </Button >
                  <Button className="button-vertical project-mouse-up" onClick={handleEdit} >
                    Editar proyecto
                  </Button>
                </div>
                <UpdateModalProject history={history} />
                <DeleteConfirmModalProject history={history} />
              </>
            :  
              <></>
          }
        <MeetingModal  />
        </div>
        <div className="project-content">
          <ProjectSidePanel changeViewCallback={handleChangeView} />
          <ListWidgetContainer widgets={widgets} />
        </div>
      </div>
  )


  return (
    <MobileView>
      <div className="project-page-mobile">
        <div className="project-header-mobile">
          <div className="project-logo"></div>
          <div className="project-data">
            <div className="project-name">{ projectName }</div>
            <div className="project-context">
              <span>
                Creada el:<b> { creationDate } </b>
              </span>
              &nbsp;
            </div>
            <div className="project-description">
              {projectDescription}
            </div>
          </div>    
        </div>
        <div className="project-content-mobile">
          <ProjectSidePanel changeViewCallback={handleChangeView} />
          <ListWidgetContainer widgets={widgets} />
          {/* TO-DO: button to go back to project list on mobile */}
        </div>
      </div>
    </MobileView>
  );
};
