import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAttachedFileAction, uploadProjectAttachedFile } from "../../actions/AttachedFileActions";
import { FileType } from "../../constants/FileTypes";
import "./styles/ActResDataFormStyle.css"

export const AdjFileDataForm = ({
  onCancel,
  setActive,
  selectedItem,
}) => {

  const dispatch = useDispatch();

  const selectedProject = useSelector((state) => state.ProjectReducer.selectedProject);


  const [adjFile, setAdjFile] = useState(null);

  const handleAdjFileChange = (event) => {
    setAdjFile(event.target.files[0]);
  }

  const submitHandler = (event) => {
    event.preventDefault()
    if (!adjFile) return;
    if (setActive) setActive(false);
    if (selectedItem && selectedItem.id) {
      dispatch(UpdateAttachedFileAction(selectedProject.id, selectedItem.id, adjFile, {
        fileName: adjFile.name,
        fileType: FileType.Attachment,
      })).then(() => {
        if (onCancel) onCancel();
      }).catch(err => {
        console.log(err);
        if(setActive) setActive(true);
      });
      return;  
    }
    dispatch(uploadProjectAttachedFile(selectedProject.id, adjFile, {
      fileName: adjFile.name,
      fileType: FileType.Attachment,
    })).then(() => {
      if (onCancel) onCancel();
    }).catch(err => {
      console.log(err);
      if(setActive) setActive(true);
    })
  }

  return (
    <form className="act-res-data-form" onSubmit={submitHandler}>
      <label>
        <div>
          Archivo:
        </div>
        <input onChange={handleAdjFileChange} type="file" name="activities" id="activities"/>
      </label>

      <div className="spacer">

      </div>

      <div className="submit-cancel-options">
        <input className="cancel-button" type="button" value="Cancelar" onClick={onCancel} />
        <input className="submit-button" type="submit" value="Subir" />
      </div>
    </form>
  )
};