import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { closeAll, logout, setVisibility } from "../actions";
import { ROUTES } from "../constants";
import { Button } from "./Button";
import { USER_TYPE } from "../constants";

import { ReactComponent as IconProyects } from './svg/icono_proyectos.svg';
import { ReactComponent as IconAdmin } from './svg/iconos_adm_proyectos.svg';
import { ReactComponent as IconNotes } from './svg/icono_anotaciones.svg';
import { ReactComponent as IconConfig } from './svg/icono_ajustes.svg';
import { ReactComponent as IconHome } from './svg/icono_inicio.svg';
import { ReactComponent as IconMeetings } from './svg/icono_reuniones_virtuales.svg';
import { ReactComponent as IconGuests } from "../routes/svg/icono_invitado.svg";
import { ReactComponent as Downloads } from './Icons/download-svgrepo-com.svg';

import "./styles/SidePanel.css";

const mapStateToProps = (state) => {
  return {
    orgUser: state.UserReducer.orgUser,
  };
};

class SidePanel extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  goTo(routeName) {
    if (this.props.location.pathname === routeName) {
      return;
    }
    this.props.history.push(routeName);
    this.props.dispatch(closeAll(true));
    this.props.dispatch(setVisibility(false));
  }

  logout() {
    this.props.dispatch(logout()).then(() => {
      this.goTo(ROUTES.HOME);
    });
  }

  render() {
    return (
      <div
        className={`side-panel ${this.props.hidden ? "side-panel-hidden" : ""}`}
      >
        {/* <div className="side-panel-title" onClick={()=> this.goTo(ROUTES.MY_ACCOUNT)}> */}
        {/* <div className="side-panel-title" onClick={()=> this.goTo(ROUTES.MY_ACCOUNT)}>
                        <h1>RE</h1>
                    </div> */}

        {this.props.orgUser !== null ? (
          <>
            <Button
              className={`button-vertical ${
                this.props.location.pathname === ROUTES.LOGGED_HOME ? "selected" : ""
              }`}
              tooltip='Inicio'
              onClick={() => this.goTo(ROUTES.LOGGED_HOME)}
            >
              <IconHome height={40}/>
            </Button>
            <Button
              className={`button-vertical ${
                this.props.location.pathname === ROUTES.PROJECTS
                  ? "selected"
                  : ""
              }`}
              tooltip='Proyectos'
              onClick={() => this.goTo(ROUTES.PROJECTS)}
            >
              <IconProyects height={40}/>
            </Button>
            {this.props.orgUser.accountType === USER_TYPE.ADMIN ? (
              <Button
                className={`button-vertical ${
                  this.props.location.pathname === ROUTES.MY_ORG
                    ? "selected"
                    : ""
                }`}
                tooltip='Usuarios'
                onClick={() => this.goTo(ROUTES.MY_ORG)}
              >
                <IconAdmin height={40}/>
              </Button>
            ) : (
              <Button
                className={`button-vertical ${
                  this.props.location.pathname === ROUTES.MY_ORG
                    ? "selected"
                    : ""
                }`}
                tooltip='Usuarios'
                onClick={() => this.goTo(ROUTES.MY_ORG)}
              >
                <IconGuests height={40}/>
              </Button>
            )}
            {this.props.orgUser.accountType === USER_TYPE.ADMIN ? (
              <Button
                className={`button-vertical ${
                  this.props.location.pathname ===
                  ROUTES.MARKERS
                    ? "selected"
                    : ""
                }`}
                tooltip='Anotaciones'
                onClick={()=> this.goTo(ROUTES.MARKERS)}
              >
                <IconNotes height={40}/>
              </Button>
            ) : (
              <></>
            )}
            <Button
              className={`button-vertical ${
                this.props.location.pathname === ROUTES.DOWNLOADS ? "selected" : ""
              }`}
              tooltip='Descargas'
              onClick={()=> this.goTo(ROUTES.DOWNLOADS)}
            >
              <Downloads fill='current' height={40}/>
            </Button>
            <Button
              className={`button-vertical ${
                this.props.location.pathname === ROUTES.MEETINGS
                  ? "selected"
                  : ""
              }`}
              tooltip='Reuniones'
              onClick={() => this.goTo(ROUTES.MEETINGS)}
            >
              <IconMeetings height={40}/>
            </Button>
            {this.props.orgUser.accountType === USER_TYPE.ADMIN ? (
              <Button
                className={`button-vertical ${
                  this.props.location.pathname === "Settings" ? "selected" : ""
                }`}
                tooltip='Ajustes'
              >
                <IconConfig height={40}/>
              </Button>
            ) : (
              <></>
            )}
            <Button
              className="move-back"
              onClick={() => {
                this.goTo(ROUTES.LOGGED_HOME);
              }}
            >
              ⮜
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const connectedSidePanel = connect(mapStateToProps)(SidePanel);

const SidePanelWithRouter = withRouter(connectedSidePanel);

export { SidePanelWithRouter as SidePanel };
