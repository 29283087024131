import { React } from "react";
import "./styles/ListWidgetContainer.css";
import { ListWidget } from "./ListWidget";
import { ListWidgetMobile } from "./ListWidgetMobile";
import {
  isBrowser,
} from "react-device-detect";

export const ListWidgetContainer = ({ widgets, color }) => {
  return widgets.map((item, index) => (
    isBrowser ?
      <div key={index} className="widget-container">
        <ListWidget content={item} color={color}/>
      </div>
      :
      <div key={index} className="widget-container-mobile">
        <ListWidgetMobile content={item} color={color}/>
      </div>
  ));
}; 
