import React, { Component } from "react";
import { connect } from "react-redux";
import { NewMeetingNote, setMeetingModal } from "../../actions";
import "./styles/Modal.css"
import "./styles/AddMeetingNoteModal.css"
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import { Button, ListWidget } from "..";
import { setAddMeetingNoteModal } from "../../actions";
import { LIST_WIDGET_CONTENT_TYPE } from "../../constants/ListWidgetContentType";
import { MarkersContentType } from "../ListWidget/contentType/MarkersContentType";

const mapStateToProps = state => {
    return { 
        isVisible: state.ModalReducer.modalAddMeetingNote,
        selectedProject: state.ProjectReducer.selectedProject,
        selectedProjectAnnotationTypes: state.ProjectReducer.annotations,
    };
}

const initialState =  {
    name: '',
    description: '',
    noteId: [],
    disabled: false,
    notes: [],
    newNotes:[],
};

class AddMeetingNoteModal extends Component{
    
    constructor(props){
        super(props);
        this.state = initialState;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetState = this.resetState.bind(this);
    }


    componentDidMount(){
        if (!this.props.isVisible) {
            this.setState(initialState);
        }
    }

    handleChange(event) {
        if (this.state.disabled) {
            return;
        }
        var newState = this.state;
        newState[event.target.name] = event.target.value;
        this.setState(newState);
    }

    resetState(){
        this.setState(initialState);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({...this.state, disabled:true});
        const onSuccess = ()=>{
            this.closeModal();
        };
        const onFailure = (error)=>{
            console.error(error.fieldErrors);
            this.setState({...this.state, disabled:false});
        };
        const newAnnotationTypes = this.props.selectedProjectAnnotationTypes.filter(({id}) => this.state.noteId.includes(id));
        this.props.dispatch(NewMeetingNote(this.state.noteId, newAnnotationTypes)).then(onSuccess).catch(onFailure);
    }

    closeModal(){
        this.props.dispatch(setAddMeetingNoteModal(false)).then(this.resetState);
        this.props.dispatch(setMeetingModal(true));
    }

    // Falta implementar
    onClickOutside(event) {
        if (this.state.disabled && this.modal.contains(event.target)) return;
        this.closeModal();
    };

    SetSelectedAnnotationTypes = (AnnotationTypeIds) =>{
        this.setState({noteId: AnnotationTypeIds});
    }

    render(){
        var modalState = 'add-modal ';
        modalState += this.props.isVisible? 'modal-open':'modal-closed';

        const {selectedProject} = this.props;

        const MARKERS = {
            type: LIST_WIDGET_CONTENT_TYPE.MARKERS,
            isSelectionList: true,
            content: [],
            contentType: MarkersContentType,
        };
        if(this.props.isVisible && this.props.selectedProjectAnnotationTypes && this.props.selectedProjectAnnotationTypes.length !== 0 ){
            MARKERS.content = this.props.selectedProjectAnnotationTypes;
        }

        return(
                <div className={modalState}>
                    <div className="meeting-note-modal">
                        <div id="MyModal" className="modal-content">
                            <Button className="modal-button" onClick={this.closeModal}>
                                <Close/>
                            </Button>
                            <div className="project-header-3" style={{borderTopLeftRadius: "10px"}}>
                                <div className="project-logo"></div>
                                <div className="project-data">
                                    <div className="project-name">{selectedProject? (selectedProject.name):("No name")}</div>
                                    <div className="project-context">
                                        <span> Creada el:<b> {selectedProject && selectedProject.createdAt? (selectedProject.createdAt.substring(0,10)):("No Date")} </b></span>
                                        &nbsp;
                                        <span> Nombre del Proyecto: <b>{selectedProject? (selectedProject.name):("No Admin")}</b>{" "}</span>
                                    </div>
                                    <div className="project-description">
                                        {selectedProject? (selectedProject.description):("No description")}
                                    </div>
                                </div>
                                </div>
                            
                            <form onSubmit={this.handleSubmit}>
                                
                                <div className="meeting-note-list-content">
                                    <ListWidget content={MARKERS} parentCallback={this.SetSelectedAnnotationTypes}/>
                                </div>
                                <div className="add-note-button-box"> 
                                    <input className="add-note-button" type="submit" value="AGREGAR ANOTACIÓN" disabled={this.state.disabled}/>
                                </div>
                            </form> 
                    </div>
                   </div>
               </div>
        )
    }
}

const connectedAddMeetingNoteModal = connect(mapStateToProps)(AddMeetingNoteModal);

export { connectedAddMeetingNoteModal as AddMeetingNoteModal };