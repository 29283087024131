import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { OrgList } from "../components/OrgList";
import { Header } from "../components/Header";
import { getUsersOrganizations, logout } from "../actions";
import { ROUTES } from "../constants";

import "./styles/Redico.css";

const mapStateToProps = (state) => {
  return {
    organizations: state.UserReducer.organizations,
  };
};

class Organizations extends Component {
  constructor(props) {
    super(props);

    this.SetOrganizationsList = this.SetOrganizationsList.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
  }

  goTo(routeName) {
    if (this.props.location.pathname === routeName) {
      return;
    }
    this.props.history.push(routeName);
  }

  componentDidMount() {
    this.SetOrganizationsList();
  }

  logout() {
    this.props.dispatch(logout()).then(() => {
      this.goTo(ROUTES.HOME);
    });
  }

  SetOrganizationsList() {
    this.props.dispatch(getUsersOrganizations()).then(() => {
      if (this.props.organizations.length === 0) {
        this.logout();
      }
    }).catch((error) => {
      console.log(error);
    });
  }


  render() {

    return (
      <div className="fill-screen">
        <Header />
        <div className="main-content" selected="Organizations">
          <OrgList content={this.props.organizations} />
        </div>
      </div>
    );
  }
}

const connectedOrganizations = connect(mapStateToProps)(Organizations);

const OrganizationsWithRouter = withRouter(connectedOrganizations);

export { OrganizationsWithRouter as Organizations };
