import React from 'react';
import {FiSettings} from 'react-icons/fi';
import { MdUnfoldMore } from 'react-icons/md';
import { HiPaperClip } from 'react-icons/hi';
import { RiQuestionLine } from 'react-icons/ri';
import { BiSearch, BiSearchAlt2, BiFilter, BiAddToQueue } from 'react-icons/bi';
import './styles/MiniButton.css';

export function MiniButton({ type, onClick }) {
  let Icon;
  switch (type) {
    case "search":
      Icon = BiSearchAlt2;
      break;
    case "filter":
      Icon = BiFilter;
      break;
    case "create":
      Icon = BiAddToQueue;
      break;
    case "to_search":
      Icon = BiSearch;
      break;
    case 'config':
      Icon = FiSettings;
      break;
    case 'clip':
      Icon = HiPaperClip;
      break;
    case 'up_down_arrow':
      Icon = MdUnfoldMore;
      break;
    case 'loupe':
      Icon = BiSearchAlt2;
      break;
    case 'circle_question_mark':
      Icon = RiQuestionLine;
      break;
    default:
      Icon = "null";
      break;
  }
  return (
    <button type="button" className="mini-button" id={type} onClick={onClick}>
      <Icon size={25} />
    </button>
  );
}
