import React, { Component } from "react";
import { withRouter } from "react-router";

import { ROUTES } from "../constants";
import { SigninForm } from "../components";

import {ReactComponent as RedicoLogo } from '../components/Icons/logo_redico.svg';
import {ReactComponent as LoginLogo } from '../components/Icons/logo_login.svg';

import "./styles/SignUp.css";
import { connect } from "react-redux";
import { selectOrganization } from "../actions";

const mapStateToProps = (state) => {
    return {
      currentUser: state.UserReducer.currentUser,
      organizations: state.UserReducer.organizations,
    };
  };
class SignIn extends Component{

    goTo(routeName){
        if (this.props.organizations.length === 1) {
            const body = {
                orgId: this.props.organizations[0].id,
            };
            this.props.dispatch(selectOrganization(body)).then(() => { 
            this.props.history.push(ROUTES.LOGGED_HOME); 
            });
        }
        else {
            this.props.history.push(routeName);
        }
    }

    componentDidMount(){
        if ( this.props.location.state ) {
            if (this.props.location.state.notice){
                alert(this.props.location.state.notice)
            }
        }
    }

    render(){
        return(
            <div className='grid'>
                <div className='first-mid-row'>
                    <LoginLogo className='login-logo'/>
                </div>
                <div className='mid-grid'>
                    <SigninForm onSuccess={()=>this.goTo(ROUTES.ORGANIZATIONS)} /> 
                </div>
                <div className='third-third-row'>
                    <RedicoLogo className='redico-logo'/>
                </div>
            </div>
        )
    }
}


const connectedSignIn = connect(mapStateToProps)(SignIn);

const SignInWithRouter = withRouter(connectedSignIn);

export { SignInWithRouter as SignIn };