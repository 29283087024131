import { ORG_ACTIONS } from "../constants";

const initialState = {
    organization: null,
    orgUsers: [],
    orgMaintainers: [],
    orgMarkers: [],
    selectedAnnotationType: {},
};

const rootReducer = (state = initialState, action) => {
    const {type, payload} = action;
    
    switch (type) {
        case ORG_ACTIONS.UPDATE_ORG:
            return {
                ...state, 
                organization: payload, 
                };
        case ORG_ACTIONS.ORG_INFO:
            return {
                ...state, 
                organization: payload, 
                };
        case ORG_ACTIONS.GET_ORG_USERS:
            return {...state, orgUsers: payload};
        case ORG_ACTIONS.GET_ORG_MARKERS:
            return {...state, orgMarkers: payload};
        case ORG_ACTIONS.GET_ORG_MARKER:
            const versionCounter = {};
            for (let i = 0; i < payload.VisualReferences.length; i++) {
                const VisualReference = payload.VisualReferences[i];
                VisualReference.fileName = VisualReference.View3D.fileName; 
                if (!versionCounter[VisualReference.View3DId]) {
                    versionCounter[VisualReference.View3DId] = 0;
                }
                versionCounter[VisualReference.View3DId] += 1;
                VisualReference.versionIndex = `Versión ${ versionCounter[VisualReference.View3DId] }`;
            }
            return {...state, selectedAnnotationType: payload};
        case ORG_ACTIONS.DELETE_ORG:
            return {...state, orgUser: null, organization: null}
        case ORG_ACTIONS.NEW_MANTAINER:
            return {...state};
        case ORG_ACTIONS.NEW_GUEST:
            return {...state};
        case ORG_ACTIONS.GET_ORG_MAINTAINERS:
            let maintainers = [];
            let index = 0;
            payload.orgUsers.forEach((orgUser) => {
                if(orgUser.type === 2){
                const dateObj = new Date();
                let myDate = (dateObj.getFullYear() + "-" + (dateObj.getMonth() + 1)+ "-" + (dateObj.getDate()));
                maintainers[index] = {
                    id: orgUser.id,
                    itemName: orgUser.User.username,
                    creationDate: myDate,
                    description: orgUser.User.email,
                };
                index ++;
            }
        })
            return {...state, orgMaintainers: maintainers};
        case ORG_ACTIONS.RESET_MARKER_INFO:
            return {...state, selectedAnnotationType: initialState.selectedAnnotationType};
        case ORG_ACTIONS.RESET_ORGS:
            return initialState;
        default:
            return state;
    }
}

export default rootReducer;