import { ReactComponent as Projects } from '../../../routes/svg/icono_proyectos.svg';
import { ProjectList } from "./lists/ProjectList";

const { LIST_WIDGET_CONTENT_TYPE } = require("../../../constants/ListWidgetContentType");

const RenderList = function ({
  list,
  searchFilter,
  type,
  AddProjectToSelection,
  funcionProjectDescartado
}) {
  return (
    <ProjectList list={list} 
                 searchFilter={searchFilter} 
                 type={type} 
                 AddProjectToSelection={AddProjectToSelection} 
                 funcionProjectDescartado={funcionProjectDescartado} />
  )
};

export const AnnotationProjectContentType = {
  type: LIST_WIDGET_CONTENT_TYPE.PROJECTS,
  RenderList,
  RenderIcon: () => (<Projects />),
};
