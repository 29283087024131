import './styles/Modal.css';
import './styles/DataModal.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import { ReactComponent as DocIcon } from '../svg/icono_lecciones_aprendidas.svg';
import { Button } from "../Button";
import { SetAttachedFileDeleteModal } from '../../actions/ModalActions';
import { deleteAttachedFile } from '../../actions/AttachedFileActions';

const FILE_TYPE = {
  0: 'Actividades y Restricciones',
  1: 'Documento Adjunto',
}


export const AttachedFileDeleteModal = () => {

  const dispatch = useDispatch();

  const isVisible = useSelector((state) => (state.ModalReducer.attachedFileDeleteModalVisibility));
  const selectedItem = useSelector((state) => (state.ModalReducer.attachedFileModalSelectedItem));

  const closeModal = () => {
    dispatch(SetAttachedFileDeleteModal(false, {}));
  }

  const deleteSelectedItem = () => {
    if (!selectedItem || !selectedItem.id) {
      return;
    }
    dispatch(deleteAttachedFile(selectedItem)).then(closeModal);
  }

  return (
    <div className={ `add-modal modal-${isVisible ? 'open' : 'closed' }` }>
      <div className='modal-content attached-file-modal'>
        <Button className="modal-button" onClick={closeModal}>
          <Close/>
        </Button>
        <div className='modal-header'>
          <h3>
            ¿Esta seguro que desea eliminar el siguiente archivo? 
          </h3>
        </div>
        <div className='modal-body'>
          <div className='list-item attached-file no-under-line'>
            <div> 
              <DocIcon />
            </div>
            <div className='attached-file description'>
              <h3>
                { selectedItem.fileName?.replaceAll('_', ' ') }
              </h3>
              <p>
                { `tipo: ${FILE_TYPE[selectedItem.fileType??0]??'Desconocido'}` }
              </p>
            </div>
            <div className='spacer'/>
          </div>
          <div className="spacer">
        
          </div>

          <div className="submit-cancel-options">
            <Button className="cancel" onClick={closeModal}>
              Cancelar
            </Button>
            <Button className="submit" onClick={deleteSelectedItem}>
              Eliminar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
