import React from "react";
import {
  closeAll,
  setVisibility,
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { ListWidget } from "../components";
import { Links } from "../components/Links";
import { ROUTES } from "../constants";
import { Button } from "../components/Button";
import icono_invitado from "../components/Icons/icono_invitado.svg";
import "./styles/Redico.css";
import "../components/styles/List.css";
import "../components/styles/Header.css";
import { LIST_WIDGET_CONTENT_TYPE } from "../constants/ListWidgetContentType";
import { ProjectContentType } from "../components/ListWidget/contentType/ProjectContentType";
import { useHistory, useLocation } from "react-router-dom";

export const User = () => {

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.ProjectReducer.projects);

  const currentUser = location.state.member.User;
  
  const goTo = (routeName) => {
    if (location.pathname === routeName) {
      return;
    }
    history.push({
      pathname: routeName,
    });
    dispatch(closeAll(true));
    dispatch(setVisibility(false));
  }

  const links = [
    {
      link: ROUTES.MY_ORG,
      name: "Administradores de Proyecto",
    },
    {
      link: "#",
      name: currentUser.username,
    },
  ];

  const PROJECTS = {
    type: LIST_WIDGET_CONTENT_TYPE.PROJECTS_MAN,
    content: projects ?? [],
    contentType: ProjectContentType
  };

  return (
    <div className="main-content">
      <Links links={links} />

      <div className="list-header-2">
        <Button
          className="move-back-2"
          onClick={ () => goTo(ROUTES.MY_ORG) } >
          ▶
        </Button>
        <span>
          <img src={icono_invitado} alt="" className="amd-ind-logo-2"></img>
        </span>
        <span className="Name-administer">
         { currentUser.email }
        </span>
      </div>
      <div className="list-content-2">
        <ListWidget content={PROJECTS} />
      </div>
    </div>
  );
};
