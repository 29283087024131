import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMeeting, setMeetingModal, getProjectMeetings, getMeetings } from "../../actions"
import { Button } from "../Button";
import { ListWidgetItem } from "..";
import { ReactComponent as Close } from "../svg/icono_cerrar.svg";
import "./styles/Modal.css"
import { LIST_WIDGET_CONTENT_TYPE } from "../../constants/ListWidgetContentType";
import { useHistory } from "react-router-dom";

export const DeleteConfirmModalMeeting = ({
  meetingId,
  fromMeeting,
  meetingDescription
}) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const isVisible = useSelector((state) => state.ModalReducer.modalDeleteMeeting);
  const selectedMeetingId = useSelector((state) => state.ModalReducer.modalMeetingid);
  const selectedProject = useSelector((state) => state.ProjectReducer.selectedProject);

  const [disabled, setDisabled] = useState(false);

  useEffect(function resetState() {
    if (!isVisible) setDisabled(false);
  }, [isVisible])

  const closeModal = () => {
    dispatch(setMeetingModal(false)).then(setDisabled(false));
  }

  
  const goToProject = () => {
    if (selectedProject) {
        dispatch(getProjectMeetings(selectedProject.id));
     }
    dispatch(getProjectMeetings(selectedProject.id));
    setDisabled(false);
    history.push(`/projects/${this.props.selectedProject.id}`);
    closeModal();
  }

  
  const goToMeetings = () => {
    dispatch(getMeetings());
    setDisabled(false);
    history.push(`/meetings/`);
    closeModal();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (disabled || !selectedProject) return;
    setDisabled(true);
    dispatch(deleteMeeting(selectedProject.id, selectedMeetingId)).then(()=>{
      setDisabled(false);
      if (!fromMeeting){
        goToProject();
      } 
      else {
        goToMeetings();
      }
    }).catch(()=>{
      setDisabled(false);
    })
  }
  
  return(
    <div className={`modal modal-${ isVisible && selectedMeetingId === meetingId ? 'open' : 'closed' } `}>
      <div className="delete-modal">
        <Button className="modal-button" onClick={closeModal}>
          <Close/>
        </Button>
        <div className="modal-selectedProject">
          <form onSubmit={handleSubmit}>
            <div className='modal-2-title'>
              <div className="modal-avisar">
                Está seguro que quiere eliminar:
              </div>
            </div>
            <div>
              {
                meetingId ? (
                  <ListWidgetItem 
                    type={LIST_WIDGET_CONTENT_TYPE.MEETINGS}
                    key={1}
                    itemId={selectedMeetingId}
                    itemName={`Reunión con id: ${selectedMeetingId}`}
                    description={meetingDescription}
                    eliminando={true}
                    fromMeeting={fromMeeting}
                  /> 
                ) : (
                  ""
                )
              }
            </div>
            <div className="modal-avisar-2">
              Este procesos actualizará tu lista de reuniones y no tiene vuelta atras.
            </div>
            <div className="button-div-2">
              <Button className="purple-button-2" onClick={closeModal}>
                NO ELIMINAR
              </Button>
              <input className="delete-proyecto-button" type="submit" value="ELIMINAR REUNIÓN" disabled={disabled}/>
            </div>
          </form> 
        </div>
      </div>
    </div>
  )
} 