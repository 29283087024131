import { ATTACHED_FILE_ACTIONS } from "../constants";
import { DeleteAttachedFile, getAttachedFileList, UpdateAttachedFile, UploadAttachedFile } from "../services/AttachedFilesService";

export const getProjectAttachedFileList = (projectId) => async (dispatch) => {
  try {
    const attachedFileList = await getAttachedFileList(projectId);
    dispatch({
      type: ATTACHED_FILE_ACTIONS.GET_PROJECT_ATTACHED_FILE_LIST,
      payload: attachedFileList,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadProjectAttachedFile = (projectId, attachedFile, attachedFileData) => async (dispatch) => {
  try {
    const attachedFileRes = await UploadAttachedFile(projectId, attachedFile, attachedFileData);
    dispatch({
      type: ATTACHED_FILE_ACTIONS.ADD_ATTACHED_FILE,
      payload: attachedFileRes,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }

};

export const UpdateAttachedFileAction = (projectId, attachedFileId, attachedFile, attachedFileData) => async (dispatch) => {
  try {
    const attachedFileRes = await UpdateAttachedFile(projectId, attachedFileId, attachedFile, attachedFileData);
    dispatch({
      type: ATTACHED_FILE_ACTIONS.UPDATE_ATTACHED_FILE,
      payload: attachedFileRes,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deleteAttachedFile = (attachedFile) => async (dispatch) => {
  try {
    const attachedFileRes = await DeleteAttachedFile(attachedFile);
    dispatch({
      type: ATTACHED_FILE_ACTIONS.DELETE_ATTACHED_FILE,
      payload: {
        deletedItem: attachedFileRes,
      },
    });
  }
  catch (error) {
    console.error(error);
    throw error;
  }
};
